<template>
  <div>
    <v-card class="card-preview">
      <div class="card-div">
        <div style="font-size:18px;color:#444444;opacity:100%">Preview File</div>
        <v-btn depressed @click="Start()" color="primary" style="margin-top:1rem;">Back to Upload</v-btn>
        <div id="pdf" style="margin-top:2rem;"></div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "pdfPreview",
  created() {},
  data: () => ({
    files: [],
    color: "gray",
  }),
  computed: {},
  mounted() {
    const obj = document.createElement("embed");
    obj.style.width = "100%";
    obj.style.height = "550px";
    obj.type = "application/pdf";
    obj.src = this.$route.params.linkSource;
    document.getElementById("pdf").appendChild(obj);
  },
  methods: {
    Start() {
      this.$router.push("/UploadPDF");
    },
  },
};
</script>
<style scoped>
input[type="file"] {
  display: none;
}
.custom-file-upload {
  background-color: white;
  display: inline-block;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}
.dvborder {
  margin: 4px;
  border: 2px dotted #ccc;
}
.centertext {
  text-align: center;
}
.groupname {
  margin-top: 0.85rem;
}
.card {
  display: block;
  border-radius: 4px !important;
  overflow: auto;
  height: 650px;
  margin: 0;
  padding: 0;
}
.flex {
  margin-left: 2rem;
  margin-right: 2rem;
}
.card-preview {
  border-radius: 5px !important;
  height: 100%;
  overflow: auto;
  margin-bottom: 20px;
  padding: 0;
}
.card-div {
  border-radius: 10px !important;
  margin-bottom: 2rem;
  height: 100%;
  overflow: auto;
  margin: 0;
  padding: 1.5rem;
}
</style>
<style>
.theme--light.v-data-table thead tr th {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
}
</style>
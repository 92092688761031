<template>
  <div>
    <v-container fluid>
      <v-card class="card">
        <v-toolbar color="primary" dark>
          <v-toolbar-title class="title">Setting</v-toolbar-title>
          <v-spacer />
          <v-toolbar-title class="title">{{today}}</v-toolbar-title>
        </v-toolbar>
        <v-flex class="flex">
          <v-col cols="12" sm="6" md="6" class="mt-4">
            <v-text-field v-model="label" label="User Color Code"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <p>Logo</p>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <div class="dotted">
              <img :src="imageUrl" height="250" width="350" v-if="imageUrl" />
            </div>
            <v-text-field label="Select Image" @click="pickFile" v-model="imageName"></v-text-field>
            <input
              type="file"
              style="display: none"
              ref="image"
              accept="image/*"
              @change="onFilePicked"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <p>Default language for Mobile Users</p>
            <v-select :items="items" label="English" dense solo></v-select>
          </v-col>
          <v-row class="ml-1 mt-3 text-right">
            <v-btn color="primary" x-large class="mr-4">Save</v-btn>
            <v-btn color="#FAA91B" x-large>Cancel</v-btn>
          </v-row>
          <br />
          <br />
        </v-flex>
      </v-card>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Setting",
  data: () => ({
    language: "English",
    label: "#000000",
    today: null,
    imageName: "",
    imageUrl: "",
    imageFile: "",
    items: ["Foo", "Bar", "Fizz", "Buzz"]
  }),
  methods: {
    pickFile() {
      this.$refs.image.click();
    },

    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result;
          this.imageFile = files[0]; // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    GetDate() {
      let today1 = new Date();
      let dd = today1.getDate();
      let mm = today1.getMonth() + 1; //January is 0!

      let yyyy = today1.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      this.today = dd + "/" + mm + "/" + yyyy;
    }
  },
  mounted() {
    this.GetDate();
  }
};
</script>
<style scoped>
div.dotted {
  border-style: dotted;
  height: 256px;
  width: 356px;
}
.card {
  border-radius: 10px !important;
}
.flex {
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
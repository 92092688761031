<template>
  <div>
    <v-img
      src="@/assets/images/login_bg.png"
      width="100%"
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh' }
          : { height: '100vh' },
      ]"
    >
      <div>
        <v-container>
          <v-row style="min-height: 100vh" justify="center" align="center">
            <v-card
              width="420"
              :class="$vuetify.breakpoint.xs ? 'rounded-0' : 'rounded-lg'"
            >
              <v-card-text style="overflow: auto">
                <v-alert type="error" v-model="erroralert">{{
                  errorMessage
                }}</v-alert>
                <v-alert type="info" v-model="successalert">{{
                  successMessage
                }}</v-alert>
                <v-tabs class="d-none" v-model="model"> </v-tabs>

                <v-tabs-items v-model="model">
                  <!-- login -->
                  <v-tab-item :value="`tab-1`">
                    <h2 class="mb-6">Reset your password</h2>

                    <label for="companycode" style="font-size: 16px"
                      >Company Code</label
                    >
                    <v-text-field
                      id="companycode"
                      outlined
                      v-model="company_code"
                      style="margin-bottom: -15px"
                      readonly
                      disabled
                    ></v-text-field>

                    <label for="username" style="font-size: 16px"
                      >Username</label
                    >
                    <v-text-field
                      id="username"
                      outlined
                      v-model="username"
                      style="margin-bottom: -15px"
                      readonly
                      disabled
                    ></v-text-field>

                    <label for="psw" style="font-size: 16px"
                      >New Password</label
                    >
                    <v-text-field
                      id="psw"
                      outlined
                      type="password"
                      v-model="password"
                      style="margin-bottom: -15px"
                      @keyup.enter="ResetPassword()"
                    ></v-text-field>

                    <label for="psw" style="font-size: 16px"
                      >Confirm Password</label
                    >
                    <v-text-field
                      id="psw"
                      outlined
                      type="password"
                      v-model="confirmPassword"
                      style="margin-bottom: -15px"
                      @keyup.enter="ResetPassword()"
                    ></v-text-field>

                    <v-btn
                      :loading="loading"
                      :disabled="loading"
                      block
                      color="primary"
                      class="mt-3 rounded-lg"
                      x-large
                      @click="ResetPassword()"
                      >Submit</v-btn
                    >
                    <br />
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-row>
        </v-container>
      </div>
    </v-img>
    <v-footer
      color="primary"
      :absolute="$vuetify.breakpoint.height < 786 ? false : true"
      class="white--text text-caption hidden-md-and-down"
    >
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="10" md="10">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="4" md="4" lg="2">
              <v-img
                src="@/assets/VLogo.png"
                width="52"
                class="mx-auto"
              ></v-img>
            </v-col>
            <v-col>
              <span
                >Fusion Solution Co., Ltd 77/148 34 Fl. Sinsathorn Tower,
                Klongtonsai, Klongsarn, Bangkok 10600</span
              >
              <div class="d-flex">
                <span>Terms of Use</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
                <span>Privacy Policy</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
                <span>Cookies Policy</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
                <span>Privacy Notice</span>
                <v-divider vertical inset class="mx-3" dark></v-divider>
              </div>
            </v-col>
            <v-col>
              <div>For more Information</div>
              <div>TEL : 02 440 0408</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" xs="12" sm="2" md="2">
          <v-btn
            icon
            color="white"
            class="float-right"
            style="margin-top: 0.5rem"
          >
            <v-icon>mdi-arrow-up-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

    <Loading :flagLoading="flagLoadingSend" />
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import Alert from "@/components/Alert";
import Loading from "@/components/Loading"; 

export default {
  components: {
    Alert,
    Loading,
  },
  name: "ResetPassword",
  data() {
    return {
      flagLoadingSend: false,
      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      company_code: "",
      username: "",
      password: "",
      confirmPassword: "",
      url: enurl.apiUrl,
      loading: false,
      erroralert: false,
      successalert: false,
      successMessage: "",
      errorMessage: "",
      model: "tab-1",
      secretKey: "3i4Oi2J2IUzI13#NiI*GesInR5cCI6IR3&4$kp",
      email: "",

      has_minimum_lenth: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      isPassword: false,
    };
  },
  async mounted() {  
    const query_data = this.$route.query; 

    this.verifyAndDecodeJWT(query_data.token); 
  },
  watch: {
    password() {
      let self = this;
      self.has_minimum_lenth = self.password.length >= 8;
      self.has_number = /\d/.test(self.password);
      self.has_lowercase = /[a-z]/.test(self.password);
      self.has_uppercase = /[A-Z]/.test(self.password);
      self.has_special = /[-!@#$%^&*)(+=._|~`{}[\]\\:”;'<>?,/]/.test(
        self.password
      );
      if (
        self.has_minimum_lenth == true &&
        self.has_number == true &&
        self.has_lowercase == true &&
        self.has_uppercase == true &&
        self.has_special == true
      ) {
        self.isPassword = true;
      } else {
        self.isPassword = false;
      }
    },
  },
  methods: {
    verifyAndDecodeJWT(token) {
      const jwt = require("jsonwebtoken");

      try {
        const decoded = jwt.verify(token, this.secretKey); 
        this.company_code = decoded.companycode;
        this.username = decoded.username;
        this.email = decoded.email;
        console.log(decoded)
      } catch (error) {
        alert(
          error.message == "jwt expired"
            ? "Reset Password has expired"
            : error.message
        );
        this.$router.push("/login");
      }
    },

    ClearDataAlert() {
      if (this.typeAlertSend == "success") {
        this.$router.push("/login");
      }
      this.flagAlertSend = false;
      this.typeAlertSend = "";
      this.mesAlertSend = "";
    },

    ResetPassword() {
      if (this.password && this.confirmPassword && this.email) {
        if (this.password === this.confirmPassword) {
          this.flagLoadingSend = true;

          let payload = {
            email: this.email,
            password: this.password,
          };

          axios.post(`${this.url}Login/ResetPassword`, payload).then((res) => {
            if (res.data.status == 0) {
              this.flagLoadingSend = false;
              this.SuccessAlert(res.data.message);
            } else {
              this.flagLoadingSend = false;
              this.ErrorAlert(res.data.message);
            }
          });
        } else {
          this.WarningAlert(
            "Passwords do not match. Please re-enter your passwords."
          );
        }
      } else {
        this.WarningAlert("Please Enter All Fields!");
      }
    },

    WarningAlert(msg) {
      this.typeAlertSend = "warning";
      this.mesAlertSend = msg;
      this.flagAlertSend = true;
    },

    SuccessAlert(msg) {
      this.typeAlertSend = "success";
      this.mesAlertSend = msg;
      this.flagAlertSend = true;
    },

    ErrorAlert(msg) {
      this.typeAlertSend = "error";
      this.mesAlertSend = msg;
      this.flagAlertSend = true;
    },
  },
};
</script>
<style scoped>
::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 40px !important;
}
</style> 

<template>
  <div>
    <v-container fluid>
      <v-card class="card">
        <v-toolbar color="primary" dark>
          <v-toolbar-title class="title">{{$t('permissionmanagement')}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-title class="title">{{today}}</v-toolbar-title>
        </v-toolbar>
        <v-flex class="flex">
          <v-row style="margin-top:2rem;">
            <v-col cols="3">
              <v-select
                v-model="permissionname"
                :items="PermissionListAll"
                item-text="roleName"
                item-value="roleName"
                :label="$t('permissionname')"
                multiple
                clearable
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-btn class="primary white--text mt-3" @click="Search">{{$t('search')}}</v-btn>
            </v-col>
          </v-row>
          <v-data-table :items="filteredPermissions">
            <template v-slot:header="{ props: {  } }">
              <thead>
                <tr>
                  <th >PID</th>
                  <th style="width:35rem;">
                    <v-select
                      :label="$t('permissionname')"
                      :items="PermissionList"
                      v-model="filters.roleName"
                      multiple
                      clearable
                      item-text="roleName"
                      item-value="roleName"
                    ></v-select>
                  </th>
                  <th>{{$t('groupname')}}</th>
                  <th>{{$t('adduseringroup')}}</th>
                 
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td style="text-align:left">{{"P"+GetIndex(item)}}</td>
                <td style="text-align:left">{{ item.roleName }}</td>
                <td class="text-xs-left">{{ GetGroupName(item.groupID) }}</td>
                <td class="text-xs-left">
                  <v-btn icon @click="EditPermission(item)"  :disabled="checkPermissionTwo(9,10)">
                    <img :src="images.edit" />
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>

        <br />
        <br />
        <br />
      </v-card>
    </v-container>
    <!-- EditUserDialog -->
    <v-flagLoadingSend v-model="Editdialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('permissionmanagement')}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="permissionID"
                  :items="PermissionList"
                  item-text="roleName"
                  item-value="roleID"
                  :label="$t('permissionname')"
                >
                  <template v-slot:append-outer>
                    <v-slide-x-reverse-transition mode="out-in"></v-slide-x-reverse-transition>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  v-model="groupList"
                  :items="GroupList"
                  item-text="groupName"
                  item-value="id"
                  multiple
                  :label="$t('groupname')"
                  persistent-hint
                >
                  <template v-slot:append-outer>
                    <v-slide-x-reverse-transition mode="out-in"></v-slide-x-reverse-transition>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="Editdialog = false">{{$t('close')}}</v-btn>
          <v-btn color="blue darken-1" text @click="UpdateRole">{{$t('update')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-flagLoadingSend>
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import checkPermissionTwo from "@/api/function.js";
import LogTrace from "@/api/function.js";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  name: "Permission",
  mixins: [checkPermissionTwo,LogTrace],
  data: () => ({
    filters: {
      roleName: []
    },
    flagLoadingSend: false,
    roleName: null,
    url: enurl.apiUrl,
    Editdialog: false,
    permissionname: "",
    groupname: "",
    today: null,
    value: null,
    groupList: [],
    GroupList: [],
    PermissionList: [],
    PermissionListAll: [],
    permissionID: null,
    images: {
      edit: require("@/assets/edit1.png")
    },
    headers: [
      {
        text: "Permission Name",
        value: "name"
      },
      { text: "Group Name", value: "type" },
      { text: "Edit", value: "edit" }
    ],

    flagAlertSend: false,
    mesAlertSend: "",
    typeAlertSend: "success",
  }),
  computed: {
    filteredPermissions() {
      return this.PermissionList.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    }
  },
  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
     GetIndex(val) {
      let self = this;
      let data = self.PermissionList.indexOf(val);
      data++;
      return data;
    },
    Search() {
      let self = this;
      self.flagLoadingSend = true;
      if (self.permissionname != "") {
        let List = self.PermissionListAll;
        self.PermissionList = [];
        for (let i = 0; i < List.length; i++) {
          if (List[i].roleName == self.permissionname) {
            self.PermissionList.push(List[i]);
          }
        }
        self.flagLoadingSend = false;
      } else {
        alert("Please enter Group Name!!");
        self.GetPermission();
        self.flagLoadingSend = false;
      }
    },
    GetDate() {
      let today1 = new Date();
      let dd = today1.getDate();
      let mm = today1.getMonth() + 1; //January is 0!

      let yyyy = today1.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      this.today = dd + "/" + mm + "/" + yyyy;
    },
    GetGroupManagement() {
      let self = this;
      axios
        .post(`${self.url}Group/GetAllGroups`)
        .then(function(response) {
          self.GroupList = response.data.data;
        })
        .catch(function(error) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },
    EditPermission(index) {
      try{
      var self = this;
      self.Editdialog = true;
      self.permissionID = index.roleID;
      self.roleName = index.roleName;

      var temp = index.groupID.split(",");
      for (var i = 0; i < temp.length; i++) {
        var gp = self.GroupList.filter(
          x => x.id == temp[i] && x.activeFlag == true
        );
        self.groupList.push({
          id: gp[0].id,
          groupNamee: gp[0].groupName
        });
      }
      }catch(error){
        this.LogTrace(error,"EditPermission")
      }
    },
    GetPermission() {
      let self = this;
      self.flagLoadingSend = true;
      axios
        .post(`${self.url}Role/GetAllRoles`)
        .then(function(response) {
          self.PermissionList = response.data.data;
          self.PermissionListAll = response.data.data;
          self.flagLoadingSend = false;
        })
        .catch(function(error) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },
    GetGroupName(val) {
      let self = this;
      var temp2 = [];
      if (val != null) {
        var temp = val.split(",");
        var j = 0;
        while (j < temp.length) {
          var gp = self.GroupList.filter(x => x.id == temp[j]);
          if (gp.length > 0) {
            temp2.push(gp[0].groupName);
          } else {
            temp2.push("No Group");
          }
          j++;
        }
        return temp2 + "";
      } else {
        return "No Group";
      }
    },
    UpdateRole() {
      try{
      var self = this;
      self.flagLoadingSend = true;
      var templist = self.groupList + "";
      var tempRole = {
        roleID: self.permissionID,
        roleName: self.roleName,
        groupID: templist == "" ? null : templist
      };
      if(templist != "null" ){
      axios
        .post(`${self.url}Role/UpdateRole`, tempRole)
        .then(function(response) {
          if (response.data.status == 0) {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
            self.Editdialog = false;
            // self.permissionID = null;
            // self.groupList = null;
            self.GetPermission();
            window.location.reload();
            self.flagLoadingSend = false;
          }
        })
        .catch(function(error) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
          self.flagLoadingSend = false;
        });
      }else{
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = "Please fill all fields!!";
        self.flagAlertSend = true;
        self.flagLoadingSend = false;
      }
      }catch(error){
        this.LogTrace(error,"EditPermission")
      }
    }
  },
  mounted() {
    this.GetDate();
    this.GetGroupManagement();
    this.GetPermission();
  }
};
</script>
<style scoped>
.card {
  border-radius: 10px !important;
  margin-bottom: 2rem;
  height: 90vh;
  overflow: auto;
  margin: 0;
  padding:0;
}
.flex {
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
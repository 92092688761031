<template>
  <div>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="padding-bottom: 0px;">
        <p style="font-size: 14px; color:#126496; margin-bottom: 0px; cursor: pointer;" @click="goToDashboard()"><v-icon color="primary">mdi-chevron-left</v-icon> {{$t("backToHomePage")}}</p>
      </v-col>


      <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
        <v-row>
          <v-col cols="12">
            <v-card id="Plan" style="box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 3%), 0px 1px 1px 1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 3%)">
              <div style="margin-left: 20px; margin-right: 20px">
                <div class="pt-8" style="font-size: 18px;color: #444444;">
                  {{$t("currentPlan")}}
                </div>
              <v-row class="mt-5">
                <v-col cols="12" xs="12" sm="12" md="12">
                  <p style="font-size: 14px; margin-bottom: -10px;">{{$t("currentPackage")}}</p>
                </v-col>
              </v-row>
              <v-row  v-for="(item, index) in ListCurrentPackage" :key="index"> 
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-card white outlined :style="{'border': '1px solid', 'border-color': '#EBEBF2'}">
                    <div
                      style="
                          margin-left: 1rem;
                          margin-right: 1rem;
                          margin-top: 0.8rem;
                          margin-bottom: 0.5rem;
                        "
                    >
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <p class="head" style="margin-bottom: 0px;">{{$t("packageName")}}</p>
                          <p class="subject" style="margin-bottom: 0px; color: #126496;">{{item.name}}</p>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-card white outlined :style="{'border': '1px solid', 'border-color': '#EBEBF2'}">
                    <div
                      style="
                          margin-left: 1rem;
                          margin-right: 1rem;
                          margin-top: 0.8rem;
                          margin-bottom: 0.5rem;
                        "
                    >
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <p class="head" style="margin-bottom: 0px;">{{$t("startDate")}}</p>
                          <p class="subject" style="margin-bottom: 0px; color: #126496;">{{item.startDate}}</p>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-card white outlined :style="{'border': '1px solid', 'border-color': '#EBEBF2'}">
                    <div
                      style="
                          margin-left: 1rem;
                          margin-right: 1rem;
                          margin-top: 0.8rem;
                          margin-bottom: 0.5rem;
                        "
                    >
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <p class="head" style="margin-bottom: 0px;">{{$t("endDate")}}</p>
                          <p class="subject" style="margin-bottom: 0px; color: #126496;">{{item.expireDate}}</p>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
              </v-row>

              <v-row class="mt-5">
                <v-col cols="12" xs="12" sm="12" md="12">
                  <p style="font-size: 14px; margin-bottom: -10px;">{{$t("currentUsage")}}</p>
                </v-col>

                <!-- <v-col cols="12" xs="12" sm="12" md="6" v-if="LimitUser != null && LimitSignature != null">
                  <v-card white outlined :style="{'border': '1px solid', 'border-color': '#EBEBF2'}">
                    <div
                      style="
                          margin-left: 1rem;
                          margin-right: 1rem;
                          margin-top: 0.8rem;
                          margin-bottom: 0.5rem;
                        "
                    >
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <p class="head" style="margin-bottom: 0px;">Active User (Signer)</p>
                          <v-row>
                            <v-col cols="12" xs="12" sm="12" md="6">
                              <p class="subject" style="margin-bottom: 0px; color: #126496;">Signer</p>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6">
                              <p class="subject float-right" style="margin-bottom: 0px; color: #126496;">{{(LimitUser - LimitUserUse)}}/{{LimitUser}}</p>
                            </v-col>
                          </v-row>
                          <v-progress-linear 
                            rounded
                            height="8"
                            :value="((LimitUser - LimitUserUse) * 100) / LimitUser"
                            color="#126496"
                            background-color="#EBEBF2"
                          >
                          </v-progress-linear>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col> -->

                <v-col cols="12" xs="12" sm="12" md="6" v-if="LimitUser != 0 || CheckUnlimitUser == true">
                  <v-card  white outlined :style="{'border': '1px solid', 'border-color': '#EBEBF2'}">
                    <div
                      style="
                          margin-left: 1rem;
                          margin-right: 1rem;
                          margin-top: 0.8rem;
                          margin-bottom: 0.5rem;
                        "
                    >
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <p class="head" style="margin-bottom: 0px;">{{$t("activeUser(Signer)")}}</p>
                          <v-row>
                            <v-col cols="12" xs="12" sm="12" md="6">
                              <p class="subject" style="margin-bottom: 0px; color: #126496;">{{$t("signer")}}</p>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6">
                              <p v-if="CheckUnlimitUser!=true" class="subject float-right" style="margin-bottom: 0px; color: #126496;">{{(LimitUser - LimitUserUse)}}/{{LimitUser}}</p>
                              <p v-else class="subject float-right" style="margin-bottom: 0px; color: #126496;">{{LimitUserUse}}</p>
                            </v-col>
                          </v-row>
                          <v-progress-linear 
                          v-if="CheckUnlimitUser!=true"
                            rounded
                            height="8"
                            :value="((LimitUser - LimitUserUse) * 100) / LimitUser"
                            color="#126496"
                            background-color="#EBEBF2"
                          >
                          </v-progress-linear>
                          <v-progress-linear 
                            v-else
                            rounded
                            height="8"
                            :value="100"
                            color="#126496"
                            background-color="#EBEBF2"
                          >
                          </v-progress-linear>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" v-if="LimitSignature != 0 || CheckUnlimitSignature == true">
                  <v-card  white outlined :style="{'border': '1px solid', 'border-color': '#EBEBF2'}">
                    <div
                      style="
                          margin-left: 1rem;
                          margin-right: 1rem;
                          margin-top: 0.8rem;
                          margin-bottom: 0.5rem;
                        "
                    >
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12">
                          <p class="head" style="margin-bottom: 0px;">{{$t("signature")}}</p>
                          <v-row>
                            <v-col cols="12" xs="12" sm="12" md="6">
                              <p class="subject" style="margin-bottom: 0px; color: #126496;">{{$t("signature")}}</p>
                            </v-col>
                            <!-- <v-col cols="12" xs="12" sm="12" md="6">
                              <p v-if="CheckUnlimitSignature!=true" class="subject float-right" style="margin-bottom: 0px; color: #126496;">{{(LimitSignature - (LimitSignatureUse > LimitSignature ? LimitSignature : LimitSignatureUse))}}/{{LimitSignature}}</p>
                              <p v-else class="subject float-right" style="margin-bottom: 0px; color: #126496;">{{LimitSignatureUse}}</p>
                            </v-col> -->
                            <v-col cols="12" xs="12" sm="12" md="6">
                                <p class="subject float-right" style="margin-bottom: 0px; color: #126496;">
                                    {{ CheckUnlimitSignature ? LimitSignatureUse : (LimitSignature - Math.min(LimitSignature, LimitSignatureUse)) }}/{{ LimitSignature }}
                                </p>
                            </v-col>
                          </v-row>
                          <v-progress-linear 
                            v-if="CheckUnlimitSignature!=true"
                            rounded
                            height="8"
                            :value="((LimitSignature - LimitSignatureUse) * 100) / LimitSignature"
                            color="#126496"
                            background-color="#EBEBF2"
                          >
                          </v-progress-linear>
                          <v-progress-linear 
                            v-else
                            rounded
                            height="8"
                            :value="100"
                            color="#126496"
                            background-color="#EBEBF2"
                          >
                          </v-progress-linear>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>  
              </v-row>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card id="Plan" style="box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 3%), 0px 1px 1px 1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 3%)">
              <div style="margin-left: 20px; margin-right: 20px">
                <div class="pt-8" style="font-size: 18px;color: #444444;">
                  {{$t("requestUpgradePlan")}}
                </div>
              <v-row class="mt-5">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <p style="font-size: 14px; margin-bottom: 0px;">{{$t("selectYourVeracityPlan")}}</p>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="6">
                  <p
                    class="float-right"
                    style="color: #126496; font-size:14px; margin-bottom:0px;"
                  >{{$t("differencesBetweenPlans")}}</p>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" v-for="item in ListProductPlanUser" v-bind:key="item.id">
                  <v-card white outlined :style="{'border': '1px solid', 'background-color': ProductPlanUserID == item.id ? '#FFFFFF' : '#0000001A', 'color': '#E7EFF4' , 'border-color': ProductPlanUserID == item.id ? '#126496' : '#EBEBF2'}" @click="ChoosePackageUser(item.id)">
                    <div
                      style="
                          margin-left: 1rem;
                          margin-right: 1rem;
                          margin-top: 0.8rem;
                          margin-bottom: 0.5rem;
                        "
                    >
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6">
                          <p class="head" style="margin-bottom: 0px;">{{item.name}}</p>
                          <p class="subject" style="margin-bottom: 0px;">{{item.description}}</p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="3">
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="3">
                          <p v-show="item.limitUser != null && item.limitSignature == null" class="subject" style="margin-bottom: 0px;">From {{item.limitUser}} User {{item.contactSale ? "(Contact Sales)" : ""}}</p>
                          <v-row v-if="ProductPlanUserID == item.id">
                            <v-col cols="1">
                              <p class="Circle" style="margin-top: 5px;"></p>
                            </v-col>
                            <v-col cols="10">
                              <p class="subject" style="margin-bottom: 0px; color: #5CB85C;">{{$t("currentPackage")}}</p>
                            </v-col>
                          </v-row>
                          <v-row v-else>
                            <v-col cols="12">
                              <a class="subject" style="margin-bottom: 0px; color: #126496;">{{$t("changePackage")}}</a>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="12" v-for="item in ListProductPlanSignature" v-bind:key="item.id">
                  <v-card white outlined :style="{'border': '1px solid', 'background-color': ProductPlanSignatureID == item.id ? '#FFFFFF' : '#0000001A', 'color': '#E7EFF4' , 'border-color': ProductPlanSignatureID == item.id ? '#126496' : '#EBEBF2'}" @click="ChoosePackageSignature(item.id)">
                    <div
                      style="
                          margin-left: 1rem;
                          margin-right: 1rem;
                          margin-top: 0.8rem;
                          margin-bottom: 0.5rem;
                        "
                    >
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6">
                          <p class="head" style="margin-bottom: 0px;">{{item.name}}</p>
                          <p class="subject" style="margin-bottom: 0px;">{{item.description}}</p>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="3">
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="3">
                          <p v-show="item.limitSignature != null && item.limitUser == null" class="subject" style="margin-bottom: 0px;">From {{item.limitSignature}} Signature {{item.contactSale ? "(Contact Sales)" : ""}}</p>
                          <v-row v-if="ProductPlanSignatureID == item.id">
                            <v-col cols="1">
                              <p class="Circle" style="margin-top: 5px;"></p>
                            </v-col>
                            <v-col cols="10">
                              <p class="subject" style="margin-bottom: 0px; color: #5CB85C;">{{$t("currentPackage")}}</p>
                            </v-col>
                          </v-row>
                          <v-row v-else>
                            <v-col cols="12">
                              <a class="subject" style="margin-bottom: 0px; color: #126496;">{{$t("changePackage")}}</a>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
                
                <v-col cols="12" xs="12" sm="12" md="12">
                  <button style="font-size:16px; width:100%; height: 36px; background-color:#126496; color:#FFFFFF; border-radius: 5px;" @click="AddProductPlan()">
                    {{$t("sendRequest")}}
                  </button>
                  <!-- <v-btn color="primary" width="100%" @click="OpenSignCertificate(item)" depressed>
                    Send Request
                  </v-btn> -->
                </v-col>
              </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      
      <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <v-card  id="Plan" style="box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 3%), 0px 1px 1px 1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 3%);">
          <div style="margin-left: 20px; margin-right: 20px;">
            <div class="pt-8 head" style="text-align: center;">
              {{$t("stepsForRequestingAPlanUpgrade")}}
            </div>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" >
              <!-- <v-col cols="12" xs="12" sm="12" md="12" v-if="$vuetify.breakpoint.xl"> -->
                <br/>
                <v-img src="@/assets/Step1.png" width="83" height="83" class="mx-auto"></v-img>
                <center class="subject">{{$t("requestUpgradePlan")}}</center>
                <v-img src="@/assets/VerticalLine.png" width="3" height="83" class="mx-auto" style="margin-bottom: 8px;"></v-img>
                <v-img src="@/assets/Step2.png" width="83" height="83" class="mx-auto"></v-img>
                <center class="subject">{{$t("veracityReceiveInformationAndContactBackToEmailAndPhoneNumbersForConfirmationVeracity")}}</center>
                <v-img src="@/assets/VerticalLine.png" width="3" height="83" class="mx-auto" style="margin-bottom: 8px;"></v-img>
                <v-img src="@/assets/Step3.png" width="83" height="83" class="mx-auto"></v-img>
                <center class="subject">{{$t("veracitySendYouNewAccountInEmailVeracity")}}</center>
                <br/>
              </v-col>
              <!-- <v-col cols="12" xs="12" sm="12" md="12" v-else>
                <br/>
                <v-img src="@/assets/Step1.png" width="50" height="50" class="mx-auto"></v-img>
                <center style="font-size: 18px;">Request Upgrade plan</center>
                <v-img src="@/assets/VerticalLine.png" width="3" height="50" class="mx-auto" style="margin-bottom: 8px;"></v-img>
                <v-img src="@/assets/Step2.png" width="50" height="50" class="mx-auto"></v-img>
                <center style="font-size: 18px;">Veracity Receive information and contact back to email and phone numbers for confirmation</center>
                <v-img src="@/assets/VerticalLine.png" width="3" height="50" class="mx-auto" style="margin-bottom: 8px;"></v-img>
                <v-img src="@/assets/Step3.png" width="50" height="50" class="mx-auto"></v-img>
                <center style="font-size: 18px;">Veracity Send you a new account in email.</center>
              </v-col> -->
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
 
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />
    <Loading
      :flagLoading="flagLoadingSend"
    />
  </div>
</template>

<script>
import axios from "axios";
// import store from "../store";
import enurl from "@/api/environment";
// import * as moment from "moment/moment";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  data: () => ({
    flagLoadingSend: false,
    url: enurl.apiUrl,
    flagAlertSend: false,
    mesAlertSend: "",
    typeAlertSend: "warning",
    PackagePlan: 0,
    UserPlan: 0,
    SignPlan: 0,
    CompanyName: "",
    CompanyCode: "",
    Phone: "",
    Email: "",
    Address: "",
    Country: "",
    City: "",
    ListProductPlanUser: [],
    ListProductPlanSignature: [],
    ProductPlanUserID: 0,
    ProductPlanSignatureID: 0,
    Name: "",
    LimitUser: "",
    LimitSignature: "",
    LimitUserUse: "",
    LimitSignatureUse: "",
    CheckUnlimitUser: false,
    CheckUnlimitSignature: false,
    StartDate: "",
    ExpireDate: "",

    ListCurrentPackage: [],
  }),

  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagLoadingSend = false;
      self.flagAlertSend = false;
      //self.typeAlertSend = "warning"; //success warning error
      self.mesAlertSend = "";
      if(this.typeAlertSend == 'success'){
        this.$router.push('/UploadPDF');
      }
    },
    goToDashboard(){
      this.$router.push('/UploadPDF');
    },
    ChoosePackageUser(id){
      let self = this;
      self.ProductPlanUserID = id;
    },
    ChoosePackageSignature(id){
      let self = this;
      self.ProductPlanSignatureID = id;
    },
    async GetProductPlanAll () {
      let self = this;
      await axios
      .post(`${self.url}ProductPlan/GetMasterProductPlan`)
      .then(function (response) {
        if (response.data.status == 0) {
          self.ListProductPlanUser = response.data.data.user;
          self.ListProductPlanSignature = response.data.data.signature;
          // console.log(response.data.data);
        }
      })
      .catch(function (error) {
        alert(error);
      });
    },
    async GetCurrentProductPlan () {
      let self = this;
      await axios
      .post(`${self.url}ProductPlan/GetProductsByCompany`)
      .then(function (response) {
        if (response.data.status == 0) {
          if(response.data.data.listData.length > 0){
            self.ListCurrentPackage = response.data.data.listData;
            self.LimitUser = response.data.data.listData[0].limitUser;
            self.LimitSignature = response.data.data.listData[0].limitSignature;
            self.LimitUserUse = response.data.data.listData[0].limitUserUse;
            self.LimitSignatureUse = response.data.data.listData[0].limitSignatureUse;
            self.CheckUnlimitUser = response.data.data.listData[0].checkUnlimitUser;
            self.CheckUnlimitSignature = response.data.data.listData[0].checkUnlimitSignature;
          }
        }
      })
      .catch(function (error) {
        alert(error);
      });
    },
    async AddProductPlan () {
      let self = this;
      self.flagLoadingSend = true;
      let TempSend = {
        ProductPlanUserID : self.ProductPlanUserID,
        ProductPlanSignatureID : self.ProductPlanSignatureID,
        CompanyName : self.CompanyName,
        CompanyCode : self.CompanyCode,
        TelephoneNo : self.Phone,
        Email : self.Email,
        Address : self.Address,
        Country : self.Country,
        City : self.City,
      }
      await axios
      .post(`${self.url}ProductPlan/AddUpgradePlansForExistingCompany`, TempSend)
      .then(function (response) {
        self.flagLoadingSend = false;
        if (response.data.status == 0) {
          self.typeAlertSend = "success"; //success warning error
          self.mesAlertSend = self.$t('requestsentsuccessfully');
          self.flagAlertSend = true;
        }
        else
        {
          self.typeAlertSend = "warning"; //success warning error
          self.mesAlertSend = self.$t(response.data.message);
          self.flagAlertSend = true;
        }
      })
      .catch(function (error) {
        alert(error);
      });
    },
  },

  watch: {
  },

  mounted() {
    let self = this;
    self.GetProductPlanAll();
    self.GetCurrentProductPlan();
  },
};
</script>
<style scoped>
  .input-border {
    border: 1px solid #EBEBF2;
    top: 1136px;
    left: 144px;
    width: 100%;
    height: 36px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EBEBF2;
    border-radius: 5px;
    opacity: 1;
  }
  .head {
    font-size: 16px;
    color: #444444;   
  }
  .subject {
    font-size: 14px;
    color: #444444ad;   
  }
  .Circle {
    width: 0;
    height: 0;
    border: 5px solid #5CB85C;
    border-radius: 180px;
  }
</style>
<template>
  <div>
    <v-container fluid>
      <v-card class="card">
        <v-toolbar color="primary" dark>
          <v-toolbar-title class="title">Log</v-toolbar-title>
          <v-spacer />
          <v-toolbar-title class="title">{{today}}</v-toolbar-title>
        </v-toolbar>
        <v-flex class="flex">
          <!-- <v-row style="margin-top:2rem;">
          
            <v-col cols="3" class="mt-4">
              <v-autocomplete v-model="value" :items="items" dense label="User Name"></v-autocomplete>
            </v-col>
            <v-col cols="3" class="mt-4">
              <v-autocomplete v-model="value" :items="items" dense label="IP Address"></v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-btn class="primary white--text mt-3">Search</v-btn>
            </v-col>
          </v-row>-->
          <v-data-table :items="filteredUsers" class="mt-10">
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr>
                  <th>
                    <v-select
                      :label="$t('date')"
                      :items="users"
                      v-model="filters.createDate"
                      multiple
                      clearable
                      item-text="createDate"
                      item-value="createDate"
                    ></v-select>
                  </th>
                  <th>Log ID</th>
                  <th>{{$t('username')}}</th>
                  <th>IP address</th>
                  <th>{{$t('description')}}</th>
                  <th>Log Type</th>
                  <th>Method Name</th>
                  <th>Exception</th>
                  <th>Log Trace</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{convertUTCDateToLocalDate(item.createDate) }}</td>
                <td>{{ item.logId }}</td>
                <td>{{ item.userName }}</td>
                <td>{{ item.ip }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.logType }}</td>
                <td>{{ item.methodName }}</td>
                <td>{{ item.exception }}</td>
                <td>{{ item.logTrace }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>

        <br />
        <br />
        <br />
      </v-card>
      
    </v-container>
    
    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/api/function.js";
import Loading from "@/components/Loading";

export default {
  mixins: [LogTrace],
  name: "Log",
  components: {
    Loading,
  },
  data: () => ({
    filters: {
      createDate: []
    },
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    flagLoadingSend: false,
    url: enurl.apiUrl,
    header: [
      {
        text: "Date",
        value: "date"
      },
      { text: "Log ID", value: "menu" },
      { text: "User Name", value: "user" },
      { text: "IP Address", value: "user" },
      { text: "Description", value: "user" },
      { text: "Log Type", value: "user" },
      { text: "Method Name", value: "user" },
      { text: "Exception", value: "user" },
      { text: "Log Trace", value: "edit" }
    ],
    today: null,
    items: ["foo", "bar", "fizz", "buzz"],
    values: ["foo", "bar"],
    value: null,
    users: [],
    images: {
      edit: require("@/assets/edit1.png")
    }
  }),
  computed: {
    filteredUsers() {
      return this.users.filter(d => {
        return Object.keys(this.filters).every(f => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    }
  },
  methods: {
    convertUTCDateToLocalDate(date) {
      date = new Date(date);

      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output =
        newDate.getDate() +
        "/" +
        newDate.getMonth() +
        "/" +
        newDate.getFullYear() +
        "   " +
        newDate.getHours() +
        ":" +
        newDate.getMinutes();

      return output;
    },

    GetDate() {
      let today1 = new Date();
      let dd = today1.getDate();
      let mm = today1.getMonth() + 1; //January is 0!

      let yyyy = today1.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      this.today = dd + "/" + mm + "/" + yyyy;
    },
    GetLog() {
      try {
        let self = this;
        self.flagLoadingSend = true;
        axios
          .post(`${self.url}Log/GetAllLogs`)
          .then(function(response) {
            self.users = response.data.data;
            // console.log(self.users);

            self.flagLoadingSend = false;
          })
          .catch(function(error) {
            alert(error);
            self.flagLoadingSend = false;
          });
      } catch (error) {
        this.LogTrace(error, "GetLog");
      }
    }
  },
  mounted() {
    this.GetDate();
    this.GetLog();
  }
};
</script>
<style scoped>
.card {
  border-radius: 10px !important;
  margin-bottom: 2rem;
  height: 90vh;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.pagin {
  margin-top: 1.5rem;
}
.flex {
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
<template>
  <nav style="z-index:3">
    <v-toolbar color="#FFFFFF">
      <!-- <div class="d-flex align-center"> -->
      <v-img
        alt="Veracity Logo"
        class="shrink app"
        contain
        :src="images.logo"
        transition="scale-transition"
        width="130"
        min-width="100"
      />
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mobileRes" ></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text dark v-on="on" >
            <flag :iso="currentflag" style="font-size:25px"  v-bind:squared="false"/>
          </v-btn>
        </template>
        <v-list>
            <v-list-item 
              v-for="entry in languages" 
              :key="entry.title"
              @click="changeLocale(entry.language, entry.flag)"
              >
              <v-list-item-action>
                <flag :iso="entry.flag" v-bind:squared="false"/>
              </v-list-item-action>
              <v-list-item-title>{{ entry.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
      </v-menu> 

      <!-- <v-menu offset-y>
        <template v-slot:activator="{ on: menu }">
          <v-btn fab small color="primary" v-on="{  ...menu }" class="mr-4">
            <flag :iso="currentflag" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="entry in languages"
            :key="entry.title"
            @click="changeLocale(entry.language,entry.flag)"
          >
            <v-list-item-action>
              <flag :iso="entry.flag" v-bind:squared="false" />
            </v-list-item-action>
            <v-list-item-title>{{entry.title}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>-->
      <v-menu offset-y>
        <template class="white-color" v-slot:activator="{ on: menu }">
          <v-btn v-on="{  ...menu }">
            <span>{{username}}</span>
            <span>
              <v-icon>mdi-menu-down</v-icon>
            </span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item class="list">
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title @click="UserProfile">{{$t('userprofile')}}</v-list-item-title>
          </v-list-item>
          <v-list-item class="list">
            <v-btn block depressed color="primary" class="text-capitalize mt-3" @click="GotoUpgradePlan()">{{$t("upgradeYourPlan")}}</v-btn>
          </v-list-item>
          <v-list-item class="list">
            <v-list-item-icon>
              <img src="@/assets/id-management.svg" alt="User Image" />
            </v-list-item-icon>
            <v-list-item-title @click="UserManualPdfDownload()">{{$t("userManual")}}</v-list-item-title>
          </v-list-item>
          <v-list-item class="list" @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('logout')}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <!-- <v-navigation-drawer
      fixed
      app
      style="max-height: calc(100% - 70px);margin-top:70px;background-image: linear-gradient(to bottom, #ffffff, #ffffff, #ffffff, #E1F0FE, #E1F0FE);"
      v-model="drawer"
      absolute
    >-->    
    <v-navigation-drawer
      fixed
      app
      style="min-height: calc(100% - 60px);margin-top:65px;background-color:#ffffff;"
      v-model="drawer"
      absolute
    >
      <v-list>
        <v-list-item
          v-for="(item,id) in items"
          :key="id"
          link
          router
          :to="item.route"
          active-class="blue--text"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.text | myLocale }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <Loading
      :flagLoading="flagLoadingSend"
    />
  </nav>
</template>
<script>
import i18n from "@/plugins/i18n";
import axios from "axios";
import store from "../store";
import enurl from "@/api/environment";
import Loading from "@/components/Loading";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      flagLoadingSend: false,
      url: enurl.apiUrl,
      show: false,
      username: store.state.username,
      usertype: store.state.usertype,
      permissionid: store.state.permissionid,
      drawer: null,
      images: {
        logo: require("@/assets/Group 7673@2x.png"),
      },
      currentflag: sessionStorage.getItem("flag"),
      languages: [
        { flag: "us", language: "en", title: "English" },
        { flag: "th", language: "th", title: "ไทย" },
      ],
      users: [],
    };
  },
  computed: { 
    items(){
      return [
        //{ title: "log", icon: "mdi-view-list", route: "/log" },
        { 
          index: 0,
          id: [2],
          title: "Upload File",
          icon: "mdi-home", 
          route: "/UploadPDF",
          text: "uploadFile"
          // flag: false
        },
        {
          index: 1,
          id: [3,4],
          title: "History Log",
          icon: "mdi-clock-outline",
          route: "/historylog",
          text: "historyLog"
          // flag: false
        },
        {
          index: 2,
          id: [5,6,7,8],
          title: "Certificate Management",
          icon: "mdi-cogs",
          route: "/CertList",
          text: "certificateManagement"
          // flag: false
        },
        {
          index: 3,
          id: [9,10,11,12,13,14,15,16,17,18,19,20,21],
          title: "User Management",
          icon: "mdi-clipboard",
          route: "/usermanagement",
          text: "usermanagement"
          // flag: false
        },
        // { id: 4, title: "setting", icon: "mdi-settings", route: "/setting" }
      ]},
  },
  methods: {
    GotoUpgradePlan(){
      let self = this;
      self.$router.push("/upgradeplan");
    },

    CheckPermission() {
      let self = this;
      let PerArr = [];
      if(self.permissionid != "" && (typeof self.permissionid !="object")){
        PerArr = self.permissionid.split(","); 
      }else{
        PerArr = self.permissionid;
      }
      let tempitem = [];
      for(let i = 0; i < PerArr.length; i++)
      {
        for(let k = 0; k < self.items.length; k++){
          let temp = self.items[k].id.filter(x => x == PerArr[i] || (PerArr[i] == 1));
          if(temp.length > 0){
            let temppush = tempitem.filter(x => x.title == self.items[k].title);
            if(temppush.length == 0){
              tempitem.push(self.items[k]);
            }
          }
        }
      }
      self.items = [];
      let tempitem_sort = tempitem.sort((a, b) => (a.index > b.index) ? 1 : -1)
      self.items = tempitem_sort;

      // const result1 = PerArr.map(function(currentValue, index){
      //   console.log(currentValue + "/" + index);
      //   if(currentValue == 3 || currentValue == 4){
      //     console.log(currentValue);
      //   }
      // });
      // // arr.splice(2,2);
      // console.log(result1);
    },

    profileImageSync() {
      let self = this;
      let tempData = {
        userName: self.username,
        password: self.password,
      };
      axios.post(`${self.url}Login/Login`, tempData)
          .then(function (response) {
        self.imageUrl = response.data.data.pathImage;
      });
    },

    changeLocale(locale, flag) {
      this.currentflag = flag;
      i18n.locale = locale;
      sessionStorage.setItem("flag", flag);
      sessionStorage.setItem("locale", locale);
    },
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push("/login");
          sessionStorage.removeItem("permissionID");
        })

        // eslint-disable-next-line no-console
        .catch((err) => console.log(err));
    },
    UserProfile() {
      let self = this;
      self.$router.push("/userprofile");
    },


    UserManualPdfDownload() {
      let self = this;
      self.flagLoadingSend = true;
      axios
            .get(`${self.url}Documents/GetUserManualPdf`)
            .then((response) => {
              if (response.data.status == 0) {
              if (response.data.data != "") {
                const blobPDF = self.base64ToBlob(response.data.data.base64String, 'application/pdf');
                const linkSource = URL.createObjectURL(blobPDF);
                self.downloadURI(linkSource, response.data.data.fileName);
                self.flagLoadingSend = false;
              }
            }
            else{
              self.flagLoadingSend = false;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
          });
    },

    downloadURI(uri, name) {
      var link = document.createElement("a");
      // link.download = name;
      link.setAttribute("download", name);
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    },
    base64ToBlob( base64, type = "application/octet-stream" ) {
      const binStr = atob( base64 );
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[ i ] = binStr.charCodeAt( i );
      }
      return new Blob( [ arr ], { type: type } );
    },


    homepage() {
      let self = this;
      self.$router.push("/usermanagement");
    },
  },
  mounted() {
    this.currentflag = sessionStorage.getItem("flag");
    i18n.locale = sessionStorage.getItem("locale");
  },
};
</script>
<style>
.app {
  margin-left: 2rem;
  cursor: pointer;
}
.list {
  cursor: pointer;
}
.apptitle {
  font-family: "Roboto", sans-serif;
  font-size: 20px !important;
}
.v-list-item__content {
  font-family: "Roboto", sans-serif;
  font-size: 20px !important;
}
.white-color {
  background-color: white;
}
@media screen and (min-width: 431px) {
  .mobileRes {
    margin-left:3rem;
  }
}
</style>
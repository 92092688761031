import Vue from 'vue'
import Router from 'vue-router';
import Login from './views/Login'
import UserManagement from './views/UserManagement'
import UserProfile from './views/UserProfile'
import MenuManagement from './views/MenuManagement'
import Calendar from './views/Calendar'
import Permission from './views/Permission'
import Log from './views/Log'
import Setting from './views/Setting'
import UserGroup from './views/UserGroup'
import UploadPDF from './views/UploadPDF'
import PdfPreview from './views/PdfPreview'
import CertList from './views/CertList'
import HistoryLog from './views/HistoryLog'
import AccessDenied from './views/AccessDenied'
import ConfirmPassword from './views/ConfirmPassword'
import Register from './views/Register'
import Upgradeplan from './views/Upgradeplan'
import ResetPassword from "./views/ResetPassword";


import store from "./store"
Vue.use(Router)
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: {
        name: "Login",
      },
    },

    {
      path: "/login",
      name: "Login",
      component: Login,
    },

    {
      path: "/ResetPassword",
      name: "ResetPassword",
      component: ResetPassword,
    },

    {
      path: "/confirmpassword",
      name: "ConfirmPassword",
      component: ConfirmPassword,
    },

    {
      path: "/usermanagement",
      name: "UserManagement",
      component: UserManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/historylog",
      name: "HistoryLog",
      component: HistoryLog,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/userprofile",
      name: "UserProfile",
      component: UserProfile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/menumanagement",
      name: "MenuManagement",
      component: MenuManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/calendar",
      name: "Calendar",
      component: Calendar,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/permission",
      name: "Permission",
      component: Permission,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/log",
      name: "Log",
      component: Log,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/UploadPDF",
      name: "UploadPDF",
      component: UploadPDF,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/PdfPreview",
      name: "PdfPreview",
      component: PdfPreview,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/CertList",
      name: "CertList",
      component: CertList,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/setting",
      name: "Setting",
      component: Setting,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/usergroup",
      name: "UserGroup",
      component: UserGroup,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/AccessDenied",
      name: "AccessDenied",
      component: AccessDenied,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/Upgradeplan",
      name: "Upgradeplan",
      component: Upgradeplan,
    },
  ],
});
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {

        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }

})

export default router;
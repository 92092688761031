/* eslint-disable no-console */
import axios from "axios";
import enurl from "@/api/environment";
import store from "../store";
export default {
    data() {
        return {
            url: enurl.apiUrl,
            // globaladd:true,
            // globaledit:true,
            // globaldelete:true
        }
    },

    mounted: function () {
        // this.authenticatedOrRedirect();
        // this.checkPermissionFour();

    },
    methods: {
        checkPermissionFour(a, b, c, d) {
            let temp = {
              add: true,
              edit: true,
              delete: true
            };
            let permissionID = sessionStorage.getItem("permissionid").split(",");
            if (permissionID.some(e => e == 99)) {
              temp.add = false;
              temp.edit = false;
              temp.delete = false;
            } else {
              if (permissionID.some(e => e == a)) {
                temp.add = true;
                temp.edit = true;
                temp.delete = true;
              }
              if (permissionID.some(e => e == b)) {
                temp.add = false;
              }
              if (permissionID.some(e => e == c)) {
                temp.edit = false;
              }
              if (permissionID.some(e => e == d)) {
                temp.delete = false;
              }
            }
            return temp;
          },

        LogTrace(exception, methodname) {

            if (exception != null) {
                var logtype = "Critical";
                var description = exception.message;
                var datetime= new Date().toJSON();
                //var logtrace = exception.stack;
            } else {
                logtype = "Verbose";
            }
            let tempLog = {
                userName: store.state.username,
                userId: store.state.userid,
                laptopModel: "string",
                description: description,
                logType: logtype,
                methodName: methodname,
                exception: description,
                logTrace: description,
                createDate: datetime
            }
            axios
          
                .post(`${this.url}Log/AddLog`, tempLog)
                .then(function (response) {
                    alert(response.data.message);
                })
                .catch(function (error) {
                    alert(error);
                });


        },
        authenticatedOrRedirect() {
            const token = sessionStorage.getItem("token");
            if (!token) this.$router.push("/login");
        },
        checkPermissionTwo(a, b) {

            let permissionID = sessionStorage.getItem('permissionid').split(',');
            for (let item of permissionID) {
                if (item == 99 || item == b) return false;
                else if (item == a) return true;

            }
        },
      
    }
}
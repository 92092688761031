<template>
  <div>
    <v-card
      v-show="PermissionPage == false && GroupPage == false && EmpPage == false && RolePage == false && ViewDataRolePermissionStatus == false"
      class="card-preview"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >{{$t('usermanagement')}}</div>
        <div class="mt-6 mb-5" style="margin-bottom: 5rem">
          <div class="pl-5">
            <v-tabs class="mt-2" grey--text slider-color="#1976d2" show-arrows v-model="ActiveTab">
              <v-tab
                class="text-capitalize tabone"
                @click="orginalOne(1)"             
              >{{$t('user')}}</v-tab>
              <!-- <v-tab
                class="text-capitalize tabone"
                @click="orginalOne(2)"
              >User Group</v-tab> -->
              <!-- <v-tab
                class="text-capitalize tabone"
                @click="orginalOne(3)"
                v-show="flag.permission_view"
              >Permission</v-tab> -->
              <v-tab
                class="text-capitalize tabone"
                @click="orginalOne(3)"
              >{{$t('role')}}</v-tab>
              <v-tabs-slider style="margin-top: -10px;height: 5px;" :color="ActiveTab == 0 ? '#126496' : ActiveTab == 1 ? '#1CBF3D' : '#FAA91B'"></v-tabs-slider>
              
              <v-tab-item>
                <!-- <v-row class="pt-5" style="margin-left: 25px; margin-right: 10px">
                  <div style="width: 300px" class="ml-2 mt-3">
                    <v-row>
                      <v-col>
                        <v-text-field
                          autocomplete="off"
                          label="Regular"
                          v-model="search"
                          rounded
                          solo
                          dense
                          style="
                        margin-top: -0.8rem;
                        margin-left: -1rem;
                        color: black;
                      "
                          prepend-inner-icon="mdi-magnify"
                          :placeholder="$t('searchCompanyName')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-row>-->
                <!-- <v-row class="pt-2" style="margin-left: 11px; margin-right: 11px">
                  <div class="ml-2 mt-3">
                    <v-row>
                      <v-col cols="12" md="6" style="margin-top: -20px;">
                        <v-btn
                          width="180px"
                          color="#126496"
                          class="white--text mt-2"
                          router
                          @click="addEmployee"
                          characters="a-z,A-Z,0-9,#"
                          :disabled="!flag.user_add"
                        >Add User</v-btn>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          autocomplete="off"
                          label="Regular"
                          v-model="searchUser"
                          v-on:keyup.enter="SearchEmployeeList()"
                          solo
                          dense
                          style="
                        margin-top: -0.8rem;
                        margin-left: -1rem;
                        color: black;
                      "
                          prepend-inner-icon="mdi-magnify"
                          placeholder="search"
                        ></v-text-field>
                      </v-col>                    
                    </v-row>
                  </div>
                </v-row> -->
                <br>
                <!-- New User Data Table -->
                <v-data-table
                  :headers="headers"
                  :items="empList"
                  :page.sync="pageUser"
                  :items-per-page="itemsPerPageUser"
                  style="margin-left: 10px; margin-right: 40px"
                  hide-default-header
                  hide-default-footer
                  :mobile-breakpoint="0"> 
                  
                  <!-- Top -->
                  <template v-slot:top>
                    <v-row class="mb-5">
                      <v-col cols="12" xs="12" sm="12" md="4" lg="2" xl="2" class="d-flex" :style="{'max-width': $vuetify.breakpoint.xl? '10.666667%' : ''}">
                        <v-btn
                          width="150px"
                          color="#126496"
                          class="white--text flex-grow-1"
                          router
                          @click="addEmployee()">{{$t('addUser')}}</v-btn>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="2" xl="2" class="d-flex" :style="{'max-width': $vuetify.breakpoint.xl? '10.666667%' : ''}">
                        
                        <input type="file" ref="hiddenFile" style="display:none" @change="importUser()" accept=".xlsx"/>
                        <v-btn
                          width="150px"
                          color="#126496"
                          class="white--text flex-grow-1"
                          router
                          @click="$refs.hiddenFile.click()">{{$t('import')}}</v-btn>
                      </v-col>
                      <v-col cols="12" xs="12" sm="12" md="4" lg="2" xl="2" class="d-flex" :style="{'max-width': $vuetify.breakpoint.xl? '10.666667%' : ''}">
                        <v-btn
                          width="150px"
                          color="#126496"
                          class="white--text flex-grow-1"
                          router
                          @click="downloadTemplate()">{{$t('download')}}</v-btn>
                          <!-- <div>
                            <a :href="'@/assets/ImportUserTemplate.xlsx'" download>
                              Download Excel Template
                            </a>
                          </div> -->
                      </v-col>
                      <!-- Filter -->
                      <v-col cols="12" xs="12" sm="12" md="4" lg="2" xl="1" :style="{'margin-left': $vuetify.breakpoint.xl? '80px':''}">
                        <v-menu
                          offset-y
                          :close-on-content-click="false"
                          v-model="menu"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            style="border: 1px solid #EBEBF2"
                              block
                              height="40"
                              class="text-capitalize"
                              outlined
                              color="rgba(0,0,0,0.5)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{$t('filter')}}
                              <v-icon>mdi-filter-variant</v-icon>
                            </v-btn>
                          </template>
                          <v-card style="overflow: auto">
                            <v-card-text style="max-height: 50vh; min-width: 40svw;" class="d-flex-row flex-wrap pa-4">
                              <!-- <v-row>
                                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                                  <p class="mt-3">บทบาท</p>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="4" lg="6">
                                    <v-select
                                        hide-no-data
                                        return-object
                                        :items="rolelist"
                                        item-text="roleName"
                                        item-value="roleID"
                                        v-model="Filter.role"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>
                              </v-row>
                              <v-divider class="mt-1"></v-divider> -->
                              <!-- <v-row class="mt-3">
                                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                                  <p class="mt-3">สถานะผู้ดูแลระบบ</p>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                                  <v-checkbox
                                    dense
                                    hide-details="auto"
                                    label="ADMIN"
                                    v-model="Filter.adminStatus"
                                    value="1"
                                  ></v-checkbox>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                                  <v-checkbox
                                    dense
                                    hide-details="auto"
                                    label="EMPLOYEE"
                                    v-model="Filter.adminStatus"
                                    value="2"
                                  ></v-checkbox>
                                </v-col>
                              </v-row> -->
                              <!-- บทบาท -->
                              <v-row class="mt-3">
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                                  <p class="mt-3">{{$t('role')}}</p>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                                  <v-checkbox
                                    dense
                                    hide-details="auto"
                                    :label="$t('admin')"
                                    v-model="Filter.role"
                                    value="1"
                                  ></v-checkbox>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                                  <v-checkbox
                                    dense
                                    hide-details="auto"
                                    :label="$t('Operator')"
                                    v-model="Filter.role"
                                    value="2"
                                  ></v-checkbox>
                                </v-col> 
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                                  <v-checkbox
                                    dense
                                    hide-details="auto"
                                    :label="$t('Signer')"
                                    v-model="Filter.role"
                                    value="3"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                              <v-divider class="mt-3"></v-divider>
                              <!-- สถานะผู้ลงนาม -->
                              <v-row class="mt-3">
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                                  <p class="mt-3">{{$t('status')}}</p>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                                  <v-checkbox
                                    dense
                                    hide-details="auto"
                                    :label="$t('Active')"
                                    v-model="Filter.signerStatus"
                                    value="1"
                                  ></v-checkbox>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                                  <v-checkbox
                                    dense
                                    hide-details="auto"
                                    :label="$t('Inactive')"
                                    v-model="Filter.signerStatus"
                                    value="2"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                              <v-divider class="mt-1"></v-divider>
                              <div class="d-sm-flex d-block mt-3">
                                <v-col>
                                  <v-btn
                                    text
                                    class="text-capitalize flex-sm-grow-0 flex-grow-1"
                                    color="error"
                                    @click="ClearSearchFilter(), date1 = null, date2 = null"
                                    >{{$t('clearAllFilters')}}</v-btn
                                  >
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col>
                                  <v-btn
                                    text
                                    class="text-capitalize flex-sm-grow-0 flex-grow-1"
                                    @click="menu = false"
                                    >{{$t('cancel')}}</v-btn
                                  >
                                </v-col>
                                <v-col>
                                  <v-btn
                                    text
                                    class="text-capitalize flex-sm-grow-0 flex-grow-1"
                                    color="primary"
                                    @click="FlagSearch = true; SearchFilter()"
                                    >{{$t('apply')}}</v-btn
                                  >
                                </v-col>
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </v-col> 
                      <!-- Search -->
                      <v-col cols="12" xs="12" sm="12" md="8" lg="7" xl="6">
                        <v-text-field
                          :label="$t('search')"
                          v-model="searchUser"
                          prepend-inner-icon="mdi-magnify"
                          outlined
                          dense
                          hide-details="auto"
                          @keyup.enter="SearchEmployeeList()"></v-text-field>
                      </v-col>
                      
                    </v-row>
                  </template>
                  <!-- Header -->
                  <template v-slot:header>
                    <thead >
                      <tr>
                        <th 
                          v-for="(header, index) in headers" 
                          :key="index"
                          class="data-table-header">{{ header.text }}</th>
                      </tr>
                    </thead>
                  </template>
                  <!-- Items -->
                  <template v-slot:item.groupName="{ item }">
                    <span>{{ getGroupName(item) }}</span>
                  </template>
                  <template v-slot:item.roleName="{ item }">
                    <span>{{ getRoleName(item)}}</span>
                  </template>
                  <template v-slot:item.activeStatus="{ item }">
                    <v-btn
                      rounded
                      width="100"
                      text
                      :style="{
                        color: item.activeStatus == true ? '#22A534' : getRoleName(item) == 'Signer' ? '#FF6565' : '#FFFFFF00',
                        background: item.activeStatus == true ? '#C6E8CB' :  getRoleName(item) == 'Signer' ? '#FFD8D8' : '#FFFFFF00',
                      }"
                    >{{ item.activeStatus == true ? $t('active') : getRoleName(item) == 'Signer' ? $t('inactive'): ''}}</v-btn>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn 
                      icon 
                      @click="editEmp(item)">
                      <img :src="images.edit" />
                    </v-btn>
                    <v-btn 
                      v-if="!item.ownAdmin"
                      icon 
                      @click="deleteEmp(item.employeeID)">
                      <img :src="images.delete" />
                    </v-btn>
                  </template>
                </v-data-table>
                <!-- Old User Data Table -->
                <!-- <v-data-table
                  style="margin-left: 10px; margin-right: 40px"
                  :headers="headers"
                  :items="empList"
                  :page.sync="pageUser"
                  hide-default-header
                  :items-per-page="itemsPerPageUser"
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-row class="mb-5">
                      <v-col cols="12" md="3">
                        <v-btn
                          width="150px"
                          color="#126496"
                          class="white--text"
                          router
                          @click="addEmployee"
                        >Add User</v-btn>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          label="Search"
                          v-model="searchUser"
                          prepend-inner-icon="mdi-magnify"
                          outlined
                          dense
                          hide-details="auto"
                          v-on:keyup.enter="SearchEmployeeList()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3"></v-col>
                    </v-row>
                  </template>
                  <template v-slot:header>
                    <thead>
                      <tr>
                        <th
                          style="cursor: pointer; min-width: 180px !important;background-color: #126496;"
                          class="b-text"
                        >{{ headers[0].text }}</th>
                        <th
                          style="cursor: pointer; min-width: 180px !important;background-color: #126496;"
                          class="b-text"
                        >{{ headers[1].text }}</th>
                        <th
                          style="cursor: pointer; min-width: 180px !important;background-color: #126496;"
                          class="b-text"
                        >{{ headers[2].text }}</th>
                        <th
                          style="background-color: #126496;"
                          
                        >Action</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.employeeName }}</td>
                      <td>{{ getGroupName(item) }}</td>
                      <td>{{ getRoleName(item) }}</td>
                      <td class="pl-1">
                        <v-btn icon @click="editEmp(item)"> -->
                        <!-- Already Commented -->
                        <!-- <v-btn icon @click="editEmp(item)" v-show="flag.user_edit"> -->
                          <!-- Not Already Commented -->
                          <!-- <img :src="images.edit" />
                        </v-btn>
                        <v-btn icon @click="deleteEmp(item.employeeID)" > -->
                        <!-- Already Commented -->
                        <!-- <v-btn icon @click="deleteEmp(item.employeeID)" v-show="flag.user_delete"> -->
                          <!-- Not Already Commented -->
                          <!-- <img :src="images.delete" />
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table> -->
                <v-spacer></v-spacer>

                <div class="text-right">
                  <v-row justify="end" class="ml-5 mt-5 mr-5">
                    <v-col cols="12" md="3">
                      <v-row>
                        <v-col cols="12" md="6">
                          <p style="color: #126496;float: left;">{{$t('activate')}}</p>
                        </v-col>
                        <v-col cols="12" md="6">
                          <p v-if="CheckUnlimitUser != true" style="color: #126496;">{{(LimitUserUse)}}/{{LimitUser}}</p>
                          <p v-else style="color: #126496;">{{LimitUserUse}}</p>
                        </v-col>
                      </v-row>
                      <v-progress-linear 
                        v-if="CheckUnlimitUser != true"
                        style="margin-top: -25px;"
                        rounded
                        height="8"
                        :value="(LimitUserUse * 100) / LimitUser"
                        color="#126496"
                        background-color="#EBEBF2"
                      >
                      </v-progress-linear>
                      <v-progress-linear 
                        v-else
                        style="margin-top: -25px;"
                        rounded
                        height="8"
                        :value="100"
                        color="#126496"
                        background-color="#EBEBF2"
                      >
                      </v-progress-linear>
                    </v-col>          
                    <v-col cols="12" md="4"></v-col>
                    <v-col cols="12" md="2" class="mt-1">
                      <v-select
                        dense
                        style="width: 120px; float: right"
                        solo
                        label="10/page"
                        v-model="itemsPerPageUser"
                        @input="ChangePerPage(itemsPerPageUser)"
                        :items="items"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-pagination
                        v-model="pageUser"
                        :length="pageCountUser"
                        color="#FFCD2C"
                        :total-visible="6"
                        @input="ChangePage(pageUser)"
                      ></v-pagination>
                    </v-col>                                     
                  </v-row>
                </div>
              </v-tab-item>

              <!-- <v-tab-item>
                <br>
                <v-data-table
                  :headers="groupheaders"
                  :search="groupsearch"
                  :items="groupList"
                  :page.sync="pageGroup"
                  :items-per-page="itemsPerPageGroup"
                  @page-count="pageCountGroup = $event"
                  style="margin-left: 10px; margin-right: 40px"
                  hide-default-header
                  hide-default-footer>
                    <template v-slot:top>
                      <v-row class="mb-5">
                        <v-col cols="12" md="3">
                          <v-btn
                            width="180px"
                            color="#126496"
                            class="white--text"
                            router
                            @click="addGroup">Add Group</v-btn>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field
                            label="Search"
                            v-model="groupsearch"
                            prepend-inner-icon="mdi-magnify"
                            outlined
                            dense
                            hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3"></v-col>
                      </v-row>
                    </template>
                    <template v-slot:header>
                      <thead class="hide-header">
                        <tr class="table-header-right">
                          <th 
                            v-for="(groupheader, index) in groupheaders" 
                            :key="index"
                            class="data-table-header">{{ groupheader.text }}</th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-btn 
                        icon 
                        @click="editGroup(item)">
                        <img :src="images.edit" />
                      </v-btn>
                      <v-btn 
                        icon 
                        @click="deleteGroup(item.id)">
                        <img :src="images.delete" />
                      </v-btn>
                    </template>
                  </v-data-table>
                <div class="text-right">
                  <v-row justify="end" class="ml-5 mt-5 mr-5">                 
                    <v-col cols="12" md="2" class="mt-1">
                      <v-select
                        dense
                        style="width: 120px; float: right"
                        solo
                        label="10/page"
                        v-model="itemsPerPageGroup"
                        :items="items"
                        @input="itemsPerPageGroup = parseInt($event, 10)"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-pagination
                        v-model="pageGroup"
                        :length="pageCountGroup"
                        color="#FFCD2C"
                        :total-visible="6"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item> -->

              <!-- <v-tab-item>
                <v-row class="pt-5" style="margin-left: 11px; margin-right: 11px">
                  <div style="width: 300px" class="ml-2 mt-3">
                    <v-row>
                      <v-col>
                        <v-text-field
                          autocomplete="off"
                          label="Regular"
                          v-model="permissionsearch"
                          solo
                          dense
                          style="
                        margin-top: -0.8rem;
                        margin-left: -1rem;
                        color: black;
                      "
                          prepend-inner-icon="mdi-magnify"
                          placeholder="search"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-row>
                <v-data-table
                  :headers="permissionheaders"
                  style="margin-left: 10px; margin-right: 40px"
                  class="elevation-1"
                  :search="permissionsearch"
                  :items="permissionList"
                  :page.sync="pagePermission"
                  hide-default-header
                  :items-per-page="itemsPerPagePermission"
                  @page-count="pageCountPermission = $event"
                  hide-default-footer
                >
                  <template v-slot:header="{ props: {} }">
                    <thead>
                      <tr>
                        <th @click="sortdelete('companyCode')">
                          {{ permissionheaders[0].text }}
                        </th>
                        <th @click="sortdelete('companyName')">
                          {{ permissionheaders[1].text }}
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.permissionID }}</td>
                      <td>{{ item.permissionName }}</td>
                    </tr>
                  </template>
                </v-data-table>
                <div class="text-right">
                  <v-row justify="end" class="ml-5 mt-5 mr-5">
                    <v-col cols="12" md="3">
                      <v-pagination
                        v-model="pagePermission"
                        :length="pageCountPermission"
                        color="#FFCD2C"
                        :total-visible="6"
                      ></v-pagination>
                    </v-col>
                    <v-col cols="12" md="2" class="mt-1">
                      <v-select
                        dense
                        style="width: 120px; float: right"
                        solo
                        label="10/page"
                        v-model="itemsPerPagePermission"
                        :items="items"
                        @input="itemsPerPagePermission = parseInt($event, 10)"
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item> -->

              <v-tab-item>
                <br>
                <!-- New User Role Data Table -->
                <v-data-table
                  :headers="roleheaders"
                  :search="rolesearch"
                  :items="rolelist"
                  :page.sync="pageRole"
                  :items-per-page="itemsPerPageRole"
                  @page-count="pageCountRole = $event"
                  hide-default-header
                  hide-default-footer
                  style="margin-left: 10px; margin-right: 40px"
                  :mobile-breakpoint="0">
                  <!-- Top -->
                  <template v-slot:top>
                    <v-row class="mb-5">
                      <v-col cols="12" md="6">
                        <v-text-field
                          :label="$t('search')"
                          v-model="rolesearch"
                          prepend-inner-icon="mdi-magnify"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4"></v-col>
                    </v-row>
                  </template>    
                  <!-- Header -->
                  <template v-slot:header>
                    <thead >
                      <tr>
                        <th 
                          v-for="(roleheader, index) in roleheaders" 
                          :key="index"
                          class="data-table-header">{{ roleheader.text }}</th>
                      </tr>
                    </thead>
                  </template>   
                  <!-- Items -->
                  <template v-slot:item.roleName="{ item }">
                    <span @click="ViewDataRole(item); changeName(item.roleName)" style="text-decoration: underline; color: #24709E;cursor: pointer">{{ item.roleName }}</span>
                  </template>
                  </v-data-table>
                <!-- Old User Role Data Table -->
                <!-- <v-data-table
                  style="margin-left: 10px; margin-right: 40px"
                  class="mytable"
                  :headers="roleheaders"
                  :search="rolesearch"
                  :items="rolelist"
                  :page.sync="pageRole"
                  hide-default-header
                  :items-per-page="itemsPerPageRole"
                  @page-count="pageCountRole = $event"
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-row class="mb-5"> -->
                      <!-- Already Commented -->
                      <!-- <v-col cols="12" md="2">
                        <v-btn
                          width="180px"
                          color="#126496"
                          class="white--text"
                          router
                          @click="addRole"
                        >Add Role</v-btn>
                      </v-col> -->
                      <!-- Not Already Commented -->
                      <!-- <v-col cols="12" md="6">
                        <v-text-field
                          label="Search"
                          v-model="rolesearch"
                          prepend-inner-icon="mdi-magnify"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4"></v-col>
                    </v-row>
                  </template>
                  <template v-slot:header="{ props: {} }">
                    <thead>
                      <tr>
                        <th style="cursor: pointer; min-width: 180px !important;background-color: #126496;" @click="sortdelete('companyCode')">
                          {{ roleheaders[0].text }}
                        </th>
                        <th style="cursor: pointer; min-width: 180px !important;background-color: #126496;" @click="sortdelete('companyName')">
                          {{ roleheaders[1].text }}
                        </th> -->
                        <!-- Already Commented -->
                        <!-- <th style="cursor: pointer; min-width: 180px !important;background-color: #126496;" v-show="flag.role_edit && flag.role_delete">Action</th> -->
                        <!-- Not Already Commented -->
                      <!-- </tr>
                    </thead>
                  </template>
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.roleCode }}</td>
                      <td style="text-decoration: underline;color: #24709E;cursor: pointer;" @click="ViewDataRole(item)">{{ item.roleName }}</td> -->
                      <!-- Already Commented -->
                      <!-- <td class="pl-1">
                        <v-btn icon @click="editRole(item)" v-show="flag.role_edit">
                          <img :src="images.edit" />
                        </v-btn>
                        <v-btn icon @click="deleteRole(item.roleID)" v-show="flag.role_delete">
                          <img :src="images.delete" />
                        </v-btn>
                      </td> -->
                      <!-- Not Already Commented -->
                    <!-- </tr>
                  </template>
                </v-data-table> -->
                <div class="text-right">
                  <v-row justify="end" class="ml-5 mt-5 mr-5">                  
                    <v-col cols="12" md="2" class="mt-1">
                      <v-select
                        dense
                        style="width: 120px; float: right"
                        solo
                        label="10/page"
                        v-model="itemsPerPageRole"
                        :items="items"
                        @input="itemsPerPageRole = parseInt($event, 10)"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-pagination
                        v-model="pageRole"
                        :length="pageCountRole"
                        color="#FFCD2C"
                        :total-visible="6"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
        </div>
      </div>
    </v-card>

    <v-card
      style="box-shadow: 1px 1px 1px -1px rgb(0 0 0 / 3%), 0px 1px 1px 1px rgb(0 0 0 / 3%), 0px 1px 1px 0px rgb(0 0 0 / 3%)"
      v-show="ViewDataRolePermissionStatus == true"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        ><span><v-icon @click="HideViewDataRole">mdi-arrow-left-bold-circle-outline</v-icon></span> {{ dynamicName }}</div>
        <br />
        <div class="pl-5">
          <v-tabs class="mt-2" grey--text slider-color="#1976d2">
            <v-tab class="text-capitalize tabone">{{$t('permission')}}</v-tab>
            <v-tabs-slider style="margin-top: -10px;height: 5px;" color="#126496"></v-tabs-slider>
            <v-tab-item>
              <!-- New User Permission Data Table -->
              <v-data-table 
                :headers="ViewRolePermissionHeaders"
                :items="ViewDataRolePermissionList"
                :items-per-page="itemsPerPageViewDataRolePermission"
                :page.sync="pageViewDataRolePermission"
                class="mt-5"
                hide-default-header
                hide-default-footer
                :mobile-breakpoint="0">
                <!-- Top -->
                <template v-slot:top>
                  <v-row class="mb-5">
                    <v-col cols="12" md="8">
                      <v-text-field
                        :label="$t('search')"
                        v-model="ViewDataRolePermissionSearch"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        hide-details="auto"
                        v-on:keyup.enter="SearchViewDataRolePermission(ViewDataRolePermissionSearch)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>
                <!-- Header -->
                <template v-slot:header>
                  <thead>
                    <tr>
                      <th v-for="(header, index) in ViewRolePermissionHeaders" :key="index" class="data-table-header">
                        {{ header.text }}
                      </th>
                    </tr>
                  </thead>
                </template>
                <!-- Items -->
                <template v-slot:item.resource="{ item }">
                  <span>{{ item.resourceName }}</span>
                </template>
                <template v-slot:item.accessLevel="{ item }">
                  <span>{{ item.permissionName }}</span>
                </template>
              </v-data-table>
              <!-- Old User Permission Data Table -->
              <!-- <v-data-table
                class="mt-5"
                :headers="ViewRolePermissionHeaders"
                :items="ViewDataRolePermissionList"                
                hide-default-footer
                style="color: #444444"
                :items-per-page="itemsPerPageViewDataRolePermission"
                :page.sync="pageViewDataRolePermission"
                hide-default-header
              >
                <template v-slot:top>
                  <v-row class="mb-5">
                    <v-col cols="12" md="8">
                      <v-text-field
                        label="Search"
                        v-model="ViewDataRolePermissionSearch"
                        prepend-inner-icon="mdi-magnify"
                        outlined
                        dense
                        hide-details="auto"
                        v-on:keyup.enter="SearchViewDataRolePermission(ViewDataRolePermissionSearch)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:header="{ props: {} }">
                  <thead>
                    <tr>
                      <th style="cursor: pointer; min-width: 180px !important;background-color: #126496;">{{ ViewRolePermissionHeaders[0].text }}</th>
                      <th style="cursor: pointer; min-width: 180px !important;background-color: #126496;">{{ ViewRolePermissionHeaders[1].text }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td style="text-align: left;">{{ item.resourceName }}</td>
                    <td style="text-align: left;">{{ item.permissionName }}</td> -->
                    <!-- Already Commented -->
                    <!-- <td style="text-align: center;">None</td> -->
                    <!-- Not Already Commented -->
                  <!-- </tr>
                </template>
              </v-data-table> -->
              <v-row class="mt-5">
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="$vuetify.theme.dark ? 'without' : '#444444'"
                        v-bind="attrs"
                        v-on="on"
                        class="text-capitalize mt-1"
                        outlined
                      >
                        {{ itemsPerPageViewDataRolePermission }}
                        <v-icon color="primary">mdi-menu-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in paginationCount"
                        :key="index"
                        @click="
                      itemsPerPageViewDataRolePermission = item.title;
                      ChangePerPageViewDataRolePermission(itemsPerPageViewDataRolePermission);
                    "
                      >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-pagination
                    class="__pagination"
                    v-model="pageViewDataRolePermission"
                    :total-visible="6"
                    :length="pageCountViewDataRolePermission"
                    @input="ChangePageViewDataRolePermission(pageViewDataRolePermission)"
                  ></v-pagination>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </v-card>

    <v-card class="card-preview" v-show="PermissionPage == true">
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >{{$t('permission')}}</div>
        <v-row class="pt-5" style="margin-left: 25px; margin-right: 10px">
          <div style="width: 300px" class="ml-2 mt-3">
            <v-row>
              <v-col>
                <v-text-field
                  autocomplete="off"
                  :label="$t('regular')"
                  v-model="permissionsearch"
                  solo
                  dense
                  style="
                        margin-top: -0.8rem;
                        margin-left: -1rem;
                        color: black;
                      "
                  prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('search')"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-row>
        <v-data-table
          style="margin-left: 10px; margin-right: 40px"
          v-model="selected"
          v-bind:headers="permissionheaders"
          item-key="permissionID"
          show-select
          @click:row="handleClick"
          :search="permissionsearch"
          :items="permissionList"
          :page.sync="pagePermission"
          :items-per-page="itemsPerPagePermission"
          @page-count="pageCountPermission = $event"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <td>
                  <v-checkbox v-model="permission_status" @click="permissionstatus"></v-checkbox>
                </td>
                <td>{{ permissionheaders[0].text }}</td>
                <td>{{ permissionheaders[1].text }}</td>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-checkbox
                  v-model="item.select"
                  @click="item.select == true? item.select= false: item.select= true"
                ></v-checkbox>
              </td>
              <td>{{ item.permissionID }}</td>
              <td>{{ item.permissionName }}</td>
            </tr>
          </template>
          <!-- <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th @click="sortdelete('companyCode')">
                  {{ permissionheaders[0].text }}
                </th>
                <th @click="sortdelete('companyName')">
                  {{ permissionheaders[1].text }}
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.permissionID }}</td>
              <td>{{ item.permissionName }}</td>
            </tr>
          </template>-->
        </v-data-table>
        <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="pagePermission"
                :length="pageCountPermission"
                color="#FFCD2C"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPagePermission"
                :items="items"
                @input="itemsPerPagePermission = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
        <div style="float:right;margin-bottom:8px;">
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="hidePermission"
            text
          >{{$t('cancel')}}</v-btn>
          <v-btn
            color="#1976d2"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="AddPermissionList()"
          >{{$t('save')}}</v-btn>
        </div>
      </div>
    </v-card>

    <!-- Role -->
    <!-- Current -->
    <v-card class="card-preview" v-show="GroupPage == true">  
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >{{$t('role')}}</div>
        <v-row class="pt-5" style="margin-left: 25px; margin-right: 10px">
          <div style="width: 300px" class="ml-2 mt-3">
            <v-row>
              <v-col>
                <v-text-field
                  autocomplete="off"
                  :label="$t('regular')"
                  v-model="rolesearch"
                  solo
                  dense
                  style="
                        margin-top: -0.8rem;
                        margin-left: -1rem;
                        color: black;
                      "
                  prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('search')"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-row>
        
        <!-- New Role Data Table -->
        <v-data-table
          :headers="roleHeadersRadio"
          :search="rolesearch"
          :items="rolelist"
          :page.sync="pageRole"
          :items-per-page="itemsPerPageRole"
          @page-count="pageCountRole = $event"
          hide-default-header
          hide-default-footer
          style="margin-left: 10px; margin-right: 40px"
          class="mytable"
          item-key="roleID">
          <template v-slot:header>
            <thead class="hide-header">
              <tr>
                <th 
                  v-for="(header, index) in roleHeadersRadio" 
                  :key="index"
                  class="data-table-header">
                  {{ header.text }}
                </th>
              </tr>
            </thead>
          </template>         
          <template v-slot:item.roleRadio="{ item }">
            <span>
              <v-radio-group v-model="RoleIDUser">
                <!-- <input type="radio" v-model="RoleIDUser" :value="item.roleID" :key="item.roleID" class="margin-right-clear"> -->
                <label>
                <v-radio
                  :value="item.roleID"
                  :key="item.roleID"
                  class="margin-right-clear"
                ></v-radio>
                </label>
              </v-radio-group>
            </span>
          </template>
        </v-data-table>
        <!-- Old Role Data Table -->
        <!-- <v-data-table
          style="margin-left: 10px; margin-right: 40px"
          class="mytable"
          item-key="roleID"
          v-bind:headers="roleheaders"
          :search="rolesearch"
          :items="rolelist"
          :page.sync="pageRole"
          :items-per-page="itemsPerPageRole"
          @page-count="pageCountRole = $event"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <td> -->
                  <!-- Already Commented -->
                  <!-- <v-checkbox v-model="role_status" @click="rolestatus"></v-checkbox> -->
                  <!-- Not Already Commented -->
                <!-- </td>
                <td>{{ roleheaders[0].text }}</td>
                <td>{{ roleheaders[1].text }}</td>
              </tr>
            </thead> -->
            <!-- Already Commented -->
            <!-- <div class="checkbox" style="padding-left:0px;padding-bottom:12px">
              <v-checkbox v-model="permission_status" @change="permissionstatus()"></v-checkbox>
            </div>-->
            <!-- Not Already Commented -->
          <!-- </template>         
          <template v-slot:item="{ item }">
            <tr>
              <td> -->
                <!-- Already Commented -->
                <!-- {{item}} -->
                <!-- <v-checkbox
                  v-model="item.select"
                  @click="item.select == true ? item.select = false: item.select = true;CheckStatusTrue();"
                ></v-checkbox> -->
                <!-- {{RoleIDUser}} -->
                <!-- Not Already Commented -->
                <!-- <v-radio-group v-model="RoleIDUser">
                  <v-radio
                    label=""
                    :value="item.roleID"
                    :key="item.roleID"
                  ></v-radio>
                </v-radio-group> -->
                <!-- Already Commented -->
                <!-- <v-radio
                  v-model="item.select"
                  @click="item.select == true ? item.select = false: item.select = true;CheckStatusTrue();"
                ></v-radio> -->
                <!-- <input type="radio" name="a" @click="item.select == true ? item.select = false: item.select = true;CheckStatusTrue();" > -->
                <!-- Not Already Commented -->
              <!-- </td>
              <td>{{ item.roleCode }}</td>
              <td>{{ item.roleName }}</td>
            </tr>
          </template>
        </v-data-table> -->
        
        <!-- New Pagination -->
        <div style="padding-bottom: 20px">
          <v-row no-gutters style="margin-left: 10px; margin-right: 40px">
            <v-col lg="9" md="9" sm="7"></v-col>
            <v-col lg="2" md="2" cols="12" sm="3">
              <v-pagination
                v-model="pageRole"
                :length="pageCountRole"
                color="#FFCD2C"
                :total-visible="6"></v-pagination>
            </v-col>
            <v-col lg="1" md="1" cols="12" sm="2">
              <v-select
                dense
                solo
                label="10/page"
                v-model="itemsPerPageRole"
                :items="items"
                @input="itemsPerPageRole = parseInt($event, 10)"></v-select>
            </v-col>
            <v-col lg="9" md="9"  sm="7"></v-col>
            <v-col lg="2" md="2" cols="6" sm="3">
              <v-btn
                class="mr-2 text-capitalize full-btn-width"
                min-width="120"
                @click="hideRole"
                text>{{$t('cancel')}}</v-btn>
            </v-col>
            <v-col lg="1" md="1" sm="2" cols="6" style="display: flex; justify-content: flex-end">
              <v-btn
                color="#1976d2"
                class="white--text text-capitalize full-btn-width"
                min-width="120"
                style="float: right"
                @click="AddGroupRole()">{{$t('save')}}</v-btn>
            </v-col>
          </v-row>
        </div>
        
        <!-- Old Pagination -->
        <!-- <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">            
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPageRole"
                :items="items"
                @input="itemsPerPageRole = parseInt($event, 10)"
              ></v-select>
            </v-col>
            <v-col cols="12" md="8">
              <v-pagination
                v-model="pageRole"
                :length="pageCountRole"
                color="#FFCD2C"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="1">
              <v-btn
                class="mr-2 text-capitalize"
                width="120"
                max-height="35"
                @click="hideRole"
                text
              >Cancel</v-btn>
            </v-col>
            <v-col cols="12" md="1">
              <v-btn
                color="#1976d2"
                class="mr-2 white--text text-capitalize"
                width="120"
                max-height="35"
                @click="AddGroupRole()"
              >Save</v-btn>
            </v-col>
          </v-row>
        </div> -->
        <!-- <div style="float:right;margin-bottom:8px;">
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="hideRole"
            text
          >Cancel</v-btn>
          <v-btn
            color="#1976d2"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="AddGroupRole()"
          >Save</v-btn>
        </div> -->
      </div>
    </v-card>
    
    <v-card class="card-preview" v-show="RolePage == true">
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >{{$t('usergroup')}}</div>
        <v-row class="pt-5" style="margin-left: 25px; margin-right: 10px">
          <div style="width: 300px" class="ml-2 mt-3">
            <v-row>
              <v-col>
                <v-text-field
                  autocomplete="off"
                  :label="$t('regular')"
                  v-model="groupsearch"
                  solo
                  dense
                  style="
                        margin-top: -0.8rem;
                        margin-left: -1rem;
                        color: black;
                      "
                  prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('search')"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-row>
        <!-- New Inside User Group Data Table -->
        <v-data-table
          :headers="groupheaders"
          :search="groupsearch"
          :items="groupList"
          :page.sync="pageGroup"
          :items-per-page="itemsPerPageGroup"
          @page-count="pageCountGroup = $event"
          hide-default-header
          hide-default-footer
          style="margin-left: 10px; margin-right: 40px"
          class="mytable"
          item-key="roleID">
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <td class="data-table-header">
                  <v-checkbox v-model="group_status" @click="groupstatus"></v-checkbox>
                </td>
                <td class="data-table-header" style="font-size: 16px; color: white">{{ groupheaders[0].text }}</td>
              </tr>
            </thead>
            <!-- <div class="checkbox" style="padding-left:0px;padding-bottom:12px">
              <v-checkbox v-model="permission_status" @change="permissionstatus()"></v-checkbox>
            </div>-->
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-checkbox
                  v-model="item.select"
                  @click="item.select == true? item.select= false: item.select= true"
                ></v-checkbox>
              </td>
              <td>{{ item.groupName }}</td>
            </tr>
          </template>
        </v-data-table>
        <!-- Old Inside User Group Data Table -->
        <!-- <v-data-table
          style="margin-left: 10px; margin-right: 40px"
          class="mytable"
          item-key="roleID"
          v-bind:headers="groupheaders"
          :search="groupsearch"
          :items="groupList"
          :page.sync="pageGroup"
          :items-per-page="itemsPerPageGroup"
          @page-count="pageCountGroup = $event"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <td>
                  <v-checkbox v-model="group_status" @click="groupstatus"></v-checkbox>
                </td>
                <td>{{ groupheaders[0].text }}</td>
              </tr>
            </thead> -->
            <!-- Already Commented -->
            <!-- <div class="checkbox" style="padding-left:0px;padding-bottom:12px">
              <v-checkbox v-model="permission_status" @change="permissionstatus()"></v-checkbox>
            </div>-->
            <!-- Not Already Commented -->
          <!-- </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-checkbox
                  v-model="item.select"
                  @click="item.select == true? item.select= false: item.select= true"
                ></v-checkbox>
              </td>
              <td>{{ item.groupName }}</td>
            </tr>
          </template>
        </v-data-table> -->
        <!-- New Pagination -->
        <div style="padding-bottom: 20px">
          <v-row no-gutters style="margin-left: 10px; margin-right: 40px">
            <v-col lg="9" md="9" sm="5"></v-col>
            <v-col lg="2" md="2" cols="12" sm="5">
              <v-pagination
                v-model="pageGroup"
                :length="pageCountGroup"
                color="#FFCD2C"
                :total-visible="6"></v-pagination>
            </v-col>
            <v-col lg="1" md="1" cols="12" sm="2">
              <v-select
                dense
                solo
                label="10/page"
                v-model="itemsPerPageGroup"
                :items="items"
                @input="itemsPerPageGroup = parseInt($event, 10)"></v-select>
            </v-col>
            <v-col lg="10" md="9" sm="7"></v-col>
            <v-col lg="1" md="2" cols="6" sm="3">
              <v-btn
                class="mr-2 text-capitalize full-btn-width"
                min-width="120"
                @click="hideRole"
                text>{{$t('cancel')}}</v-btn>
            </v-col>
            <v-col lg="1" md="1" sm="2" cols="6" style="display: flex; justify-content: flex-end">
              <v-btn
                color="#1976d2"
                class="white--text text-capitalize full-btn-width"
                min-width="120"
                style="float: right"
                @click="AddGroupRole()">{{$t('save')}}</v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- Old Pagination -->
        <!-- <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="2">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPageGroup"
                :items="items"
                @input="itemsPerPageGroup = parseInt($event, 10)"
              ></v-select>
              
            </v-col>
            <v-col cols="12" md="4" class="mt-1">
              <v-pagination
                v-model="pageGroup"
                :length="pageCountGroup"
                color="#FFCD2C"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                class="mr-2 text-capitalize"
                width="120"
                max-height="35"
                @click="hideRole"
                text
              >Cancel</v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                color="#1976d2"
                class="mr-2 white--text text-capitalize"
                width="120"
                max-height="35"
                @click="AddEmpGroup()"
              >Save</v-btn>
            </v-col>
          </v-row>
        </div> -->
        <!-- <div style="float:right;margin-bottom:8px;">
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="hideRole"
            text
          >Cancel</v-btn>
          <v-btn
            color="#1976d2"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="AddEmpGroup()"
          >Save</v-btn>
        </div> -->
      </div>
    </v-card>

    <v-card class="card-preview" v-show="EmpPage == true">
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 18px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >{{$t('groupmember')}}</div>
        <v-row class="pt-5" style="margin-left: 25px; margin-right: 10px">
          <div style="width: 300px" class="ml-2 mt-3">
            <v-row>
              <v-col>
                <v-text-field
                  autocomplete="off"
                  :label="$t('regular')"
                  v-model="search"
                  @keyup.enter="SearchGroupMember"
                  solo
                  dense
                  style="
                        margin-top: -0.8rem;
                        margin-left: -1rem;
                        color: black;
                      "
                  prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('search')"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-row>
        <v-data-table
          style="margin-left: 10px; margin-right: 40px"
          class="mytable"
          item-key="roleID"
          v-bind:headers="employeeheaders"
          :items="empList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-header
          hide-default-footer
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <td>
                  <v-checkbox v-model="emp_stauts" @click="empstatus"></v-checkbox>
                </td>
                <td>{{ employeeheaders[0].text }}</td>
                <td>{{ employeeheaders[1].text }}</td>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <v-checkbox v-model="item.select" @click="CheckSelectCheckbox(item)"></v-checkbox>
              </td>
              <td>{{ item.username }}</td>
              <td>{{ item.employeeName }}</td>
            </tr>
          </template>
        </v-data-table>
        <!-- <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination v-model="page" :length="pageCount" color="#FFCD2C" :total-visible="6"></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage1 = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>-->

        <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCountUser"
                color="#FFCD2C"
                :total-visible="6"
                @input="ChangePageAssign(page)"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                @input="ChangePerPageAssign(itemsPerPage)"
                :items="items"
              ></v-select>
            </v-col>
          </v-row>
        </div>

        <div style="float:right;margin-bottom:8px;">
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="closeEmpPage"
            text
          >{{$t('cancel')}}</v-btn>
          <v-btn
            color="#1976d2"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="AddGroupEmp()"
          >{{$t('save')}}</v-btn>
        </div>
      </div>
    </v-card>
    <!-- New Add Employee Dialog -->
    <v-dialog v-model="addEmpDialog" persistent max-width="750">
      <v-card>
        <v-card-title>
          <p>{{$t('addUser')}}</p>
          <v-spacer></v-spacer>
          <v-btn 
            style="margin-top: -10px" 
            @click="closeaddEmpDialog" 
            icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters style="font-size: 16px">
              <!-- User Name -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('username')}}:</p>
              </v-col>
              <v-col lg="10" cols="12">
                <v-text-field
                  dense
                  solo
                  :placeholder="$t('username')"
                  v-model="addUsername"
                  ></v-text-field>
              </v-col>
              <!-- First Name -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('firstname')}}:</p>
              </v-col>
              <v-col lg="10" cols="12">
                <v-text-field
                  dense
                  solo
                  :placeholder="$t('firstname')"
                  v-model="addFirstname"
                  ></v-text-field>
              </v-col>
              <!-- Last Name -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('lastname')}}:</p>
              </v-col>
              <v-col lg="10" cols="12">
                <v-text-field 
                  dense 
                  :placeholder="$t('lastname')"
                  solo 
                  v-model="addLastname"></v-text-field>
              </v-col>
              <!-- Phone Number -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('phonenumber')}}:</p>
              </v-col>
              <v-col lg="10" cols="12">
                <v-text-field 
                  dense
                  :placeholder="$t('phonenumber')"
                  maxlength="11" 
                  solo 
                  v-model="addPhonenum" 
                  @keypress="isNumber($event)"></v-text-field>           
              </v-col>
              <!-- Email -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('email')}}:</p>
              </v-col>
              <v-col lg="10" cols="12">
                <v-text-field 
                  dense
                  :placeholder="$t('email')" 
                  solo 
                  v-model="addEmail"></v-text-field>           
              </v-col>
              <!-- Password -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('password')}}:</p>
              </v-col>
              <v-col lg="10" cols="12">
                <v-text-field 
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1"
                  dense
                  :placeholder="$t('password')" 
                  id="password" 
                  solo 
                  v-model="addPassword"></v-text-field>           
              </v-col>
              <!-- User Group -->
              <!-- <v-col lg="2" md="2" sm="12" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">User Group:</p>
              </v-col>
              <v-col lg="8" md="8" sm="10" cols="8">
                <v-text-field 
                  dense
                  placeholder="User Group" 
                  solo 
                  style="width: 98%"
                  v-model="addEmpGroup"></v-text-field>           
              </v-col>
              <v-col lg="2" md="2" sm="2" cols="4">
                <v-btn 
                  style="width: 100%; color: white" 
                  color="#126496" 
                  @click="clickaddGroupEmployee">Join</v-btn>          
              </v-col>  -->
              <!-- Role -->
              <v-col lg="2" md="2" sm="12" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('role')}}:</p>
              </v-col>
              <v-col lg="8" md="8" sm="10" cols="8">
                <v-text-field 
                  dense 
                  :placeholder="$t('role')"
                  disabled 
                  solo 
                  v-model="addEmpRole"
                  style="width: 98%"></v-text-field>           
              </v-col>
              <v-col lg="2" md="2" sm="2" cols="4">
                <v-btn 
                  style="width: 100%; color: white" 
                  color="#126496" 
                  @click="clickaddGroupRole">{{$t('assign')}}</v-btn>          
              </v-col> 
              <!-- Check Box -->
              <v-col cols="12">
                <div style="display: flex;margin-bottom:30px;"> 
                    <input type="checkbox" id="chkadmin" v-model="FlagAdmin" style="height:100%;cursor:pointer" >
                    <label for="chkadmin" style="height:100%;margin-top:-5px;margin-left:5px;cursor:pointer"> {{$t('admin')}}</label>
                    <input type="checkbox" id="chksinger" v-model="ActiveStatus" style="height:100%; margin-left:20px; cursor:pointer" v-show="addEmpRole =='Signer'">
                    <label for="chksinger" style="height:100%;margin-top:-5px;margin-left:5px;cursor:pointer" v-show="addEmpRole =='Signer'"> {{$t('activate')}}</label>
                    <!-- <v-checkbox
                      :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]"
                      v-model="FlagAdmin" 
                      label="Admin" 
                      class="mr-2"></v-checkbox> 
                    <v-checkbox v-show="addEmpRole == 'Signer'"
                      :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]"
                      v-model="ActiveStatus" 
                      label="Activate" 
                      class="mr-2"></v-checkbox>  -->
                </div>     
              </v-col>
              <div class="lock_button"> 
                <v-icon v-if="lockStatus" @click="lockStatus = false">mdi-lock-outline</v-icon>
                <v-icon v-else @click="lockStatus = true">mdi-lock-open-variant-outline</v-icon>
              </div>
              <span class="ml-1">{{$t('accountLocked')}}</span> 
              <!-- Permission -->
              <v-row no-gutters v-show="advanceGroup == true">
                <v-col lg="2" md="2" sm="12" cols="12">
                  <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('permission')}}:</p>
                </v-col>
                <v-col lg="8" md="8" sm="10" cols="8">
                  <v-text-field 
                    dense 
                    placeholder="User Group" 
                    disabled 
                    solo 
                    style="width: 98%"
                    v-model="addEmpPermission"></v-text-field>
                </v-col>
                <v-col lg="2" md="2" sm="2" cols="4">
                  <v-btn 
                    style="color: #fff; width: 100%" 
                    color="#126496" 
                    @click="clickPermission">{{$t('assign')}}</v-btn>
                </v-col>
              </v-row>
            </v-row>
            <hr>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-top: -25px">
          <v-container style="padding-right: 30px; padding-left: 30px">
            <div style="display: flex; justify-content: flex-end" class="space-between">
              <v-btn class="mr-2 min-width" min-width="120" @click="closeaddEmpDialog" text>{{$t('cancel')}}</v-btn>
              <v-btn color="#126496" class="min-width" style="color: #fff" min-width="120" @click="AddEmployee()">{{$t('save')}}</v-btn>
            </div>  
          </v-container>
        </v-card-actions> 
      </v-card>
    </v-dialog>

    <!-- Old Add Employee Dialog -->
    <!-- <v-dialog v-model="addEmpDialog" persistent width="750">
      <v-card>
        <v-card-title>
          <p>Add User</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="closeaddEmpDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-row class="ml-5 mr-2">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">User Name :</p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field placeholder="User Name" solo v-model="addUsername"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">First Name :</p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field placeholder="First Name" solo v-model="addFirstname"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Last Name :</p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field placeholder="Last Name" solo v-model="addLastname"></v-text-field>
          </v-col>
        </v-row>           
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Phone Number :</p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field placeholder="Phone Number" maxlength="11" solo v-model="addPhonenum" @keypress="isNumber($event)"></v-text-field>           
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Email :</p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field placeholder="Email" solo v-model="addEmail"></v-text-field>           
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Password :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="Password" id="password" :type="typepassword" solo v-model="addPassword"></v-text-field>           
          </v-col>
          <v-col cols="12" md="2" style="margin-top: 10px;">
            <v-icon
                v-show="AddflagPassword"
                style="position:absolute;"
                @click="TogglePassword()"
              >mdi-eye</v-icon>
              <v-icon
                v-show="!AddflagPassword"
                style="position:absolute;"
                @click="TogglePassword()"
              >mdi-eye-off</v-icon>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">User Group :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="User Group" solo v-model="addEmpGroup"></v-text-field>           
          </v-col>
          <v-col cols="12" md="2">
            <v-btn style="margin-top: 5px;color: white" color="#126496" @click="clickaddGroupEmployee">Join</v-btn>          
          </v-col>
        </v-row> 
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Role :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="Role" disabled solo v-model="addEmpRole"></v-text-field>           
          </v-col>
          <v-col cols="12" md="2">
            <v-btn style="margin-top: 5px;color: white" color="#126496" @click="clickaddGroupRole">Assign</v-btn>          
          </v-col>
        </v-row> 
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2"></v-col>
          <v-col cols="12" md="3">
            <v-checkbox
              v-model="FlagAdmin"
              label="Admin"
            ></v-checkbox>        
          </v-col>
          <v-col cols="12" md="2" v-show="addEmpRole == 'Signer'">
            <v-checkbox
              v-model="ActiveStatus"
              label="Activate"
            ></v-checkbox>   
          </v-col>
          <v-col cols="12" md="5"></v-col>
        </v-row>  -->
        <!-- Already Commented -->
        <!-- <v-row class="ml-5 mr-2 mt-3">
          <v-col md="5" lg="5" col="5">
            <p
              class="mytitle"
              style="cursor:pointer;"
              @click="advanceGroup == true? advanceGroup = false: advanceGroup = true"
            >
              Advance
              <v-icon small v-if="!advanceGroup">mdi-arrow-down</v-icon>
              <v-icon small v-if="advanceGroup">mdi-arrow-up</v-icon>
            </p>
          </v-col>
        </v-row> -->
        <!-- <v-row class="ml-5 mr-2 mt-3" v-show="advanceGroup == true">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Permission :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="User Group" disabled solo v-model="addEmpPermission"></v-text-field>           
          </v-col>
          <v-col cols="12" md="2">
            <v-btn style="margin-top: 5px;color: white" color="#126496" @click="clickPermission">Assign</v-btn>          
          </v-col>
        </v-row> -->
        <!-- Not Already Commented -->
        <!-- <hr class="ml-5 mr-4" />
        <br>
        <v-card-actions style="margin-right: 3%;margin-left:3%;" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="closeaddEmpDialog"
            text
          >Cancel</v-btn>
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="AddEmployee()"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!-- New Edit Employee Dialog -->
    <v-dialog v-model="editEmpDialog" persistent max-width="750">
      <v-card>
        <v-card-title>
          <p>{{$t('editUser')}}</p>
          <v-spacer></v-spacer>
          <v-btn 
            style="margin-top: -10px" 
            @click="closeEmpDialog" 
            icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters style="font-size: 16px">
              <!-- User Name -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('username')}}:</p>
              </v-col>
              <v-col lg="10" cols="12">
                <v-text-field
                  dense
                  solo
                  :placeholder="$t('username')"
                  v-model="editUsername"
                  ></v-text-field>
              </v-col>
              <!-- First Name -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('firstname')}}:</p>
              </v-col>
              <v-col lg="10" cols="12">
                <v-text-field
                  dense
                  solo
                  :placeholder="$t('firstname')"
                  v-model="editFirstname"></v-text-field>
              </v-col>
              <!-- Last Name -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('lastname')}}:</p>
              </v-col>
              <v-col lg="10" cols="12">
                <v-text-field 
                  dense 
                  :placeholder="$t('lastname')" 
                  solo 
                  v-model="editLastName"></v-text-field>
              </v-col>
              <!-- Phone Number -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('phonenumber')}}:</p>
              </v-col>
              <v-col lg="10" cols="12">
                <v-text-field 
                  dense
                  :placeholder="$t('phonenumber')" 
                  maxlength="11" 
                  solo 
                  v-model="editPhonenum" 
                  @keypress="isNumber($event)"></v-text-field>           
              </v-col>
              <!-- Email -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('email')}}:</p>
              </v-col>
              <v-col lg="10" cols="12">
                <v-text-field 
                  dense
                  :placeholder="$t('email')" 
                  solo 
                  v-model="editEmail"></v-text-field>           
              </v-col>
              <!-- Password -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('password')}}:</p>
              </v-col>
              <v-col lg="10" cols="12">
                <v-text-field 
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1"
                  dense
                  :placeholder="$t('password')" 
                  id="password" 
                  solo 
                  v-model="editPassword"></v-text-field>           
              </v-col>
              <!-- User Group -->
              <!-- <v-col lg="2" md="2" sm="12" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">User Group:</p>
              </v-col>
              <v-col lg="8" md="8" sm="10" cols="8">
                <v-text-field 
                  dense
                  placeholder="User Group" 
                  solo 
                  style="width: 98%"
                  v-model="editEmpGroup"></v-text-field>           
              </v-col>
              <v-col lg="2" md="2" sm="2" cols="4">
                <v-btn 
                  style="width: 100%; color: white" 
                  color="#126496" 
                  @click="clickaddGroupEmployee">Join</v-btn>          
              </v-col>  -->
              <!-- Role -->
              <v-col lg="2" md="2" sm="12" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]">{{$t('role')}}:</p>
              </v-col>
              <v-col lg="8" md="8" sm="10" cols="8">
                <v-text-field 
                  dense 
                  :placeholder="editEmpRole"
                  disabled 
                  solo 
                  :v-model="editEmpRole"
                  style="width: 98%"></v-text-field>           
              </v-col>
              <v-col lg="2" md="2" sm="2" cols="4">
                <v-btn 
                  style="width: 100%; color: white" 
                  color="#126496" 
                  @click="clickaddGroupRole">{{$t('assign')}}</v-btn>          
              </v-col> 
              <!-- Check Box -->
              <v-col cols="12">
                <div style="display: flex; margin-bottom:35px;">
                    <input type="checkbox" id="chkAdmin" v-model="editFlagAdmin" style="height:100%;cursor:pointer" v-if="OwnAdmin == false">
                    <label for="chkAdmin" v-if="OwnAdmin == false" style="height:100%;margin-top:-5px;margin-left:5px;cursor:pointer"> {{$t('admin')}}</label>
                    <input type="checkbox" id="chkAdmin1" v-model="editFlagAdmin" style="height:100%;" v-if="OwnAdmin == true" disabled>
                    <label for="chkAdmin1" v-if="OwnAdmin == true" disabled style="height:100%;margin-top:-5px;margin-left:5px;"> {{$t('admin')}}</label>
                    <input type="checkbox" id="chkSinger" v-model="editActiveStatus" style="height:100%;cursor:pointer;margin-left:20px;" v-show="editEmpRole=='Signer'">
                    <label for="chkSinger" style="height:100%;margin-top:-5px;margin-left:5px;cursor:pointer" v-show="editEmpRole=='Signer'"> {{$t('activate')}}</label>
                    <!-- <v-checkbox v-if="OwnAdmin == false"
                      :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px','height':'20px !important','width':'15px !important' } : { 'margin-top': '10px' } ]"
                      v-model="editFlagAdmin" 
                      dense
                      label="Admin" 
                      class="mr-2"></v-checkbox>
                    <v-checkbox
                      v-else
                      :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]"
                      v-model="editFlagAdmin" 
                      dense
                      disabled
                      label="Admin" 
                      class="mr-2"></v-checkbox>
                    <v-checkbox
                      v-show="editEmpRole == 'Signer'"
                      :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px' } : { 'margin-top': '10px' } ]"
                      v-model="editActiveStatus" 
                      label="Activate" 
                      dense
                      class="mr-2 chk"></v-checkbox> -->
                </div>     
              </v-col>
              
              <div class="lock_button"> 
                <v-icon v-if="lockStatus" @click="lockStatus = false">mdi-lock-outline</v-icon>
                <v-icon v-else @click="lockStatus = true">mdi-lock-open-variant-outline</v-icon>
              </div>
              <span class="ml-1">{{$t('accountLocked')}}</span> 
            </v-row>
            <hr>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-top: -25px">
          <v-container style="padding-right: 30px; padding-left: 30px">
            <div style="display: flex; justify-content: flex-end" class="space-between">
              <v-btn class="mr-2 min-width" min-width="120" @click="closeEmpDialog" text>{{$t('cancel')}}</v-btn>
              <v-btn color="#126496" class="min-width" style="color: #fff" min-width="120" @click="EditEmployee()">{{$t('save')}}</v-btn>
            </div>  
          </v-container>
        </v-card-actions> 
      </v-card>
    </v-dialog>
    <!-- Old Edit Employee Dialog -->
    <!-- <v-dialog v-model="editEmpDialog" persistent width="750">
      <v-card>
        <v-card-title>
          <p>Edit User</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="closeEmpDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-row class="ml-5 mr-2">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">User Name :</p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field placeholder="User Name" solo v-model="editUsername"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">First Name :</p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field placeholder="First Name" solo v-model="editFirstname"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Last Name :</p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field placeholder="Last Name" solo v-model="editLastName"></v-text-field>
          </v-col>
        </v-row>           
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Phone Number :</p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field placeholder="Phone Number" maxlength="11" solo v-model="editPhonenum" @keypress="isNumber($event)"></v-text-field>           
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Email :</p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field placeholder="Email" solo v-model="editEmail"></v-text-field>           
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Password :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="Password" id="password" :type="typepassword" solo v-model="editPassword"></v-text-field>           
          </v-col>
          <v-col cols="12" md="2" style="margin-top: 10px;">
            <v-icon style="position:absolute;" v-if="checkpassword">mdi-lock-outline</v-icon>
            <v-icon style="position:absolute;" v-if="!checkpassword">mdi-lock-open-outline</v-icon>        
          </v-col>          -->
          <!-- Already Commented -->
          <!-- <v-col cols="12" md="2" style="margin-top: 10px;">
            <v-icon
                v-show="AddflagPassword"
                style="position:absolute;"
                @click="TogglePassword()"
              >mdi-eye</v-icon>
              <v-icon
                v-show="!AddflagPassword"
                style="position:absolute;"
                @click="TogglePassword()"
              >mdi-eye-off</v-icon>
          </v-col> -->
        <!-- Not Already Commented -->
        <!-- </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">User Group :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="User Group" disabled solo v-model="editEmpGroup"></v-text-field>           
          </v-col>
          <v-col cols="12" md="2">
            <v-btn style="margin-top: 5px;color: white" color="#126496" @click="clickaddGroupEmployee">Join</v-btn>          
          </v-col>
        </v-row> 
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Role :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="Role" disabled solo v-model="editEmpRole"></v-text-field>           
          </v-col>
          <v-col cols="12" md="2">
            <v-btn style="margin-top: 5px;color: white" color="#126496" @click="clickaddGroupRole">Assign</v-btn>          
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2"></v-col>
          <v-col cols="12" md="3"  v-if="OwnAdmin == false">
            <v-checkbox
              v-model="editFlagAdmin"
              label="Admin"
            ></v-checkbox>           
          </v-col>
          <v-col cols="12" md="3" v-else>
            <v-checkbox
              disabled
              v-model="editFlagAdmin"
              label="Admin"
            ></v-checkbox>           
          </v-col>

          <v-col cols="12" md="2" v-show="editEmpRole == 'Signer'">
            <v-checkbox
              v-model="editActiveStatus"
              label="Activate"
            ></v-checkbox>   
          </v-col>
                 
          <v-col cols="12" md="5"></v-col>
        </v-row>  -->
        <!-- Already Commented -->
        <!-- <v-row class="ml-5 mr-2 mt-3">
          <v-col md="5" lg="5" col="5">
            <p
              class="mytitle"
              style="cursor:pointer;"
              @click="advanceGroup == true? advanceGroup = false: advanceGroup = true"
            >
              Advance
              <v-icon small v-if="!advanceGroup">mdi-arrow-down</v-icon>
              <v-icon small v-if="advanceGroup">mdi-arrow-up</v-icon>
            </p>
          </v-col>
        </v-row> -->
        <!-- <v-row class="ml-5 mr-2 mt-3" v-show="advanceGroup == true">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Permission :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="User Group" disabled solo v-model="editEmpPermission"></v-text-field>           
          </v-col>
          <v-col cols="12" md="2">
            <v-btn style="margin-top: 5px;color: white" color="#126496" @click="clickPermission">Assign</v-btn>          
          </v-col>
        </v-row> -->
        <!-- Not Already Commented -->
        <!-- <hr class="ml-5 mr-4" />
        <br>
        <v-card-actions style="margin-right: 3%;margin-left:3%;" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="closeEmpDialog"
            text
          >Cancel</v-btn>
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="EditEmployee()"
          >Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- Delete Employee Role -->
    <v-dialog v-model="deleteEmpDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">{{$t('deleteUser')}}</p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">{{$t('areYouSureWantToDelete?')}}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="deleteEmpDialog = false"
            text
          >{{$t('cancel')}}</v-btn>
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="DeleteEmp()"
          >{{$t('confirm')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Role Dialog -->
    <v-dialog v-model="addRoleDialog" persistent width="550">
      <v-card>
        <v-card-title>
          <p>{{$t('addRole')}}</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="closeaddRoleDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <hr class="ml-5 mr-4" />
        <v-row class="ml-5 mr-2 mt-3">
          <v-col lg="12" md="12" cols="12">
            <p class="mytitle">{{$t('roleName')}}*</p>
            <input class="input-border" type="text" v-model="addRoleName" />
          </v-col>
          <v-col cols="12">
            <p class="mytitle">{{$t('roleID')}}*</p>
            <input class="input-border" type="text" v-model="addRoleCode" />
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="8" col="8">
            <p class="mytitle">{{$t('permission')}}*</p>
            <input class="input-border" type="text" disabled v-model="addRolePermission" />
          </v-col>
          <v-col md="4" col="4">
            <v-btn style="margin-top: 35px;" color="primary" @click="clickPermission">{{$t('assign')}}</v-btn>
          </v-col>
        </v-row>
        <v-card-actions style="margin-right: 3%;margin-left:3%;" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="closeaddRoleDialog"
            text
          >{{$t('cancel')}}</v-btn>
          <v-btn
            color="#1976d2"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="AddRole()"
          >{{$t('save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Role Dialog -->
    <v-dialog v-model="editRoleDialog" persistent width="550">
      <v-card>
        <v-card-title>
          <p>{{$t('updateRole')}}</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="closeeditRoleDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <hr class="ml-5 mr-4" />
        <v-row class="ml-5 mr-2 mt-3">
          <v-col lg="12" md="12" cols="12">
            <p class="mytitle">{{$t('roleName')}}*</p>
            <input class="input-border" type="text" v-model="editRoleName" />
          </v-col>
          <v-col cols="12">
            <p class="mytitle">{{$t('roleID')}}*</p>
            <input class="input-border" type="text" v-model="editRoleCode" />
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="8" col="8">
            <p class="mytitle">{{$t('permission')}}*</p>
            <input class="input-border" type="text" disabled v-model="editRolePermission" />
          </v-col>
          <v-col md="4" col="4">
            <v-btn style="margin-top: 35px;" color="primary" @click="clickPermission">{{$t('assign')}}</v-btn>
          </v-col>
        </v-row>
        <v-card-actions style="margin-right: 3%;margin-left:3%;" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="closeeditRoleDialog"
            text
          >{{$t('cancle')}}</v-btn>
          <v-btn
            color="#1976d2"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="UpdateRole()"
          >{{$t('update')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Role Dialog-->
    <v-dialog v-model="deleteRoleDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">{{$t('deleteRole')}}</p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">{{$t('areYouSureWantToDelete?')}}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="deleteRoleDialog = false"
            text
          >{{$t('cancel')}}</v-btn>
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="DeleteRole()"
          >{{$t('confirm')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- New Add Group Dialog -->
    <v-dialog v-model="addGroupDialog" persistent max-width="750">
      <v-card>
        <v-card-title>
          <p>{{$t('addGroup')}}</p>
          <v-spacer></v-spacer>
          <v-btn 
            style="margin-top: -10px" 
            @click="closeaddGroupDialog" 
            icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <!-- Group Name -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px', 'font-size': '16px' } : { 'margin-top': '10px', 'font-size': '16px' } ]">{{$t('groupname')}}:</p>
              </v-col>
              <v-col lg="10">
                <v-text-field 
                  placeholder="Group Name"
                  dense 
                  solo 
                  v-model="addGroupName"></v-text-field>
              </v-col>
              <!-- Group Member -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px', 'font-size': '16px' } : { 'margin-top': '10px', 'font-size': '16px' } ]">{{$t('groupMember')}}:</p>
              </v-col>
              <v-col lg="8" cols="8">
                <v-text-field 
                  placeholder="Group Member" 
                  dense
                  solo 
                  disabled 
                  style="width: 98%"
                  v-model="addGroupEmployee"></v-text-field>
              </v-col>
              <v-col lg="2" cols="4">
                <v-btn 
                  style="color: #fff; width: 100%" 
                  color="#126496" 
                  @click="clickaddGroupEmployee">{{$t('add')}}</v-btn> 
              </v-col>
              <!-- Role -->
              <v-col lg="2" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px', 'font-size': '16px' } : { 'margin-top': '10px', 'font-size': '16px' } ]">{{$t('role')}}:</p>
              </v-col>
              <v-col lg="8" cols="8">
                <v-text-field 
                  dense 
                  placeholder="Role" 
                  disabled 
                  solo 
                  style="width: 98%"
                  v-model="addGroupRole"></v-text-field>
              </v-col>
              <v-col lg="2" cols="4">
                <v-btn 
                  style="color: #fff; width: 100%" 
                  color="#126496" 
                  @click="clickaddGroupRole">{{$t('assign')}}</v-btn>
              </v-col>
              <!-- Advanced Button -->
              <v-col cols="12" lg="12" sm="12">
                <p 
                  style="cursor: pointer; font-size: 16px" 
                  @click="advanceGroup == true ? advanceGroup = false : advanceGroup = true">
                  {{$t('advance')}}
                  <v-icon small v-if="!advanceGroup">mdi-arrow-down</v-icon>
                  <v-icon small v-if="advanceGroup">mdi-arrow-up</v-icon>
                </p>
              </v-col>
              <!-- Permission -->
              <v-row no-gutters v-show="advanceGroup == true">
                <v-col lg="2" cols="12">
                  <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px', 'font-size': '16px' } : { 'margin-top': '10px', 'font-size': '16px' } ]">
                    {{$t('permission')}}:</p>
                </v-col>
                <v-col lg="8" cols="8">
                  <v-text-field 
                    placeholder="Group Name" 
                    dense 
                    disabled 
                    solo 
                    style="width: 98%"
                    v-model="addGroupPermission"></v-text-field>
                </v-col>
                <v-col lg="2" cols="4">
                  <v-btn 
                    color="primary"
                    style="width: 100%"
                    @click="clickPermission">{{$t('assign')}}</v-btn>
                </v-col>
              </v-row>
            </v-row>
            <hr>
          </v-container>
        </v-card-text>                  
        <v-card-actions style="margin-top: -25px">
          <v-container style="padding-right: 30px; padding-left: 30px">
            <div style="display: flex; justify-content: flex-end" class="space-between">
              <v-btn class="mr-2 min-width" min-width="120" @click="closeaddGroupDialog" text>{{$t('cancel')}}</v-btn>
              <v-btn color="#126496" class="min-width" style="color: #fff" min-width="120" @click="AddGroup()">{{$t('save')}}</v-btn>
            </div>  
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Old Add Group Dialog -->
    <!-- <v-dialog v-model="addGroupDialog" persistent width="750">
      <v-card>
        <v-card-title>
          <p>Add Group</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="closeaddGroupDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-row class="ml-5 mr-2">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Group Name:</p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field placeholder="Group Name" solo v-model="addGroupName"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Group Member :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="Group Member" solo disabled v-model="addGroupEmployee"></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn style="margin-top: 5px;color: white" color="#126496" @click="clickaddGroupEmployee">Add</v-btn>          
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Role :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="Role" disabled solo v-model="addGroupRole"></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn style="margin-top: 5px;color: white" color="#126496" @click="clickaddGroupRole">Assign</v-btn>          
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="8" col="8">
            <p
              class="mytitle"
              style="cursor:pointer;"
              @click="advanceGroup == true? advanceGroup = false: advanceGroup = true"
            >
              Advance
              <v-icon small v-if="!advanceGroup">mdi-arrow-down</v-icon>
              <v-icon small v-if="advanceGroup">mdi-arrow-up</v-icon>
            </p>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3" v-show="advanceGroup == true">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Permission :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="Group Name" disabled solo v-model="addGroupPermission"></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-btn style="margin-top: 35px;" color="primary" @click="clickPermission">Assign</v-btn>
          </v-col>
        </v-row>                   
        <hr class="ml-5 mr-4" />
        <br>
        <v-card-actions style="margin-right: 3%;margin-left:3%;" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="closeaddEmpDialog"
            text
          >Cancel</v-btn>
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="AddGroup()"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    
    <!-- New Edit Group Dialog -->
    <v-dialog v-model="editGroupDialog" persistent max-width="750">
      <v-card>
        <v-card-title>
          <p>{{$t('editUserGroup')}}</p>
          <v-spacer></v-spacer>
          <v-btn 
            style="margin-top: -10px" 
            @click="closeeditGroupDialog"
            icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <!-- Group Name -->
              <v-col lg="2" sm="12" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px', 'font-size': '16px' } : { 'margin-top': '10px', 'font-size': '16px' } ]">{{$t('groupname')}}:</p>
              </v-col>
              <v-col lg="10" sm="12" cols="12">
                <v-text-field 
                  dense 
                  placeholder="Group Name" 
                  solo 
                  v-model="editGroupName"></v-text-field>
              </v-col>
              <!-- Group Member -->
              <v-col lg="2" sm="12" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px', 'font-size': '16px' } : { 'margin-top': '10px', 'font-size': '16px' } ]">{{$t('groupMember')}}:</p>
              </v-col>
              <v-col lg="8" cols="8">
                <v-text-field 
                  dense 
                  placeholder="Group Member" 
                  solo 
                  disabled 
                  style="width: 98%"
                  v-model="editGroupEmployee"></v-text-field>
              </v-col> 
              <v-col lg="2" cols="4">
                <v-btn 
                  style="color: white; width: 100%" 
                  color="#126496" 
                  @click="clickaddGroupEmployee">{{$t('add')}}</v-btn>          
              </v-col>
              <!-- Role -->
              <v-col lg="2" sm="12" cols="12">
                <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px', 'font-size': '16px' } : { 'margin-top': '10px', 'font-size': '16px' } ]">{{$t('role')}}:</p>
              </v-col>
              <v-col lg="8" cols="8">
                <v-text-field 
                  dense 
                  placeholder="Role" 
                  disabled 
                  solo 
                  style="width: 98%"
                  v-model="editGroupRole"></v-text-field>
              </v-col>
              <v-col lg="2" cols="4">
                <v-btn 
                  style="color: #fff; width: 100%" 
                  color="#126496" 
                  @click="clickaddGroupRole">Ass{{$t('assign')}}ign</v-btn>          
              </v-col>
              <!-- Advanced Button -->
              <v-col cols="12" lg="12" sm="12">
                <p 
                  style="cursor: pointer; font-size: 16px" 
                  @click="advanceGroup == true ? advanceGroup = false : advanceGroup = true">
                  {{$t('advance')}}
                  <v-icon small v-if="!advanceGroup">mdi-arrow-down</v-icon>
                  <v-icon small v-if="advanceGroup">mdi-arrow-up</v-icon>
                </p>
              </v-col>
              <!-- Permission -->
              <v-row no-gutters v-show="advanceGroup == true">
                <v-col lg="2" cols="12">
                  <p :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '0px', 'font-size': '16px' } : { 'margin-top': '10px', 'font-size': '16px' } ]">
                    {{$t('permission')}}:</p>
                </v-col>
                <v-col lg="8" cols="8">
                  <v-text-field 
                    :placeholder="$t('groupname')" 
                    dense 
                    disabled 
                    solo 
                    style="width: 98%"
                    v-model="editGroupPermission"></v-text-field>
                </v-col>
                <v-col lg="2" cols="4">
                  <v-btn 
                    color="primary"
                    style="width: 100%"
                    @click="clickPermission">{{$t('assign')}}</v-btn>
                </v-col>
              </v-row>
            </v-row>
            <hr>
          </v-container>
        </v-card-text>                  
        <v-card-actions style="margin-top: -25px">
          <v-container style="padding-right: 30px; padding-left: 30px">
            <div style="display: flex; justify-content: flex-end" class="space-between">
              <v-btn class="mr-2 min-width" min-width="120" @click="closeeditGroupDialog" text>{{$t('cancel')}}</v-btn>
              <v-btn color="#126496" class="min-width" style="color: #fff" min-width="120" @click="EditGroupRole()">{{$t('save')}}</v-btn>
            </div>  
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Old Edit Group Dialog -->
    <!-- <v-dialog v-model="editGroupDialog" persistent width="750">
      <v-card>
        <v-card-title>
          <p>Edit User Group</p>
          <v-spacer></v-spacer>
          <v-btn style="margin-top: -10px" @click="closeeditGroupDialog" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-row class="ml-5 mr-2">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Group Name:</p>
          </v-col>
          <v-col cols="12" md="10">
            <v-text-field placeholder="Group Name" solo v-model="editGroupName"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Group Member :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="Group Member" solo disabled v-model="editGroupEmployee"></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn style="margin-top: 5px;color: white" color="#126496" @click="clickaddGroupEmployee">Add</v-btn>          
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2" style="margin-top: -30px;">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Role :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="Role" disabled solo v-model="editGroupRole"></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn style="margin-top: 5px;color: white" color="#126496" @click="clickaddGroupRole">Assign</v-btn>          
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3">
          <v-col md="8" col="8">
            <p
              class="mytitle"
              style="cursor:pointer;"
              @click="advanceGroup == true? advanceGroup = false: advanceGroup = true"
            >
              Advance
              <v-icon small v-if="!advanceGroup">mdi-arrow-down</v-icon>
              <v-icon small v-if="advanceGroup">mdi-arrow-up</v-icon>
            </p>
          </v-col>
        </v-row>
        <v-row class="ml-5 mr-2 mt-3" v-show="advanceGroup == true">
          <v-col cols="12" md="2">
            <p style="margin-top: 10px;">Permission :</p>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field placeholder="Group Name" disabled solo v-model="editGroupPermission"></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-btn style="margin-top: 35px;" color="primary" @click="clickPermission">Assign</v-btn>
          </v-col>
        </v-row>                   
        <hr class="ml-5 mr-4" />
        <br>
        <v-card-actions style="margin-right: 3%;margin-left:3%;" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="closeeditGroupDialog"
            text
          >Cancel</v-btn>
          <v-btn
            color="#126496"
            class="mr-2 white--text text-capitalize"
            width="120"
            max-height="35"
            @click="EditGroupRole()"
          >Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- Delete Group Dialog -->
    <v-dialog v-model="deleteGroupDialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">{{$t('deleteGroup')}}</p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">{{$t('areYouSureWantToDelete?')}}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 3%" class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize"
            width="120"
            @click="deleteGroupDialog = false"
            text
          >{{$t('cancel')}}</v-btn>
          <v-btn
            color="#FF2727"
            class="mr-2 white--text text-capitalize"
            width="120"
            @click="DeleteGroup()"
          >{{$t('confirm')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "../store";
// import LogTrace from "@/api/Function.js";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";
import * as XLSX from 'xlsx'

export default {
  components: {
    Alert,
    Loading,
  },
  props: {
    type: {
      type: String,
      default: "text"
    },
    size: {
      type: String,
      default: "10"
    },
    characters: {
      type: String,
      default: "a-z,A-Z,0-9,#"
    },
    placeholder: {
      type: String,
      default: "Password"
    },
    auto: [String, Boolean],
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // templateExcel: require("@/assets/Template_For_Import_User.xlsx"),
      ActiveTab: 0,
      dynamicName: '',
      show1: false,
      permissionid: store.state.permissionid,
      flag: {
        user_view: false,
        group_view: false,
        permission_view: false,
        role_view: false,
        user_add: false,
        user_edit: false,
        user_delete: false,
        group_add: false,
        group_edit: false,
        group_delete: false,
        role_add: false,
        role_edit: false,
        role_delete: false
      },
      tempCompanyID: "",
      tempEmpID: "",
      permission_status: false,
      RolePage: false,
      EmpPage: false,

      typepassword: "password",
      AddflagPassword: true,

      //Employee tab
      empList: [],
      allEmpList : [],
      addEmpDialog: false,
      editEmpDialog: false,
      deleteEmpDialog: false,
      emp_stauts: false,
      addEmpRole: "",
      addEmpRoleList: [],
      addEmpGroup: "",
      addEmpGroupList: [],
      addEmpPermission: "",
      addEmpPermissionList: [],
      editEmpRole: "",
      editEmpRoleList: [],
      editEmpGroup: "",
      editEmpGroupList: [],
      editEmpPermission: "",
      editEmpPermissionList: [],
      addUsername: "",
      editUsername: "",
      addFirstname: "",
      editFirstname: "",
      addLastname: "",
      editLastName: "",
      addPassword: "",
      editPassword: "",
      addEmail: "",
      editEmail: "",
      addPhonenum: "",
      editPhonenum: "",
      oldPsw: "",

      //Group tab
      addGroupName: "",
      editGroupName: "",
      group_status: false,

      selected1: [],
      tempGroupID: "",
      GroupPage: false,
      groupList: [],
      addGroupEmployee: "",
      addGroupEmployeeList: [],
      addGroupRole: "",
      addGroupRoleList: [],
      addGroupPermission: "",
      addGroupPermissionList: [],
      editGroupEmployee: "",
      editGroupEmployeeList: [],
      editGroupRole: "",
      editGroupRoleList: [],
      editGroupPermission: "",
      eidtGroupPermissionList: [],
      groupsearch: "",
      addGroupDialog: false,
      editGroupDialog: false,
      deleteGroupDialog: false,
      advanceGroup: false,
      groupheaders: [
        {
          text: this.$t('groupname'),
          align: "left",
          sortable: false,
          value: "groupName"
        },
        {
          text: this.$t('action'),
          value: "action",
          align: "right",
          sortable: false
        }
      ],

      checkpassword: true,

      //Permission tab
      permissionList: [],
      eidtpermissionList: [],
      tempPermission: [],
      addPermissonList: [],
      editPermissionList: [],
      permissionsearch: "",
      PermissionPage: false,
      permissionheaders: [
        {
          text: this.$t('permissionID'),
          align: "left",
          sortable: true,
          value: "permissionID"
        },
        {
          text: this.$t('permissionname'),
          align: "left",
          value: "permissionName",
          sortable: true
        }
      ],

      //Role tab
      rolelist: [],
      role_status: false,
      rolesearch: "",
      addRoleDialog: false,
      editRoleDialog: false,
      deleteRoleDialog: false,
      roletext: "",
      addRoleName: "",
      addRoleCode: "",
      addRolePermission: "",
      editRoleName: "",
      editRoleCode: "",
      editRolePermission: "",
      singleSelect: false,
      selected: [],
      roleID: "",
      editroleID: "",
      roleHeadersRadio: [
        {
          text: "",
          align: "center",
          sortable: false,
          value: "roleRadio"
        },
        {
          text: this.$t('roleID'),
          align: "left",
          sortable: false,
          value: "roleCode"
        },
        {
          text: this.$t('roleName'),
          value: "roleName",
          align: "left",
          sortable: false
        }
      ],
      roleheaders: [
        {
          text: this.$t('roleID'),
          align: "left",
          sortable: false,
          value: "roleCode"
        },
        {
          text: this.$t('roleName'),
          value: "roleName",
          align: "left",
          sortable: false
        }
      ],

      search: "",
      deletesearch: "",
      //userid: store.state.username,
      flagLoadingSend: false,
      detaildialog: false,
      adddialog: false,
      editdialog: false,
      status: false,
      companyName: null,
      companyCode: null,
      tele_Number: null,
      activeFlag: null,
      company_ID: 0,
      items: [10, 20, 30],
      url: enurl.apiUrl,

      //add
      AddcompanyName: null,
      AddcompanyCode: null,
      AddtelephoneNo: null,
      Addemail: null,
      Addcountry: null,
      Addcity: null,
      Addaddress: null,
      AdduserID: 0,
      Addpassword: null,
      Addstatus: false,

      //Detail
      DetailcompanyName: null,
      DetailcompanyCode: null,
      DetailtelephoneNo: null,
      Detailemail: null,
      Detailcountry: null,
      Detailcity: null,
      Detailaddress: null,
      DetailuserID: 0,
      Detailpassword: null,
      Detailstatus: false,

      //Update
      UpdatecompanyName: null,
      UpdatecompanyCode: null,
      UpdatetelephoneNo: null,
      Updateemail: null,
      Updatecountry: null,
      Updatecity: null,
      Updateaddress: null,
      UpdateuserID: 0,
      Updatepassword: null,
      Updatestatus: false,

      //delete
      companyID: 0,

      images: {
        edit: require("@/assets/edit.svg"),
        delete: require("@/assets/delete1.png")
      },
      inactivecolor: "text-danger",

      dialog: false,

      //pagination User
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      pageUser: 1,
      pageCountUser: 0,
      itemsPerPageUser: 10,
      searchUser: "",

      //pagination Group
      pageGroup: 1,
      pageCountGroup: 0,
      itemsPerPageGroup: 10,

      //pagination Permission
      pagePermission: 1,
      pageCountPermission: 0,
      itemsPerPagePermission: 10,

      //pagination Role
      pageRole: 1,
      pageCountRole: 0,
      itemsPerPageRole: 10,

      CompanyList: [],
      Deletecompanylist: [],

      deletecompany: {
        companyname: null,
        companycode: null,
        teleph: null,
        email: null,
        country: null,
        city: null,
        address: null,
        expiredate: null,
        userid: null
      },
      viewicon: require("@/assets/edit1.png"),

      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",

      ViewDataRolePermissionList: [],
      ViewDataRolePermissionStatus: false,
      ViewDataRolePermissionSearch: "",
      pageViewDataRolePermission: 1,
      pageCountViewDataRolePermission: 0,
      itemsPerPageViewDataRolePermission: 10,
      ResourceName: "",
      ResourceId: 0,
      paginationCount: [
        { title: 10 },
        { title: 20 },
        { title: 30 },
        { title: 40 }
      ],
      RoleStatusTrue: false,
      RoleIDUser: "",
      FlagAdmin: false,
      editFlagAdmin: false,
      ActiveStatus: false,
      editActiveStatus: false,
      LimitUser: "",
      LimitUserUse: "",
      FreeUser:0,
      importFlag: true,
      CheckUnlimitUser: false,
      CheckUnlimitSignature: false,
      OwnAdmin: false,
      EmailAdmin: "",
      menu: false,
      FlagSearch: false,
      Filter: {
        role: [],
        signerStatus: null,
        adminStatus: null,
        EndDate: null,
        StartExpiredDate: null,
        EndExpiredDate: null
      },
      lockStatus: false,
      //searchText : '',
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('employeename'),
          value: "employeeName",
          align: "left",
          sortable: false
        },
        // {
        //   text: "Group",
        //   value: "groupName",
        //   align: "left",
        //   sortable: false
        // },
        {
          text: this.$t('role'),
          value: "roleName",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('statusSigner'),
          value: "activeStatus",
          align: "left",
          sortable: false
        },
        {
          text: this.$t('action'),
          value: "action",
          align: "left",
          sortable: false
        }
      ];
    },

    employeeheaders() {
      return [
        {
          text: this.$t('username'),
          // value: "employeeName",
          align: "left",
          sortable: true
        },
        {
          text: this.$t('fullname'),
          align: "left",
          sortable: true
        }
      ];
    },

    ViewRolePermissionHeaders() {
      return [
        {
          text: this.$t('resource'),
          value: "resource",
          sortable: false,
          align: "left",
        },
        {
          text: this.$t('accessLevel'),
          value: "accessLevel",
          sortable: false,
          align: "left",
        }
      ];
    },

    //permission headers
    // permissionheaders() {
    //   return [
    //     {
    //       text: "Permission ID",
    //       align: "left",
    //       sortable: true,
    //       value: "permissionID",
    //     },
    //     {
    //       text: "Permission Name",
    //       align: "left",
    //       value: "permissionName",
    //       sortable: true,
    //     },
    //   ];
    // },

    //role headers
    roleheaders1() {
      return [
        {
          text: this.$t("roleID"),
          align: "left",
          sortable: true,
          value: "roleCode"
        },
        {
          text: this.$t('roleName'),
          value: "roleName",
          align: "left",
          sortable: true
        }
      ];
    },
    removeheaders() {
      return [
        {
          text: this.$t('companyCode'),
          align: "left",
          sortable: true,
          value: "companyCode"
        },
        {
          text: this.$t('companyName'),
          value: "companyName",
          align: "left",
          sortable: true
        },

        {
          text: this.$t('email'),
          align: "left",
          value: "email",
          sortable: true,
          filterable: false
        },
        {
          text: this.$t('telephoneNumber'),
          align: "left",
          value: "address",
          sortable: true,
          filterable: false
        },
        {
          text: this.$t('status'),
          align: "left",

          sortable: true
        },
        {
          text: this.$t('active'),
          value: "removeactions",
          sortable: true,
          align: "left"
        }
      ];
    },
  },
  async mounted() {
    let self = this;
    //this.CheckPermission();
    // this.tempCompanyID = localStorage.getItem("companyID");
    // this.GetPermissionList();
    self.UpdateDataProductPlan();
    await self.GetDataProductPlan();
    await self.GetEmployeeList();
    self.GetDataFormatEmail();
    self.GetRoleList();
    await self.GetAllEmployeeList();
    // this.GetRoleList();
    // this.GetGroupList();
  },
  methods: {
    async GetAllEmployeeList(){
      let self = this;
      try {
        self.flagLoadingSend = true;
        let temp = {
        // CompanyID: self.tempCompanyID,
          Page: 1,
          PerPage: self.itemsPerPageUser * self.pageCountUser,
          Search: self.searchUser == "" ? null : self.searchUser
        };
        await axios
        .post(`${self.url}EmployeeExt/GetSearchDataEmployeeExt`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.allEmpList = response.data.data.result.listData;
            //console.log("func",self.allEmpList)
            self.flagLoadingSend = false;
          }
           else {
            self.allEmpList = [];
            self.flagLoadingSend = false;
          }
          self.flagLoadingSend = false;
        });
      } catch (error) {
        self.allEmpList = [];
        self.flagLoadingSend = false;
      }
    },
    importUser(e){
      let self = this;
      self.importFlag = true;
      let file = e ?  e : this.$refs.hiddenFile.files[0];
      const fileExtension = file.name.split('.').pop();
      if (fileExtension !== 'xlsx') {
            self.typeAlertSend = "warning"; 
            self.mesAlertSend = this.$t("importedfileformatisincorrect");
            self.flagAlertSend = true;
            self.flagLoadingSend = false;  
      } else {
        if(file)
        {
        var reader = new FileReader();
        reader.onload = async (e) => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: 'array' });
          var sheetName = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[sheetName];
          
          // Convert worksheet data to JSON format
          var result = XLSX.utils.sheet_to_json(worksheet);
          var extractedHeaders = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
          var expectedHeaders = ["User Name:", "First Name:", "Last Name:", "Phone Number:", "Email:" , "Password:" , "Role: Signer/Operator" , "Activate for Signer only" , "Role: Admin"];
          var isFormatError = false;
          if (extractedHeaders.length !== expectedHeaders.length) 
          {
              isFormatError = true;
          }else{
              for (var i = 0; i < extractedHeaders.length; i++) {
                if (extractedHeaders[i].trim() !== expectedHeaders[i].trim()) {
                  isFormatError = true;
                }

            }
          }
          if(isFormatError)
          {
            self.typeAlertSend = "warning"; 
            self.mesAlertSend = this.$t("importedfileformatisincorrect");
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          }
          else{
                var active_signer_list = [];
                let r = 0;
                result.forEach(item => {
                  r = r + 1;
                  if(r > 1)
                  {
                    if(item["Role: Signer/Operator"].trim() == "Signer" && item["Activate for Signer only"] == true)
                    {
                      active_signer_list.push(item);
                    }
                  }
                });
                await self.GetAllEmployeeList();
                let tempdataList = [];
                let row = 0;
                //console.log(result.length)
                if(result.length < 2)
                {
                    self.typeAlertSend = "warning"; 
                    self.mesAlertSend = this.$t("filldataintable");
                    self.flagAlertSend = true;
                    self.flagLoadingSend = false;
                }
                else{
                  
                  try{
                    // user has limit user total
                    if(self.LimitUser != 0) 
                    {
                      if(active_signer_list.length > self.FreeUser)
                      {
                        self.importFlag = false;
                        self.typeAlertSend = "warning";
                        self.mesAlertSend = this.$t("planisinsufficient");
                        self.flagAlertSend = true;
                        self.flagLoadingSend = false;
                      }
                    }
                    //
                    if(self.importFlag == true)
                    {

                      result.forEach(item => {
                        row = row + 1;
                        if(row > 1)
                        {
                          let phone = item["Phone Number:\r\n"].toString();
                          //console.log(item["Email:\r\n"].split('@')[1].split('.')[0], self.EmailAdmin)
                          if(item["User Name:\r\n"] == null || item["First Name:\r\n"] == null || 
                            item["Last Name:\r\n"] == null || item["Email:\r\n"] == null || item["Password:\r\n"] == null)
                          {
                            self.typeAlertSend = "warning";
                            self.mesAlertSend = this.$t("fillemptyinrowno") + row;
                            self.flagAlertSend = true;
                          }
                          else if(item["Role: Signer/Operator"] == null || (item["Role: Signer/Operator"].trim() != "Signer" && item["Role: Signer/Operator"].trim() != "Operator"))
                          {
                            self.typeAlertSend = "warning";
                            self.mesAlertSend = this.$t("fillrole(Signer/Operator)inrowno") + row;
                            self.flagAlertSend = true;
                          }
                          else if (self.validateEmail(item["Email:\r\n"].trim()) == "invalid") {
                            self.typeAlertSend = "warning";
                            self.mesAlertSend = this.$t("fillcorrectemailformatinrowno") + row;
                            self.flagAlertSend = true; 
                          } 
                          else if (item["Email:\r\n"] != "" && item["Email:\r\n"].split('@')[1].split('.')[0] != self.EmailAdmin) {       
                            self.typeAlertSend = "warning";
                            self.mesAlertSend = "กรุณากรอก format Email ให้เหมือนกับ Admin in row no: "+ row;
                            self.flagAlertSend = true;      
                          }
                          else if (item["First Name:\r\n"].toLowerCase() == "admin") {
                            self.typeAlertSend = "warning";
                            self.mesAlertSend = this.$t("donotusethewordadmininrowno") + row;
                            self.flagAlertSend = true;
                          }  
                          else if (phone.length < 9 || phone.length > 11) {
                            self.typeAlertSend = "warning";
                            self.mesAlertSend = this.$t("fillcorrectphonenumber(9-11 digits)inrowno") + row;
                            self.flagAlertSend = true; 
                          }
                          else{
                            if(tempdataList != [])
                            {
                              tempdataList.forEach( t => {
                                if(t.username == item["User Name:\r\n"].trim())
                                { 
                                  //console.log(t.username,"duplicate")
                                  self.typeAlertSend = "warning";
                                  self.mesAlertSend = this.$t("usernameDuplicateinimporttablerowno") + row;
                                  self.flagAlertSend = true;
                                  self.flagLoadingSend = false;
                                }
                                else if(t.email == item["Email:\r\n"].trim())
                                {
                                  self.typeAlertSend = "warning";
                                  self.mesAlertSend = this.$t("emailDuplicateinimporttablerowno") + row;
                                  self.flagAlertSend = true;
                                  self.flagLoadingSend = false;
                                }
                              });
                            }
                            self.allEmpList.forEach( x => {
                              if(x.username == item["User Name:\r\n"].trim())
                              { 
                                self.typeAlertSend = "warning";
                                self.mesAlertSend = this.$t("usernameDuplicateinrowno") + row;
                                self.flagAlertSend = true;
                                self.flagLoadingSend = false;
                              }
                              else if(x.email == item["Email:\r\n"].trim())
                              {
                                self.typeAlertSend = "warning";
                                self.mesAlertSend = this.$t("emailDuplicateinrowno") + row;
                                self.flagAlertSend = true;
                                self.flagLoadingSend = false;
                              }
                            });
                          }

                          if(self.flagAlertSend == false)
                          {
                            var RID = item["Role: Signer/Operator"].trim() == "Signer"? '2':'1';
                            self.addEmpRoleList = [];
                            self.addEmpRoleList.push({
                            employeeID: 0,
                            roleID: RID,
                            createBy: "string",
                            createDate: new Date().toISOString(),
                            activeFlag: true
                            });
                            self.addEmpRole = item["Role: Signer/Operator"].trim();
                            self.ActiveStatus = item["Activate for Signer only"];
                            var temp = {
                              userID: 0,
                              memberID: 0,
                              username: item["User Name:\r\n"],
                              firstName: item["First Name:\r\n"],
                              lastName: item["Last Name:\r\n"],
                              email: item["Email:\r\n"].trim(),
                              password: item["Password:\r\n"].toString(),
                              userType: "string",
                              roleID: 0,
                              mobile: phone,
                              employeeName: "string",
                              positionID: 0,
                              // companyID: self.tempCompanyID,
                              employeeID: 0,
                              employeeGroup: self.addEmpGroupList,
                              employeeRole: self.addEmpRoleList,
                              employeePermission: self.addEmpPermissionList,
                              flagAdmin: item["Role: Admin"],
                              activeStatus: self.addEmpRole == 'Signer' ? self.ActiveStatus : false,
                            };
                              //console.log("temp",temp)
                              tempdataList.push(temp);
                          }
                          else{
                            tempdataList = null;
                          }
                        }
                        
                        //console.log("templ",tempdataList);
                      
                      });
                    }
                    if(tempdataList.length > 0)
                    {
                      await axios
                        .post(`${self.url}EmployeeExt/AddEmployeeGroupExt`, {"employeeGroupList":tempdataList})
                        .then(async function(response) {
                            if (response.data.status == 0) {
                              if(response.data.data == "Success"){
                                self.typeAlertSend = "success"; 
                                self.mesAlertSend = response.data.message;
                                self.flagAlertSend = true;
                                self.GetEmployeeList();
                              }
                              else if(response.data.data == "Plan Expired !"){
                                self.typeAlertSend = "warning";
                                self.mesAlertSend = response.data.data;
                                self.flagAlertSend = true;
                                self.addEmpDialog = true;
                                self.flagLoadingSend = false;
                              }
                              else if(response.data.data == "ไม่พบ Plan ที่ท่านเลือก กรุณาติดต่อ Sale"){
                                self.typeAlertSend = "warning";
                                self.mesAlertSend = response.data.data;
                                self.flagAlertSend = true;
                                self.addEmpDialog = true;
                                self.flagLoadingSend = false;
                              }
                              else if(response.data.data == "จำนวน User ที่ Activate เกินจำนวน Plan"){
                                self.typeAlertSend = "warning";
                                self.mesAlertSend = response.data.data;
                                self.flagAlertSend = true;
                                self.addEmpDialog = true;
                                self.flagLoadingSend = false;
                              }
                              else if(response.data.data == "ไม่พบข้อมูล Member"){
                                self.typeAlertSend = "warning";
                                self.mesAlertSend = response.data.data;
                                self.flagAlertSend = true;
                                self.addEmpDialog = true;
                                self.flagLoadingSend = false;
                              }
                              else if(response.data.data == "ไม่พบข้อมูล User"){
                                self.typeAlertSend = "warning";
                                self.mesAlertSend = response.data.data;
                                self.flagAlertSend = true;
                                self.addEmpDialog = true;
                                self.flagLoadingSend = false;
                              }
                              else{
                                self.typeAlertSend = "warning"; //success warning error
                                self.mesAlertSend = response.data.message;
                                self.flagAlertSend = true;
                                self.addEmpDialog = true;
                                self.flagLoadingSend = false;
                              } 
                            }
                        })
                        .catch(function(error) {
                          self.typeAlertSend = "error"; 
                          self.mesAlertSend = error;
                          self.flagAlertSend = true;
                          self.flagLoadingSend = false;
                        });
                    }
                  }
                  catch(error)
                  {
                    alert(error)
                    self.flagLoadingSend  = false;
                  }
                  
                }
         }
        };
        this.$refs.hiddenFile.value=null;
        reader.readAsArrayBuffer(file);
      }
      else{
        self.typeAlertSend = "warning"; 
        self.mesAlertSend = this.$t("chooseafile");
        self.flagAlertSend = true;
        self.flagLoadingSend = false;
      }
             
      }
      
    },
     downloadURI(uri, name) {
      var link = document.createElement("a");
      // link.download = name;
      link.setAttribute("download", name);
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    },
    base64ToBlob( base64, type = "application/octet-stream" ) {
      const binStr = atob( base64 );
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[ i ] = binStr.charCodeAt( i );
      }
      return new Blob( [ arr ], { type: type } );
    },
    async downloadTemplate(){
      let self = this;
      await axios
            .get(`${self.url}EmployeeExt/GetTemplateForImportUser`)
            .then((response) => {
              if (response.data.status == 0) {
              if (response.data.data != "") {
                const blobTemplate = self.base64ToBlob(response.data.data.base64String, 'application/pdf');
                const linkSource = URL.createObjectURL(blobTemplate);
                self.downloadURI(linkSource, response.data.data.fileName);
                self.flagLoadingSend = false;
              }
            }
            else{
              self.flagLoadingSend = false;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
          });
    },
    changeName(item) {
      this.dynamicName = item
    },
    ClearSearchFilter(){
      let self = this;
      self.Filter.role = [];
      self.Filter.signerStatus = null;
      self.Filter.adminStatus = null;
    },
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    SearchFilter() {
      try {
        let self = this;  
        self.flagLoadingSend = true;
        let temp = {
          Page: 1,
          PerPage: self.itemsPerPage,
          Search: self.searchUser == "" ? null : self.searchUser,
          Filter: self.FlagSearch == true ? self.Filter : {role: null, signerStatus: null,}
        };
        axios
          .post(`${self.url}EmployeeExt/GetSearchDataEmployeeExt`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.empList = response.data.data.result.listData;
              self.pageCountUser = response.data.data.result.count;
              self.pageUser = 1;
              self.flagLoadingSend = false;
            } else {
              self.empList = [];
              self.flagLoadingSend = false;
            }
            self.flagLoadingSend = false;
          });
      } catch (error) {
        self.empList = [];
        self.flagLoadingSend = false;
      }
    },

    async GetDataProductPlan(){
      let self = this;
      await axios
      .post(`${self.url}ProductPlan/GetProductsByCompany`)
      .then(function (response) {
        if (response.data.status == 0) {
          if(response.data.data.listData.length > 0){
            self.LimitUser = response.data.data.listData[0].limitUser;
            // self.LimitUserUse = response.data.data.listData[0].limitUserUse;
            self.CheckUnlimitUser = response.data.data.listData[0].checkUnlimitUser;
            self.CheckUnlimitSignature = response.data.data.listData[0].checkUnlimitSignature;
          }        
        }      
      })
      .catch(function (error) {
        alert(error);
        self.flagLoadingSend = false
      });
    },
    async UpdateDataProductPlan(){
      let self = this;
      await axios
      .post(`${self.url}ProductPlan/UpdateTProduct_CompanyByCompany`)
      .then(function (response) {
        if (response.data.status == 0) {
          console.log(response.data.data)
        }
      })
      .catch(function (error) {
        alert(error);
        self.flagLoadingSend = false
      });
    },

    async GetDataFormatEmail(){
      let self = this;
      await axios
      .post(`${self.url}ProductPlan/GetDataFormatEmail`)
      .then(function (response) {
        if (response.data.status == 0) {
          self.EmailAdmin = response.data.data;       
          //console.log(self.EmailAdmin)
        }
      })
      .catch(function (error) {
        alert(error);
        self.flagLoadingSend = false
      });
    },

    CheckStatusTrue(){
      // console.log(this.rolelist);
      let self = this;
      var findData = self.rolelist.filter(x => x.select == true);
      if(findData.length > 0){
        self.RoleStatusTrue = true;
      }
    },

    SearchViewDataRolePermission(value){
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: 1,
        PerPage: self.itemsPerPageViewDataRolePermission,
        RoleCode: self.ResourceName,
        RoleID: self.ResourceId,
        Search: value
      };
      axios
        .post(`${self.url}Role/SearchDataRolePermission`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.ViewDataRolePermissionList = response.data.data.result.listData;
            self.pageCountViewDataRolePermission = response.data.data.result.count;
            self.page = 1;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.pageCountViewDataRolePermission = 0;
            self.ViewDataRolePermissionList = [];
          }
        })
        .catch(function(error) {
          self.flagLoadingSend = false;
          self.mesAlert = error;
          self.typeAlert = "error";
          self.btnColorAlert = "red accent-4";
          self.flagAlert = true;  
        });
    },
    ChangePerPageViewDataRolePermission(value){
      let self = this;
      self.flagLoadingSend = true;
      self.itemsPerPageViewDataRolePermission = value;
      let temp = {
        Page: 1,
        PerPage: value,
        RoleCode: self.ResourceName,
        RoleID: self.ResourceId,
        Search: self.ViewDataRolePermissionSearch
      };
      axios
        .post(`${self.url}Role/SearchDataRolePermission`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.ViewDataRolePermissionList = response.data.data.result.listData;
            self.pageCountViewDataRolePermission = response.data.data.result.count;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.pageCountViewDataRolePermission = 0;
            self.ViewDataRolePermissionList = [];
          }
        })
        .catch(function(error) {
          self.flagLoadingSend = false;
          self.mesAlert = error;
          self.typeAlert = "error";
          self.btnColorAlert = "red accent-4";
          self.flagAlert = true;  
        });
    },
    ChangePageViewDataRolePermission(value){
      let self = this;
      self.flagLoadingSend = true;
      let temp = {
        Page: value,
        PerPage: self.itemsPerPageViewDataRolePermission,
        RoleCode: self.ResourceName,
        RoleID: self.ResourceId,
        Search: self.ViewDataRolePermissionSearch
      };
      axios
        .post(`${self.url}Role/SearchDataRolePermission`, temp)
        .then(function(response) {
          if (response.data.status == 0) {
            self.ViewDataRolePermissionList = response.data.data.result.listData;
            self.pageCountViewDataRolePermission = response.data.data.result.count;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            //self.pageCountViewDataRolePermission = 0;
            self.ViewDataRolePermissionList = [];
          }
        })
        .catch(function(error) {
          self.flagLoadingSend = false;
          self.mesAlert = error;
          self.typeAlert = "error";
          self.btnColorAlert = "red accent-4";
          self.flagAlert = true;  
        });
    },

    ViewDataRole(value) {
      let self = this;
      self.ViewDataRolePermissionList = [];
      self.ResourceName = value.roleCode;
      self.ResourceId = value.roleID;
      self.ViewDataRolePermissionStatus = true;
      // value.rolePermission.forEach(element => {
      //   self.ViewDataRolePermissionList.push(element);
      // });
      // var temp = [];
      // console.log(value)
      // for(let i = 0; i < value.rolePermission.length; i++){
      //   let temp = {
      //     resourceName: self.ResourceName,
      //     permissionName: value.rolePermission[i].permissionName
      //   };
      //   self.ViewDataRolePermissionList.push(temp);
      // }
      // console.log(self.ViewDataRolePermissionList.length)
      // var length = self.ViewDataRolePermissionList.length;
      // var count = 0;
      // do{
      //   count++;
      //   length = length - 10;
      // }while(length > 0)
      // console.log(count)
      // self.pageCountViewDataRolePermission = count;
      self.SearchViewDataRolePermission();
    },

    HideViewDataRole() {
      let self = this;
      self.ViewDataRolePermissionStatus = false;
      self.ViewDataRolePermissionList = [];
      self.ViewDataRolePermissionSearch = null;
      self.pageViewDataRolePermission = 1;
      self.itemsPerPageViewDataRolePermission = 10;
    },

    SearchGroupMember() {
      let self = this;
      try {
        self.flagLoadingSend = true;
        let temp = {
          Page: 1,
          PerPage: self.itemsPerPage,
          Search: self.search == "" ? null : self.search
        };
        axios
          .post(`${self.url}EmployeeExt/GetSearchDataEmployeeExt`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.empList = response.data.data.result.listData;
              self.pageCountUser = response.data.data.result.count;
              if (self.roletext == "addgroupemp") {
                console.log(self.addGroupEmployeeList);
                self.empList = self.empList.map(v => ({
                  ...v,
                  select: false
                }));
                if (self.addGroupEmployeeList != []) {
                  for (const item of self.addGroupEmployeeList) {
                    const index = self.empList.findIndex(
                      x => x.userID == item.employeeID
                    );
                    if (index != -1) {
                      self.empList[index].select = true;
                    }
                  }
                }
              } else if (self.roletext == "editgroupemp") {
                self.empList = self.empList.map(v => ({
                  ...v,
                  select: false
                }));
                if (self.editGroupEmployeeList != []) {
                  for (const item of self.editGroupEmployeeList) {
                    const index = self.empList.findIndex(
                      x => x.userID == item.employeeID
                    );
                    if (index != -1) {
                      self.empList[index].select = true;
                    }
                  }
                }
              }
              self.flagLoadingSend = false;
            } else {
              self.empList = [];
              self.flagLoadingSend = false;
            }
            self.flagLoadingSend = false;
          });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.empList = [];
        self.flagLoadingSend = false;
      }
    },

    CheckPermission() {
      let self = this;
      let PerArr = [];
      if (self.permissionid != "" && typeof self.permissionid != "object") {
        PerArr = self.permissionid.split(",");
      } else {
        PerArr = self.permissionid;
      }
      self.flag.user_view = PerArr.filter(x => x == 9 || x == 1).length > 0;
      self.flag.user_add = PerArr.filter(x => x == 10 || x == 1).length > 0;
      self.flag.user_edit = PerArr.filter(x => x == 11 || x == 1).length > 0;
      self.flag.user_delete = PerArr.filter(x => x == 12 || x == 1).length > 0;
      self.flag.group_view = PerArr.filter(x => x == 13 || x == 1).length > 0;
      self.flag.group_add = PerArr.filter(x => x == 14 || x == 1).length > 0;
      self.flag.group_edit = PerArr.filter(x => x == 15 || x == 1).length > 0;
      self.flag.group_delete = PerArr.filter(x => x == 16 || x == 1).length > 0;
      self.flag.role_view = PerArr.filter(x => x == 17 || x == 1).length > 0;
      self.flag.role_add = PerArr.filter(x => x == 18 || x == 1).length > 0;
      self.flag.role_edit = PerArr.filter(x => x == 19 || x == 1).length > 0;
      self.flag.role_delete = PerArr.filter(x => x == 20 || x == 1).length > 0;
      self.flag.permission_view =
        PerArr.filter(x => x == 21 || x == 1).length > 0;
      // console.log(self.flag);
    },

    orginalOne(val) {
      this.companyCode = null;
      this.companyName = null;
      this.email = null;
      this.company_Email = null;
      this.tele_Number = null;
      this.deletesearch = "";
      switch (val) {
        case 1:
          //User
          // this.search = "";
          this.searchUser = "";
          this.page = 1;
          this.itemsPerPage = 10;
          this.GetEmployeeList();
          break;
        case 2:
          //Group
          this.groupsearch = "";
          this.pageGroup = 1;
          this.itemsPerPageGroup = 10;
          this.GetGroupList();
          break;
        // case 3:
        //   //Permission
        //   this.permissionsearch = "";
        //   this.pagePermission = 1;
        //   this.itemsPerPagePermission = 10;
        //   this.GetPermissionList();
        //   break;
        case 3:
          //Role
          this.rolesearch = "";
          this.pageRole = 1;
          this.itemsPerPageRole = 10;
          this.GetRoleList();
          break;
      }
      //this.GetCompanyList();
      // this.GetPermissionList();
      // this.GetRoleList();
      // this.GetGroupList();
      // this.GetEmployeeList();
    },

    handleClick(value) {
      console.log(value);
    },

    sortFun(rowName) {
      // console.log(rowName);

      let self = this;
      let keyName = [
        "companyCode",
        "companyName",
        "status",
        "tele_Number",
        "company_Email"
      ];
      if (
        rowName == "companyCode" ||
        rowName == "companyName" ||
        rowName == "company_Email"
      ) {
        self.CompanyList = self.CompanyList.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.CompanyList = self.CompanyList.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },

    sortdelete(rowName) {
      let self = this;
      let keyName = [
        "companyCode",
        "companyName",
        "company_Email",
        "tele_Number"
      ];
      if (
        rowName == "companyCode" ||
        rowName == "companyName" ||
        rowName == "company_Email"
      ) {
        self.Deletecompanylist = self.Deletecompanylist.sort((a, b) =>
          self[rowName]
            ? b[rowName].localeCompare(a[rowName])
            : a[rowName].localeCompare(b[rowName])
        );
      } else {
        self.Deletecompanylist = self.Deletecompanylist.sort((a, b) =>
          self[rowName] ? a[rowName] - b[rowName] : b[rowName] - a[rowName]
        );
      }

      for (let x of keyName) {
        x == rowName ? (self[rowName] = !self[rowName]) : (self[x] = false);
      }
    },

    // GetEmployeeList() {
    //   try {
    //     let self = this;
    //     self.flagLoadingSend = true;
    //     axios
    //       .post(
    //         `${self.url}EmployeeExt/GetEmployeeExtByCompanyID?id=${self.tempCompanyID}`)
    //       .then(function (response) {
    //         //self.empList = response.data.data;
    //         if(response.data.data == null)
    //         {
    //           self.empList = undefined;
    //         }
    //         else
    //         {
    //           self.empList = response.data.data.map((v) => ({
    //             ...v,
    //             select: false,
    //           }));
    //         }
    //         //self.LogTrace(null, "Get Company", 12, "Critical");
    //         self.flagLoadingSend = false;
    //       });
    //   } catch (error) {
    //     //self.LogTrace(error, "Get Company Fail", 12, "Critical");
    //     self.empList = undefined;
    //     self.flagLoadingSend = false;
    //   }
    // },

    async GetEmployeeList() {
      let self = this;
      try {
        self.flagLoadingSend = true;
        let temp = {
          // CompanyID: self.tempCompanyID,
          Page: self.pageUser,
          PerPage: self.itemsPerPageUser,
          Search: self.searchUser == "" ? null : self.searchUser
        };
        await axios
          .post(`${self.url}EmployeeExt/GetSearchDataEmployeeExt`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              //console.log(response.data.data.result);
              self.empList = response.data.data.result.listData;
              self.pageCountUser = response.data.data.result.count;
              self.LimitUserUse = response.data.data.result.activateCount;
              //console.log(self.LimitUser,self.LimitUserUse)
              self.FreeUser = self.LimitUser - self.LimitUserUse;
              self.flagLoadingSend = false;
            } else {
              self.empList = [];
              self.flagLoadingSend = false;
            }

            self.flagLoadingSend = false;
          });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.empList = [];
        self.flagLoadingSend = false;
      }
    },

    async SearchEmployeeList() {
      let self = this;
      try {
        //self.searchText = self.searchUser;  
        self.flagLoadingSend = true;
        let temp = {
          // CompanyID: self.tempCompanyID,
          Page: 1,
          PerPage: self.itemsPerPageUser,
          //Search: self.searchUser == "" ? null : self.searchUser
          Search: this.searchUser.trim() === "" ? null : this.searchUser.toLowerCase()
        };
        await axios
          .post(`${self.url}EmployeeExt/GetSearchDataEmployeeExt`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.empList = response.data.data.result.listData;
              self.pageCountUser = response.data.data.result.count;
              //self.LimitUserUse = response.data.data.result.activateCount;
              self.pageUser = 1;
              self.flagLoadingSend = false;
            } else {
              self.empList = [];
              self.flagLoadingSend = false;
            }

            self.flagLoadingSend = false;
          });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.empList = [];
        self.flagLoadingSend = false;
      }
    },

    async ChangePerPage(val) {
      try {
        let self = this;
        self.flagLoadingSend = true;
        let temp = {
          // CompanyID: self.tempCompanyID,
          Page: 1,
          PerPage: val,
          Search: self.searchUser == "" ? null : self.searchUser,
          Filter: self.FlagSearch == true ? self.Filter : {role: null, signerStatus: null,}
        };
        await axios
          .post(`${self.url}EmployeeExt/GetSearchDataEmployeeExt`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.empList = response.data.data.result.listData;
              self.pageCountUser = response.data.data.result.count;
              self.pageUser = 1;
              self.flagLoadingSend = false;
            } else {
              self.empList = [];
              self.flagLoadingSend = false;
            }
            self.flagLoadingSend = false;
          });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.empList = [];
        self.flagLoadingSend = false;
      }
    },

    async ChangePage(val) {
      let self = this;
      try {
        self.flagLoadingSend = true;
        let temp = {
          // CompanyID: self.tempCompanyID,
          Page: val,
          PerPage: self.itemsPerPageUser,
          Search: self.searchUser == "" ? null : self.searchUser,
          Filter: self.FlagSearch == true ? self.Filter : {role: null, signerStatus: null,}
        };
        await axios
          .post(`${self.url}EmployeeExt/GetSearchDataEmployeeExt`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.empList = response.data.data.result.listData;
              self.pageCountUser = response.data.data.result.count;
              self.flagLoadingSend = false;
            } else {
              self.empList = [];
              self.flagLoadingSend = false;
            }
            self.flagLoadingSend = false;
          });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.empList = [];
        self.flagLoadingSend = false;
      }
    },

    CheckSelectCheckbox(val) {
      let self = this;
      if (self.roletext == "addgroupemp") {
        if (val.select == false) {
          let index = self.empList.findIndex(x => x.userID == val.userID);
          self.empList[index].select = true;
          val.select = true;
          // self.addGroupEmployeeList.push(val);
          self.addGroupEmployeeList.push({
            employeeID: val.userID,
            employeeName: "",
            groupID: null,
            tEmp_Group_ID: null
          });
          self.tempPermission.push(val);
        } else {
          let index = self.empList.findIndex(x => x.userID == val.userID);
          self.empList[index].select = false;
          // let index1 = self.addGroupEmployeeList.findIndex(x => x.userID == val.userID);
          let index1 = self.addGroupEmployeeList.findIndex(
            x => x.employeeID == val.userID
          );
          self.addGroupEmployeeList.splice(index1, 1);
        }
        // console.log(self.addGroupEmployeeList);
      } else if (self.roletext == "editgroupemp") {
        console.log(self.editGroupEmployeeList);
        if (val.select == false) {
          let index = self.empList.findIndex(x => x.userID == val.userID);
          self.empList[index].select = true;
          val.select = true;
          // self.editGroupEmployeeList.push(val);
          self.editGroupEmployeeList.push({
            employeeID: val.userID,
            employeeName: "",
            groupID: null,
            tEmp_Group_ID: null
          });
          self.tempPermission.push(val);
        } else {
          let index = self.empList.findIndex(x => x.userID == val.userID);
          self.empList[index].select = false;
          // let index1 = self.editGroupEmployeeList.findIndex(x => x.userID == val.userID);
          let index1 = self.editGroupEmployeeList.findIndex(
            x => x.employeeID == val.userID
          );
          self.editGroupEmployeeList.splice(index1, 1);
        }
        // console.log(self.editGroupEmployeeList);
      }
      // if (this.roletext == "addgroupemp") {
      //   this.addGroupEmployeeList = [];
      //   this.tempPermission = this.empList;
      //   this.addGroupDialog = true;
      //   this.addGroupEmployee = this.tempPermission
      //     .filter(x => x.select)
      //     .map(x => x.username)
      //     .join();
      //   this.tempPermission.forEach(Element => {
      //     if (Element.select == true) {
      //       // var Temp = this.addGroupEmployeeList.filter(x => x.permission_ID == Element.permissionID);
      //       // console.log(Temp);
      //       // if(Temp.length < 1)
      //       // {
      //         this.addGroupEmployeeList.push({
      //           employeeID: Element.userID,
      //           groupID: 0,
      //           createBy: "string",
      //           createDate: "2020-12-09T20:40:32.354Z",
      //           activeFlag: true
      //         });
      //       // }
      //     }
      //   });
      // } else if (this.roletext == "editgroupemp") {
      //   this.editGroupEmployeeList = [];
      //   this.tempPermission = this.empList;
      //   this.editGroupDialog = true;
      //   this.editGroupEmployee = this.tempPermission
      //     .filter(x => x.select)
      //     .map(x => x.username)
      //     .join();
      //   this.tempPermission.forEach(Element => {
      //     if (Element.select == true) {
      //       this.editGroupEmployeeList.push({
      //         employeeID: Element.userID,
      //         groupID: 0,
      //         createBy: "string",
      //         createDate: "2020-12-09T20:40:32.354Z",
      //         activeFlag: true
      //       });
      //     }
      //   });
      // }
    },

    async ChangePerPageAssign(val) {
      try {
        let self = this;
        self.flagLoadingSend = true;
        let temp = {
          // CompanyID: self.tempCompanyID,
          Page: 1,
          PerPage: val,
          Search: self.searchUser == "" ? null : self.searchUser
        };
        await axios
          .post(`${self.url}EmployeeExt/GetSearchDataEmployeeExt`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              console.log(self.editGroupEmployeeList);
              self.empList = response.data.data.result.listData;
              self.pageCountUser = response.data.data.result.count;
              self.page = 1;
              if (self.roletext == "addgroupemp") {
                self.empList = self.empList.map(v => ({
                  ...v,
                  select: false
                }));
                if (self.addGroupEmployeeList != []) {
                  for (const item of self.addGroupEmployeeList) {
                    const index = self.empList.findIndex(
                      x => x.userID == item.employeeID
                    );
                    if (index != -1) {
                      self.empList[index].select = true;
                    }
                  }
                }
              } else if (self.roletext == "editgroupemp") {
                self.empList = self.empList.map(v => ({
                  ...v,
                  select: false
                }));
                if (self.editGroupEmployeeList != []) {
                  for (const item of self.editGroupEmployeeList) {
                    const index = self.empList.findIndex(
                      x => x.userID == item.employeeID
                    );
                    if (index != -1) {
                      console.log(self.empList[index]);
                      self.empList[index].select = true;
                    }
                  }
                }
              }
              self.flagLoadingSend = false;
            } else {
              self.empList = [];
              self.flagLoadingSend = false;
            }
            self.flagLoadingSend = false;
          });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.empList = [];
        self.flagLoadingSend = false;
      }
    },

    async ChangePageAssign(val) {
      try {
        let self = this;
        self.flagLoadingSend = true;
        let temp = {
          // CompanyID: self.tempCompanyID,
          Page: val,
          PerPage: self.itemsPerPageUser,
          Search: self.search == "" ? null : self.search
        };
        await axios
          .post(`${self.url}EmployeeExt/GetSearchDataEmployeeExt`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.empList = response.data.data.result.listData;
              self.pageCountUser = response.data.data.result.count;
              if (self.roletext == "addgroupemp") {
                console.log(self.addGroupEmployeeList);
                self.empList = self.empList.map(v => ({
                  ...v,
                  select: false
                }));
                if (self.addGroupEmployeeList != []) {
                  for (const item of self.addGroupEmployeeList) {
                    const index = self.empList.findIndex(
                      x => x.userID == item.employeeID
                    );
                    if (index != -1) {
                      self.empList[index].select = true;
                    }
                  }
                }
              } else if (self.roletext == "editgroupemp") {
                self.empList = self.empList.map(v => ({
                  ...v,
                  select: false
                }));
                if (self.editGroupEmployeeList != []) {
                  for (const item of self.editGroupEmployeeList) {
                    const index = self.empList.findIndex(
                      x => x.userID == item.employeeID
                    );
                    if (index != -1) {
                      self.empList[index].select = true;
                    }
                  }
                }
              }
              self.flagLoadingSend = false;
            } else {
              self.empList = [];
              self.flagLoadingSend = false;
            }
            self.flagLoadingSend = false;
          });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.empList = [];
        self.flagLoadingSend = false;
      }
    },

    async GetPermissionList() {
      try {
        let self = this;
        self.flagLoadingSend = true;
        await axios
          .get(`${self.url}Permission/GetAllPermissions`)
          .then(function(response) {
            //self.permissionList = response.data.data;
            self.permissionList = response.data.data.map(v => ({
              ...v,
              select: false
            }));
            //self.LogTrace(null, "Get Company", 12, "Critical");
            self.flagLoadingSend = false;
          });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.flagLoadingSend = false;
      }
    },

    async GetRoleList() {
      let self = this;
      try {
        self.flagLoadingSend = true;
        await axios
          // .post(`${self.url}Role/GetRoleByCompanyID?id=${self.tempCompanyID}`)
          .post(`${self.url}Role/GetRoleByCompanyId`)
          .then(function(response) {
            //self.rolelist = response.data.data;
            if (response.data.data == null) {
              self.rolelist = undefined;
            } else {
              self.rolelist = response.data.data.map(v => ({
                ...v,
                select: false
              }));
            }
            //self.LogTrace(null, "Get Company", 12, "Critical");
            //console.log(self.rolelist);
            self.flagLoadingSend = false;
          });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.rolelist = undefined;
        self.flagLoadingSend = false;
      }
    },

    async GetGroupList() {
      try {
        let self = this;
        self.flagLoadingSend = true;
        await axios
          .post(`${self.url}Group/GetGroupByCompanyId`)
          .then(function(response) {
            //self.groupList = response.data.data;
            if (response.data.data == null) {
              self.groupList = undefined;
            } else {
              self.groupList = response.data.data.map(v => ({
                ...v,
                select: false
              }));
            }
            //self.LogTrace(null, "Get Company", 12, "Critical");
            self.flagLoadingSend = false;
          });
      } catch (error) {
        //self.LogTrace(error, "Get Company Fail", 12, "Critical");
        self.groupList = undefined;
        self.flagLoadingSend = false;
      }
    },

    addRole() {
      var self = this;
      self.addRoleDialog = true;
    },

    async clickPermission() {
      await this.GetPermissionList();
      this.PermissionPage = true;
      if (this.addRoleDialog == true) {
        this.roletext = "add";
        this.addRoleDialog = false;
      } else if (this.editRoleDialog == true) {
        this.roletext = "edit";
        this.editRoleDialog = false;
        // console.log("edit"+this.permissionList)
        this.permissionList = this.permissionList.map(v => ({
          ...v,
          select: false
        }));
        for (const item of this.eidtpermissionList) {
          const index = this.permissionList.findIndex(
            x => x.permissionID == item.permission_ID
          );
          this.permissionList[index].select = true;
        }
      } else if (this.addGroupDialog == true) {
        this.roletext = "addGroup";
        this.addGroupDialog = false;
      } else if (this.editGroupDialog == true) {
        this.roletext = "editGroup";
        this.editGroupDialog = false;
        this.permissionList = this.permissionList.map(v => ({
          ...v,
          select: false
        }));
        for (const item of this.editGroupPermissionList) {
          const index = this.permissionList.findIndex(
            x => x.permissionID == item.permissionID
          );
          this.permissionList[index].select = true;
        }
      } else if (this.addEmpDialog == true) {
        this.roletext = "addemp";
        this.addEmpDialog = false;
      } else if (this.editEmpDialog == true) {
        this.roletext = "editemp";
        this.editEmpDialog = false;
        this.permissionList = this.permissionList.map(v => ({
          ...v,
          select: false
        }));
        for (const item of this.editEmpPermissionList) {
          const index = this.permissionList.findIndex(
            x => x.permissionID == item.permissionID
          );
          this.permissionList[index].select = true;
        }
      }
    },

    hidePermission() {
      this.PermissionPage = false;
      if (this.roletext == "add") {
        this.addRoleDialog = true;
      } else if (this.roletext == "edit") {
        this.editRoleDialog = true;
      } else if (this.roletext == "addGroup") {
        this.addGroupDialog = true;
      } else if (this.roletext == "editGroup") {
        this.editGroupDialog = true;
      } else if (this.roletext == "addemp") {
        this.addEmpDialog = true;
      } else if (this.roletext == "editemp") {
        this.editEmpDialog = true;
      }
    },

    AddGroupRole() {
      this.GroupPage = false;
      if (this.roletext == "addgrouprole") {
        this.tempPermission = this.rolelist;
        this.addGroupDialog = true;
        this.addGroupRoleList = [];
        if (this.tempPermission.length != 0) {
          this.addGroupRole = this.tempPermission
            .filter(x => x.select)
            .map(x => x.roleName)
            .join();
          this.tempPermission.forEach(Element => {
            if (Element.select == true) {
              this.addGroupRoleList.push({
                groupID: 0,
                roleID: Element.roleID,
                createBy: "string",
                createDate: "2020-12-09T10:52:30.809Z",
                activeFlag: true
              });
            }
          });
        }
      } else if (this.roletext == "editgrouprole") {
        this.tempPermission = this.rolelist;
        // console.log(this.tempPermission);
        this.editGroupDialog = true;
        this.editGroupRoleList = [];
        if (this.tempPermission.length != 0) {
          this.editGroupRole = this.tempPermission
            .filter(x => x.select)
            .map(x => x.roleName)
            .join();
          this.tempPermission.forEach(Element => {
            if (Element.select == true) {
              this.editGroupRoleList.push({
                groupID: 0,
                roleID: Element.roleID,
                createBy: "string",
                createDate: "2020-12-09T10:52:30.809Z",
                activeFlag: true
              });
            }
          });
        }
      } else if (this.roletext == "addemp") {
        // this.tempPermission = this.rolelist;
        var RID = this.RoleIDUser;
        this.addEmpDialog = true;
        this.addEmpRoleList = [];
        this.addEmpRoleList.push({
          employeeID: 0,
          roleID: RID,
          createBy: "string",
          createDate: "2020-12-09T23:00:59.540Z",
          activeFlag: true
        });
        this.addEmpRole = this.rolelist.filter(x => x.roleID == RID)[0].roleName;
        // console.log(this.addEmpRoleList);
        // if (this.tempPermission.length != 0) {
        //   this.addEmpRole = this.tempPermission
        //     .filter(x => x.select)
        //     .map(x => x.roleName)
        //     .join();
        //   this.tempPermission.forEach(Element => {
        //     if (Element.select == true) {
        //       this.addEmpRoleList.push({
        //         employeeID: 0,
        //         roleID: Element.roleID,
        //         createBy: "string",
        //         createDate: "2020-12-09T23:00:59.540Z",
        //         activeFlag: true
        //       });
        //     }
        //   });
        // }
      } else if (this.roletext == "editemp") {
        let role_ID = this.RoleIDUser;
        this.tempPermission = this.rolelist;
        this.editEmpDialog = true;
        this.editEmpRoleList = [];
        this.editEmpRoleList.push({
          employeeID: 0,
          roleID: this.RoleIDUser,
          createBy: "string",
          createDate: "2020-12-09T23:00:59.540Z",
          activeFlag: true
        });
        this.editEmpRole = this.rolelist.filter(x => x.roleID == role_ID)[0].roleName;
        // if (this.tempPermission.length != 0) {
        //   this.editEmpRole = this.tempPermission
        //     .filter(x => x.select)
        //     .map(x => x.roleName)
        //     .join();
        //   this.tempPermission.forEach(Element => {
        //     if (Element.select == true) {
        //       this.editEmpRoleList.push({
        //         employeeID: 0,
        //         roleID: self.RoleIDUser,
        //         createBy: "string",
        //         createDate: "2020-12-09T23:00:59.540Z",
        //         activeFlag: true
        //       });
        //     }
        //   });
        // }
      }
    },

    AddEmpGroup() {
      this.RolePage = false;
      if (this.roletext == "addemp") {
        this.tempPermission = this.groupList;
        this.addEmpDialog = true;
        this.addEmpGroup = this.tempPermission
          .filter(x => x.select)
          .map(x => x.groupName)
          .join();
        this.tempPermission.forEach(Element => {
          if (Element.select == true) {
            this.addEmpGroupList.push({
              employeeID: 0,
              groupID: Element.id,
              createBy: "string",
              createDate: "2020-12-09T23:00:59.540Z",
              activeFlag: true
            });
          }
        });
      } else if (this.roletext == "editemp") {
        this.tempPermission = this.groupList;
        this.editEmpDialog = true;
        this.editEmpGroup = this.tempPermission
          .filter(x => x.select)
          .map(x => x.groupName)
          .join();
        this.editEmpGroupList = [];
        this.tempPermission.forEach(Element => {
          if (Element.select == true) {
            this.editEmpGroupList.push({
              employeeID: 0,
              groupID: Element.id,
              createBy: "string",
              createDate: "2020-12-09T23:00:59.540Z",
              activeFlag: true
            });
          }
        });
      }
    },

    AddGroupEmp() {
      this.EmpPage = false;
      if (this.roletext == "addgroupemp") {
        this.addGroupEmployeeList = [];
        // this.tempPermission = this.empList;
        this.addGroupDialog = true;
        this.addGroupEmployee = this.tempPermission
          .filter(x => x.select)
          .map(x => x.username)
          .join();
        this.tempPermission.forEach(Element => {
          if (Element.select == true) {
            // var Temp = this.addGroupEmployeeList.filter(x => x.permission_ID == Element.permissionID);
            // console.log(Temp);
            // if(Temp.length < 1)
            // {
            this.addGroupEmployeeList.push({
              employeeID: Element.userID,
              groupID: 0,
              createBy: "string",
              createDate: "2020-12-09T20:40:32.354Z",
              activeFlag: true
            });
            // }
          }
        });
      } else if (this.roletext == "editgroupemp") {
        this.editGroupEmployeeList = [];
        // this.tempPermission = this.empList;
        this.editGroupDialog = true;
        this.editGroupEmployee = this.tempPermission
          .filter(x => x.select)
          .map(x => x.username)
          .join();
        this.tempPermission.forEach(Element => {
          if (Element.select == true) {
            this.editGroupEmployeeList.push({
              employeeID: Element.userID,
              groupID: 0,
              createBy: "string",
              createDate: "2020-12-09T20:40:32.354Z",
              activeFlag: true
            });
          }
        });
      }
    },

    AddPermissionList() {
      this.PermissionPage = false;
      if (this.roletext == "add") {
        this.addRoleDialog = true;
        this.addPermissonList = [];
        this.tempPermission = this.permissionList;
        this.addRolePermission = this.tempPermission
          .filter(x => x.select)
          .map(x => x.permissionName)
          .join();
        this.tempPermission.forEach(Element => {
          if (Element.select == true) {
            // var Temp = saft.addPermissonList.filter(x => x.permission_ID == Element.permissionID);
            // if(Temp.length < 1)
            // {
            this.addPermissonList.push({
              role_ID: 0,
              permission_ID: Element.permissionID,
              createBy: "string",
              createDate: "2020-12-06T16:00:23.578Z",
              activeFlag: true
            });
            // }
          }
        });
      } else if (this.roletext == "edit") {
        this.editRoleDialog = true;
        this.tempPermission = this.permissionList;
        this.eidtpermissionList = this.tempPermission;
        this.editPermissionList = [];
        this.editRolePermission = "";
        this.editRolePermission = this.tempPermission
          .filter(x => x.select)
          .map(x => x.permissionName)
          .join();
        this.tempPermission.forEach(Element => {
          if (Element.select == true) {
            // var Temp = saft.editPermissionList.filter(x => x.permission_ID == Element.permissionID);
            // if(Temp.length < 1)
            // {
            this.editPermissionList.push({
              role_ID: 0,
              permission_ID: Element.permissionID,
              createBy: "string",
              createDate: "2020-12-06T16:00:23.578Z",
              activeFlag: true
            });
            // }
          }
        });
      } else if (this.roletext == "addGroup") {
        this.addGroupDialog = true;
        this.addGroupPermissionList = [];
        this.tempPermission = this.permissionList;
        this.addGroupPermission = this.tempPermission
          .filter(x => x.select)
          .map(x => x.permissionName)
          .join();
        this.tempPermission.forEach(Element => {
          if (Element.select == true) {
            this.addGroupPermissionList.push({
              groupID: 0,
              permissionID: Element.permissionID,
              createBy: "string",
              createDate: "2020-12-06T16:00:23.578Z",
              activeFlag: true
            });
          }
        });
      } else if (this.roletext == "editGroup") {
        this.editGroupDialog = true;
        this.tempPermission = this.permissionList;
        this.editGroupPermissionList = [];
        this.editGroupPermission = "";
        this.editGroupPermission = this.tempPermission
          .filter(x => x.select)
          .map(x => x.permissionName)
          .join();
        this.tempPermission.forEach(Element => {
          if (Element.select == true) {
            this.editGroupPermissionList.push({
              groupID: 0,
              permissionID: Element.permissionID,
              createBy: "string",
              createDate: "2020-12-06T16:00:23.578Z",
              activeFlag: true
            });
          }
        });
      } else if (this.roletext == "addemp") {
        this.addEmpDialog = true;
        this.tempPermission = this.permissionList;
        this.addEmpPermissionList = [];
        this.addEmpPermission = "";
        this.addEmpPermission = this.tempPermission
          .filter(x => x.select)
          .map(x => x.permissionName)
          .join();
        this.tempPermission.forEach(Element => {
          if (Element.select == true) {
            this.addEmpPermissionList.push({
              employeeID: 0,
              permissionID: Element.permissionID,
              createBy: "string",
              createDate: "2020-12-09T23:00:59.540Z",
              activeFlag: true
            });
          }
        });
      } else if (this.roletext == "editemp") {
        this.editEmpDialog = true;
        this.tempPermission = this.permissionList;
        this.editEmpPermissionList = [];
        this.editEmpPermission = "";
        this.editEmpPermission = this.tempPermission
          .filter(x => x.select)
          .map(x => x.permissionName)
          .join();
        this.tempPermission.forEach(Element => {
          if (Element.select == true) {
            this.editEmpPermissionList.push({
              employeeID: 0,
              permissionID: Element.permissionID,
              createBy: "string",
              createDate: "2020-12-09T23:00:59.540Z",
              activeFlag: true
            });
          }
        });
      }
    },

    EditEmployee() {
      let self = this;
      //console.log(self.editActiveStatus, self.FreeUser, self.LimitUser);
      if (
        self.editUsername == "" ||
        self.editFirstname == "" ||
        self.editLastName == "" ||
        self.editEmail == "" ||
        self.editPhonenum == "" ||
        self.editEmpGroupList == [] ||
        self.editEmpRoleList == [] ||
        self.editEmpPermissionList == []
      ) {
        // alert("Fill all fields.");
        self.typeAlertSend = "warning";
        self.mesAlertSend = this.$t("Fill all fields.");
        self.flagAlertSend = true; 
      } 
      else if (self.validateEmail(self.editEmail) == "invalid") {
        // alert("Fill correct email format.");
        self.typeAlertSend = "warning";
        self.mesAlertSend = this.$t("fillcorrectemailformat");
        self.flagAlertSend = true; 
      } 
      else if (self.editEmail != "" && self.editEmail.split('@')[1].split('.')[0] != self.EmailAdmin && self.OwnAdmin == false) {       
        self.typeAlertSend = "warning";
        self.mesAlertSend = "กรุณากรอก format Email ให้เหมือนกับ Admin";
        self.flagAlertSend = true;      
      } 
      else if (!self.editEmail.includes(self.EmailAdmin) && !self.OwnAdmin) {
        // alert("Fill correct email format.");
        self.typeAlertSend = "warning";
        self.mesAlertSend = this.$t("fillcorrectemailformat");
        self.flagAlertSend = true; 
      } 
      else if (self.editFirstname.toLowerCase() == "admin") {
        // alert("Fill correct phone number(9-11 digits).");
        self.typeAlertSend = "warning";
        self.mesAlertSend = this.$t("donotusethewordadmin");
        self.flagAlertSend = true;
      }  
      else if (
        self.editPhonenum.length < 9 ||
        self.editPhonenum.length > 11
      ) {
        // alert("Fill correct phone number(9-11 digits).");
        self.typeAlertSend = "warning";
        self.mesAlertSend = this.$t("fillcorrectphonenumber(9-11 digits)");
        self.flagAlertSend = true; 
      }
      // else if(self.editActiveStatus == true && self.LimitUser != 0 && self.FreeUser == 0)
      // {
      //   self.typeAlertSend = "warning";
      //   self.mesAlertSend = "Activated Users have reached limit";
      //   self.flagAlertSend = true;
      // }
       else {
        self.flagLoadingSend = true;
       // self.editEmpDialog = false;
        var temp = {
          employeeID: self.tempEmpID,
          userID: 0,
          memberID: 0,
          username: self.editUsername,
          firstName: self.editFirstname,
          lastName: self.editLastName,
          email: self.editEmail,
          password: self.editPassword == self.oldPsw ? null : self.editPassword,
          userType: "string",
          roleID: 0,
          mobile: self.editPhonenum,
          totalRecord: 0,
          employeeName: "string",
          positionID: 0,
          // companyID: self.tempCompanyID,
          employeeGroup: self.editEmpGroupList,
          employeeRole: self.editEmpRoleList,
          employeePermission: self.editEmpPermissionList,
          updateBy: "string",
          updateDate: "2020-12-10T01:31:06.998Z",
          activeFlag: true,
          flagAdmin: self.editFlagAdmin,
          activeStatus: self.editEmpRole == "Signer" ? self.editActiveStatus : false,
          lockStatus: self.lockStatus
        };
        // console.log(temp);
        axios
          .post(`${self.url}EmployeeExt/UpdateEmployeeExtJoin`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              if(response.data.data == "Success"){
                self.typeAlertSend = "success"; //success warning error
                self.mesAlertSend = response.data.message;
                self.editEmpDialog = false;
                self.GetEmployeeList();
                self.GetAllEmployeeList();
                self.GetDataProductPlan();
                self.GetDataFormatEmail();
                self.flagLoadingSend = false;
                self.flagAlertSend = true;
              }
              else if(response.data.data == "ไม่พบ Plan ที่ท่านเลือก กรุณาติดต่อ Sale"){
                self.typeAlertSend = "warning";
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                
                self.flagLoadingSend = false;
              }
              else if(response.data.data == "email address is already in use!"){
                self.typeAlertSend = "warning";
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                self.editEmpDialog = true;
                self.flagLoadingSend = false;
              }
              else if(response.data.data == "จำนวน User ที่ Activate เกินจำนวน Plan"){
                self.typeAlertSend = "warning";
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                self.flagLoadingSend = false;
              }
              else if(response.data.data == "ไม่พบข้อมูล Member !"){
                self.typeAlertSend = "warning";
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                self.flagLoadingSend = false;
              }
              else if(response.data.data == "ไม่พบข้อมูล Employee !"){
                self.typeAlertSend = "warning";
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                self.flagLoadingSend = false;
              }
              else if(response.data.data == "ไม่พบข้อมูล User !"){
                self.typeAlertSend = "warning";
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                self.flagLoadingSend = false;
              }
              else {
                self.typeAlertSend = "warning"; //success warning error
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                self.flagLoadingSend = false;
              }
            }
            else{
              self.flagLoadingSend = false;
            }
          })
          .catch(function(error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          });
      }
    },

    AddRole() {
      var self = this;
      if (
        self.addRoleCode == "" ||
        self.addRoleName == "" ||
        self.addPermissonList.length == 0
      ) {
        alert("Fill all fields.");
      } else {
        this.addRoleDialog = false;
        this.flagLoadingSend = true;
        var temp = {
          roleCode: this.addRoleCode,
          roleName: this.addRoleName,
          // companyID: this.tempCompanyID,
          rolePermission: this.addPermissonList,
          createBy: "string",
          createDate: "2020-12-06T16:00:23.578Z",
          activeFlag: true
        };
        axios
          .post(`${self.url}Role/AddRole`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              //self.LogTrace(null, "Delete Company", 12, "High");
              self.GetRoleList();
              self.clearRole();
            }
            self.flagLoadingSend = false;
          })
          .catch(function(error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          });
      }
    },

    rolestatus() {
      if (this.role_status == false) {
        this.role_status = true;
        this.selectAllRoleRows();
      } else {
        this.role_status = false;
        this.clearAllRoleRows();
      }
    },

    selectAllRoleRows() {
      for (const item of this.rolelist) {
        item.select = true;
      }
    },

    clearAllRoleRows() {
      for (const item of this.rolelist) {
        item.select = false;
      }
    },

    empstatus() {
      if (this.emp_stauts == false) {
        this.emp_stauts = true;
        this.selectAllEmpRows();
      } else {
        this.emp_stauts = false;
        this.clearAllEmpRows();
      }
    },

    selectAllEmpRows() {
      for (const item of this.empList) {
        item.select = true;
      }
    },

    clearAllEmpRows() {
      for (const item of this.empList) {
        item.select = false;
      }
    },

    async clickaddGroupEmployee() {
      await this.GetGroupList();
      if (this.addGroupDialog == true) {
        this.EmpPage = true;
        this.roletext = "addgroupemp";
        this.search = "";
        this.addGroupDialog = false;
        this.page = 1;
        this.itemsPerPage = 10;
        await this.ChangePageAssign(this.page);
        this.addGroupEmployeeList = [];
        this.tempPermission = [];
        console.log(this.empList);
        this.empList = this.empList.map(v => ({
          ...v,
          select: false
        }));
      } else if (this.editGroupDialog == true) {
        this.EmpPage = true;
        this.roletext = "editgroupemp";
        this.search = "";
        this.editGroupDialog = false;
        this.page = 1;
        this.itemsPerPage = 10;
        await this.ChangePageAssign(this.page);
        console.log(this.editGroupEmployeeList);
        if (this.editGroupEmployeeList != []) {
          this.empList = this.empList.map(v => ({
            ...v,
            select: false
          }));
          for (const item of this.editGroupEmployeeList) {
            const index = this.empList.findIndex(
              x => x.userID == item.employeeID
            );
            if (index != -1) {
              this.empList[index].select = true;
            }
          }

          this.tempPermission = this.empList;
        }
      } else if (this.addEmpDialog == true) {
        this.RolePage = true;
        this.roletext = "addemp";
        this.addEmpDialog = false;
        if (this.addEmpGroup != []) {
          this.groupList = this.groupList.map(v => ({
            ...v,
            select: false
          }));
          // for (const item of this.addEmpGroupList) {
          //   const index = this.groupList.findIndex((x) => x.id == item.id);
          //   this.groupList[index].select = true;
          // }
        }
      } else if (this.editEmpDialog == true) {
        this.RolePage = true;
        this.roletext = "editemp";
        this.editEmpDialog = false;
        if (this.editEmpGroupList.length != 0) {
          this.groupList = this.groupList.map(v => ({
            ...v,
            select: false
          }));
          for (const item of this.editEmpGroupList) {
            const index = this.groupList.findIndex(x => x.id == item.groupID);
            this.groupList[index].select = true;
          }
        }
      }
    },

    permissionstatus() {
      if (this.permission_status == false) {
        this.permission_status = true;
        this.selectAllPermissionRows();
      } else {
        this.permission_status = false;
        this.clearAllPermissionRows();
      }
    },

    selectAllPermissionRows() {
      for (const item of this.permissionList) {
        item.select = true;
      }
    },

    clearAllPermissionRows() {
      for (const item of this.permissionList) {
        item.select = false;
      }
    },

    groupstatus() {
      if (this.group_status == false) {
        this.group_status = true;
        this.selectAllGroupRows();
      } else {
        this.group_status = false;
        this.clearAllGroupRows();
      }
    },

    selectAllGroupRows() {
      for (const item of this.groupList) {
        item.select = true;
      }
    },

    clearAllGroupRows() {
      for (const item of this.groupList) {
        item.select = false;
      }
    },

    deleteRole(id) {
      this.roleID = id;
      this.deleteRoleDialog = true;
    },

    editRole(item) {
      let self = this;
      self.editRoleDialog = true;
      self.editroleID = item.roleID;
      self.editRoleName = item.roleName;
      self.editRoleCode = item.roleCode;
      self.editRolePermission = item.rolePermission
        .map(x => x.permissionName)
        .join();

      self.editPermissionList = [];
      item.rolePermission.forEach(e => {
        self.editPermissionList.push(e);
      });
      item.rolePermission.forEach(e => {
        self.eidtpermissionList.push(e);
      });
      // this.eidtpermissionList = item.rolePermission;
    },

    UpdateRole() {
      var self = this;
      if (
        self.editRoleCode == "" ||
        self.editRoleName == "" ||
        self.editPermissionList.length == 0
      ) {
        alert("Fill all fields.");
      } else {
        self.editRoleDialog = false;
        self.flagLoadingSend = true;
        var temp = {
          roleID: self.editroleID,
          roleCode: self.editRoleCode,
          roleName: self.editRoleName,
          rolePermission: self.editPermissionList,
          updateBy: "string",
          updateDate: "2020-12-07T10:00:18.301Z",
          activeFlag: true
        };
        axios
          .post(`${self.url}Role/UpdateRole`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              //self.LogTrace(null, "Delete Company", 12, "High");
              self.GetRoleList();
              self.clearRole();
              // self.editGroupPermissionList = [];
              // self.editGroupRoleList = [];
            }
            self.flagLoadingSend = false;
          })
          .catch(function(error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          });
      }
    },

    DeleteRole() {
      let self = this;
      self.deleteRoleDialog = false;
      self.flagLoadingSend = true;
      axios
        .post(`${self.url}Role/DeleteRole?id=${self.roleID}`)
        .then(function(response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            //self.LogTrace(null, "Delete Company", 12, "High");
            self.GetRoleList();
          }
          self.flagLoadingSend = false;
        })
        .catch(function(error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
        });
    },

    addGroup() {
      this.addGroupDialog = true;
      this.addGroupName = "";
      this.addGroupEmployee = "";
      this.addGroupRole = "";
      this.addGroupPermission = "";
    },

    editGroup(item) {
      this.editGroupDialog = true;
      this.tempGroupID = item.id;
      this.editGroupName = item.groupName;
      //this.editRoleCode = item.roleCode;
      this.editGroupEmployee = item.groupEmployee
        .map(x => x.employeeName)
        .join();
      this.editGroupEmployeeList = item.groupEmployee;
      this.editGroupRole = item.groupRole.map(x => x.roleName).join();
      this.editGroupRoleList = item.groupRole;
      if (item.groupPermission.length != 0) {
        this.advanceGroup = true;
        this.editGroupPermission = item.groupPermission
          .map(x => x.permissionName)
          .join();
        this.editGroupPermissionList = item.groupPermission;
      } else {
        this.editGroupPermission = "";
        this.editGroupPermissionList = [];
      }
    },

    deleteGroup(id) {
      this.deleteGroupDialog = true;
      this.tempGroupID = id;
    },

    DeleteGroup() {
      let self = this;
      self.deleteGroupDialog = false;
      self.flagLoadingSend = true;
      axios
        .post(`${self.url}Group/DeleteGroup?id=${self.tempGroupID}`)
        .then(function(response) {
          if (response.data.status == 0) {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
            //self.LogTrace(null, "Delete Company", 12, "High");
            self.flagLoadingSend = false;
            self.GetGroupList();
          } else {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
          }
          self.flagLoadingSend = false;
        })
        .catch(function(error) {
          //self.LogTrace(error, "Delete company Fail", 12, "High");
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    clickaddGroupPermission() {
      this.PermissionPage = true;
      if (this.addGroupDialog == true) {
        this.roletext = "addgrouprole";
        this.addGroupDialog = false;
      } else if (this.editGroupDialog == true) {
        this.roletext = "editgrouprole";
        this.editGroupDialog = false;
      }
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    editEmp(item) {
      this.oldPsw = item.password;
      this.editEmpDialog = true;
      this.lockStatus = item.lockStatus ?? false;
      this.editemp = "editemp";
      this.editPassword = item.password;
      this.tempEmpID = item.employeeID;
      this.editUsername = item.username;
      this.editFirstname = item.firstName;
      this.editLastName = item.lastName;
      this.editEmail = item.email;
      this.editPhonenum = item.mobile;
      //this.editRoleCode = item.roleCode;
      this.editEmpGroup = item.employeeGroup.map(x => x.groupName).join();
      this.editEmpGroupList = item.employeeGroup;
      this.editEmpRole = item.employeeRole.map(x => x.roleName).join();
      this.editEmpRoleList = item.employeeRole;
      this.editFlagAdmin = item.flagAdmin == null ? false : item.flagAdmin;
      this.editActiveStatus = item.activeStatus == null ? false : item.activeStatus;
      this.OwnAdmin = item.ownAdmin == null ? false : item.ownAdmin;
      if (item.employeePermission.length != 0) {
        this.advanceGroup = true;
        this.editEmpPermission = item.employeePermission
          .map(x => x.permissionName)
          .join();
        this.editEmpPermissionList = item.employeePermission;
      } else {
        this.editEmpPermission = "";
        this.editEmpPermissionList = [];
      }
    },

    deleteEmp(temp) {
      this.deleteEmpDialog = true;
      this.tempEmpID = temp;
    },

    DeleteEmp() {
      let self = this;
      self.deleteEmpDialog = false;
      self.flagLoadingSend = true;
      axios
        .post(
          `${self.url}EmployeeExt/DeleteEmployeeExtByUserId?id=${self.tempEmpID}`
        )
        .then(function(response) {
          if (response.data.status == 0) {
            self.typeAlertSend = "success"; //success warning error
            self.mesAlertSend = response.data.message;
            self.flagAlertSend = true;
            //self.LogTrace(null, "Delete Company", 12, "High");
            self.GetEmployeeList();
            self.GetDataProductPlan();
          }
        })
        .catch(function(error) {
          //self.LogTrace(error, "Delete company Fail", 12, "High");
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return re.test(email) || "invalid";
    },

    generateID: function() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }

      for (let i = 0; i < 6; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return password;
    },

    getGroupName(item) {
      return item.employeeGroup.map(x => x.groupName).join() != ""
        ? item.employeeGroup.map(x => x.groupName).join()
        : "N/A";
    },

    getRoleName(item) {
      return item.employeeRole.map(x => x.roleName).join() != ""
        ? item.employeeRole.map(x => x.roleName).join()
        : "N/A";
    },

    generate: function() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }

      for (let i = 0; i < this.size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return password;
    },

    TogglePassword() {
      var x = document.getElementById("password");
      if (x.type === "password") {
        x.type = "text";
        this.AddflagPassword = false;
      } else {
        x.type = "password";
        this.AddflagPassword = true;
      }
    },

    formatDate(date) {
      if (!date) return null;

      let finaldate = date.slice(0, 10).split("-");
      return finaldate[1] + "/" + finaldate[2] + "/" + finaldate[0];
    },

    addEmployee() {
      let self = this;
      self.addEmpDialog = true;
      self.roletext = "";
      self.addFirstname = "";
      self.addLastname = "";
      self.addPhonenum = "";
      self.addEmail = "";
      self.addEmpRole = "";
      self.FlagAdmin = false;
      self.ActiveStatus = false;
      self.addPassword = this.generate();
      self.addUsername = this.generateID();
    },

    closeaddEmpDialog() {
      let self = this;
      self.addEmpDialog = false;
      self.clearEmpData();
    },

    closeEmpDialog() {
      let self = this;
      self.editEmpDialog = false;
      this.lockStatus = false;
    },

    async AddEmployee() {
      let self = this;
      // console.log(self.EmailAdmin);
      // console.log(self.addEmail);
      if (
        self.addUsername == "" ||
        self.addFirstname == "" ||
        self.addLastname == "" ||
        self.addEmail == "" ||
        self.addPassword == "" ||
        self.addPhonenum == "" ||
        self.addEmpGroupList == [] ||
        self.addEmpRoleList == [] ||
        self.addEmpPermissionList == [] ||
        self.addEmpRole == ""
      ) {
        self.typeAlertSend = "warning";
        self.mesAlertSend = this.$t("Fill all fields.");
        self.flagAlertSend = true;
        // alert("Fill all fields.");
      } 
      else if (self.validateEmail(self.addEmail) == "invalid") {
        // alert("Fill correct email format.");
        self.typeAlertSend = "warning";
        self.mesAlertSend = this.$t("fillcorrectemailformat");
        self.flagAlertSend = true;
      }
      else if (self.addEmail != "" && self.addEmail.split('@')[1].split('.')[0] != self.EmailAdmin) {       
        self.typeAlertSend = "warning";
        self.mesAlertSend = "กรุณากรอก format Email ให้เหมือนกับ Admin";
        self.flagAlertSend = true;      
      } 
      else if (self.addFirstname.toLowerCase() == "admin") {
        // alert("Fill correct phone number(9-11 digits).");
        self.typeAlertSend = "warning";
        self.mesAlertSend = this.$t("donotusethewordadmin");
        self.flagAlertSend = true;
      }  
      else if (self.addPhonenum.length < 9 || self.addPhonenum.length > 11) {
        // alert("Fill correct phone number(9-11 digits).");
        self.typeAlertSend = "warning";
        self.mesAlertSend = this.$t("fillcorrectphonenumber(9-11 digits)");
        self.flagAlertSend = true;
      } else {
        //self.addEmpDialog = false;
        self.flagLoadingSend = true;
        var temp = {
          userID: 0,
          memberID: 0,
          username: self.addUsername,
          firstName: self.addFirstname,
          lastName: self.addLastname,
          email: self.addEmail,
          password: self.addPassword,
          userType: "string",
          roleID: 0,
          mobile: self.addPhonenum,
          employeeName: "string",
          positionID: 0,
          // companyID: self.tempCompanyID,
          employeeID: 0,
          employeeGroup: self.addEmpGroupList,
          employeeRole: self.addEmpRoleList,
          employeePermission: self.addEmpPermissionList,
          flagAdmin: self.FlagAdmin,
          activeStatus: self.addEmpRole == 'Signer' ? self.ActiveStatus : false,
          lockStatus: self.lockStatus
        };
        await axios
          .post(`${self.url}EmployeeExt/AddEmployeeExt`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              // alert(response.data.message);
              
              if(response.data.data == "Success"){
                self.typeAlertSend = "success"; //success warning error
                self.mesAlertSend = response.data.message;
                self.flagAlertSend = true;
                self.addEmpDialog = false;
                //self.LogTrace(null, "Delete Company", 12, "High");
                // self.flagLoadingSend = false;
                self.GetEmployeeList();
                self.GetAllEmployeeList();
                self.clearEmpData();
                self.GetDataProductPlan();
                self.flagLoadingSend = false;
                self.flagAlertSend = true;
              }
              else if(response.data.data == "Plan Expired !"){
                self.typeAlertSend = "warning";
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                self.addEmpDialog = true;
                self.flagLoadingSend = false;
              }
              else if(response.data.data == "email address is already in use!"){
                self.typeAlertSend = "warning";
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                self.addEmpDialog = true;
                self.flagLoadingSend = false;
              }
              else if(response.data.data == "ไม่พบ Plan ที่ท่านเลือก กรุณาติดต่อ Sale"){
                self.typeAlertSend = "warning";
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                self.addEmpDialog = true;
                self.flagLoadingSend = false;
              }
              else if(response.data.data == "จำนวน User ที่ Activate เกินจำนวน Plan"){
                self.typeAlertSend = "warning";
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                self.addEmpDialog = true;
                self.flagLoadingSend = false;
              }
              else if(response.data.data == "ไม่พบข้อมูล Member"){
                self.typeAlertSend = "warning";
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                self.addEmpDialog = true;
                self.flagLoadingSend = false;
              }
              else if(response.data.data == "ไม่พบข้อมูล User"){
                self.typeAlertSend = "warning";
                self.mesAlertSend = response.data.data;
                self.flagAlertSend = true;
                self.addEmpDialog = true;
                self.flagLoadingSend = false;
              }
              else{
                self.typeAlertSend = "warning"; //success warning error
                self.mesAlertSend = response.data.message;
                self.flagAlertSend = true;
                self.addEmpDialog = true;
                self.flagLoadingSend = false;
              } 
            } else {
              if (response.data.message == "Duplicate Username!") {
               self.typeAlertSend = "warning"; //success warning error
                self.mesAlertSend = response.data.message;
                self.flagAlertSend = true;
                self.flagLoadingSend = false;
              }
                           
            }
          })
          .catch(function(error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          });
      }
    },

    clearEmpData() {
      var self = this;
      self.addUsername = "";
      self.addFirstname = "";
      self.addLastname = "";
      self.addEmail = "";
      self.addPassword = "";
      self.addPhonenum = "";
      self.addEmpGroupList = [];
      self.addEmpGroup = "";
      self.addEmpRoleList = [];
      self.addEmpRole = "";
      self.addEmpPermissionList = [];
      self.addEmpPermission = "";
      self.advanceGroup = false;
      self.RoleIDUser = "";
      self.ActiveStatus = false;
      self.lockStatus = false;
    },

    closeEmpPage() {
      this.EmpPage = false;
      if (this.roletext == "addgroupemp") {
        this.addGroupDialog = true;
      } else if (this.roletext == "editgroupemp") {
        this.editGroupDialog = true;
      }
    },

    AddGroup() {
      var self = this;
      if (
        self.addGroupName == "" ||
        self.addGroupEmployeeList == [] ||
        self.addGroupRoleList == [] ||
        self.addGroupPermissionList == []
      ) {
        // alert("Fill all fields.");
        self.typeAlertSend = "warning";
        self.mesAlertSend = this.$t("Fill all fields.");
        self.flagAlertSend = true;
      } else {
        this.addGroupDialog = false;
        this.flagLoadingSend = true;
        var temp = {
          groupName: self.addGroupName,
          // companyID: self.tempCompanyID,
          groupEmployee: self.addGroupEmployeeList,
          groupRole: self.addGroupRoleList,
          groupPermission: self.addGroupPermissionList
        };
        axios
          .post(`${self.url}Group/AddGroup`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              //self.LogTrace(null, "Delete Company", 12, "High");
              self.GetGroupList();
            }
            self.flagLoadingSend = false;
          })
          .catch(function(error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          });
      }
    },

    EditGroupRole() {
      var self = this;
      if (
        self.editGroupName == "" ||
        self.editGroupEmployeeList == [] ||
        self.editGroupRoleList == [] ||
        self.editGroupPermissionList == []
      ) {
        // alert("Fill all fields.");
        self.typeAlertSend = "warning";
        self.mesAlertSend = this.$t("Fill all fields.");
        self.flagAlertSend = true;
      } else {
        this.editGroupDialog = false;
        this.flagLoadingSend = true;
        var temp = {
          id: self.tempGroupID,
          groupName: self.editGroupName,
          groupEmployee: self.editGroupEmployeeList,
          groupRole: self.editGroupRoleList,
          groupPermission: self.editGroupPermissionList,
          createBy: "string",
          createDate: "2020-12-09T10:52:30.809Z",
          activeFlag: true
        };
        axios
          .post(`${self.url}Group/UpdateGroup`, temp)
          .then(function(response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              //self.LogTrace(null, "Delete Company", 12, "High");
              self.GetGroupList();
            }
            self.flagLoadingSend = false;
          })
          .catch(function(error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          });
      }
    },

    detailcompany(temp) {
      let self = this;
      self.detaildialog = true;
      self.DetailcompanyName = temp.companyName;
      self.DetailcompanyCode = temp.companyCode;
      self.DetailtelephoneNo = temp.telephoneNo;
      self.Detailemail = temp.email;
      self.Detailcountry = temp.country;
      self.Detailcity = temp.city;
      self.Detailaddress = temp.address;
      self.Detailstatus = temp.status;
      self.DetailuserID = temp.username;
      self.Detailpassword = temp.password;
    },

    closeaddRoleDialog() {
      var self = this;
      self.addRoleDialog = false;
      self.clearRole();
    },

    clearRole() {
      var self = this;
      //self.permissionList = [];
      self.selected = [];
      self.eidtpermissionList = [];

      self.addRoleName = "";
      self.addRoleCode = "";
      self.addRolePermission = "";

      //update
      self.editRoleName = "";
      self.editRoleCode = "";
      self.editRolePermission = "";
    },

    closeeditRoleDialog() {
      var self = this;
      self.editRoleDialog = false;
      this.clearRole();
    },

    closeaddGroupDialog() {
      var self = this;
      self.addGroupDialog = false;
      self.addGroupName = "";
      self.addGroupEmployee = "";
      self.addGroupEmployeeList = [];
      self.addGroupRole = "";
      self.addGroupRoleList = [];
      self.addGroupPermission = "";
      self.addGroupPermissionList = [];
      self.selected = [];
    },

    closeeditGroupDialog() {
      var self = this;
      self.editGroupDialog = false;
      self.editGroupName = "";
      self.editGroupEmployee = "";
      self.editGroupEmployeeList = [];
      self.editGroupRole = "";
      self.editGroupRoleList = [];
      self.editGroupPermission = "";
      self.editGroupPermissionList = [];
      self.selected = [];
    },

    async clickaddGroupRole() {
      await this.GetRoleList();
      this.GroupPage = true;
      if (this.addGroupDialog == true) {
        this.roletext = "addgrouprole";
        this.addGroupDialog = false;
        this.rolelist = this.rolelist.map(v => ({
          ...v,
          select: false
        }));
      } else if (this.editGroupDialog == true) {
        this.roletext = "editgrouprole";
        this.editGroupDialog = false;
        if (this.editGroupRoleList != []) {
          this.rolelist = this.rolelist.map(v => ({
            ...v,
            select: false
          }));
          for (const item of this.editGroupRoleList) {
            const index = this.rolelist.findIndex(x => x.roleID == item.roleID);
            this.rolelist[index].select = true;
          }
        }
        // console.log(this.rolelist);
        // if (this.editGroupPermissionList != []) {
        //   for (const item of this.editGroupPermissionList) {
        //     const index = this.permissionList.findIndex(
        //       (x) => x.permissionID == item.permissionID
        //     );
        //     this.permissionList[index].select = true;
        //   }
        // }
      } else if (this.addEmpDialog == true) {
        this.roletext = "addemp";
        this.addEmpDialog = false;
        this.rolelist = this.rolelist.map(v => ({
          ...v,
          select: false
        }));
      } else if (this.editEmpDialog == true) {
        this.roletext = "editemp";
        this.editEmpDialog = false;
        this.rolelist = this.rolelist.map(v => ({
          ...v,
          select: false
        }));
        if (this.editEmpRoleList != []) {
          this.rolelist = this.rolelist.map(v => ({
            ...v,
            select: false
          }));
          for (const item of this.editEmpRoleList) {
            const index = this.rolelist.findIndex(x => x.roleID == item.roleID);
            this.rolelist[index].select = true;
          }
        }
      }
    },

    hideRole() {
      if (this.roletext == "addgrouprole") {
        this.GroupPage = false;
        this.addGroupDialog = true;
      } else if (this.roletext == "editgrouprole") {
        this.GroupPage = false;
        this.editGroupDialog = true;
      } else if (this.roletext == "addemp") {
        this.GroupPage = false;
        this.RolePage = false;
        this.addEmpDialog = true;
      } else if (this.roletext == "editemp") {
        this.GroupPage = false;
        this.RolePage = false;
        this.editEmpDialog = true;
      }
    }
  }
};
</script>
<style scoped>

 .v-input--selection-controls__input {
    padding: 2px; /* Adjust the desired padding value */
  }
.img_disabled {
  opacity: 0.5;
}

.card-preview {
  border-radius: 5px !important;
  height: 100%;
  /* overflow: auto; */
  padding: 0;
}
.text-capitalize {
  font-size: 14px;
}
.mytitle {
  font-size: 14px;
  font-weight: 300;
}
.delbtn {
  background-color: #1976d2;
  border-radius: 20px;
}
* >>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #1976d2 !important;
}
* >>> .theme--light.v-data-table thead tr th {
  color: #ffffff !important;
}
.v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}
.v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
/* .v-card:not(.v-sheet--tile):not(.v-card--shaped){
  border-radius: 20px;
} */
* >>> .v-dialog {
  border-radius: 20px !important;
}
.typeofleave .v-input__slot {
  min-height: 30px !important;
  width: 90rem !important;
}
*
  >>> .theme--light.v-data-table
  tbody
  tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff9f0 !important;
}

*
  >>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
* >>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
* >>> .theme--light.v-pagination .v-pagination__item--active {
  background: #1976d2 !important;
  color: white !important;
}
* >>> .v-data-table tr td {
  height: 70px;
}
* >>> .theme--light.v-data-table tbody td:not(.v-data-table__mobile-row) {
  font-weight: 400;
}
* >>> .theme--light.v-data-table thead tr th {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 3px;
}
.firsttab {
  background-color: white;
  width: 140px;
}
.tabone {
  width: 150px;
  font-size: 14px;
  border: solid 1px #eeeef4;
  border-radius: 3px;
  height: 42px;
}
* >>> .mdi-paperclip::before {
  content: none;
}
* >>> .foricon .mdi:before {
  color: #1976d2;
}
.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #ffffff !important;
}
.title {
  font-size: 20px;
}

/* New CSS */
.text-black {
  color: #000 !important;
}

.data-table-header {
  background-color: rgb(18, 100, 150); 
  min-width: 180px;
}

.c-justify-end {
  justify-content: flex-end;
}

.margin-right-clear > div {
  margin-right: 0px !important;
}

@media only screen and (max-width: 431px) {
  .hide-header {
    display: none !important;
  }

  .full-btn-width {
    width: 100% !important;
  }

  .v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 0px !important;
  }

  .space-between {
    justify-content: space-between !important;
  }
}

@media only screen and (max-width: 320px) {
  .min-width {
    min-width: 91.1px !important;
  }

  .padding-right {
    padding-right: 50px !important;
  }
}
.lock_button{
  display: flex; 
  cursor: pointer;
  margin-bottom: 1rem;
}
.lock_button:hover {
  scale: 110%;
  transition: 0.1s
}
.lock_button:active {
  opacity: 0.8; 
}
</style>

<template>
  <div>
    <v-layout>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" style="margin: 0; padding: 0; background-color: white">
          <div style="position: relative">
            <v-row align="center" justify="center" class="img-intersection">
              <v-col cols="12" sm="6" md="6">
                <v-form class="form ml-12 mr-12">
                  <div>
                    <p>Cert Name</p>
                  </div>
                  <v-text-field
                    placeholder=" "
                    disabled
                    dense
                    outlined
                    class="mt-4"
                    name="login"
                    type="text"
                    v-model="CertName"
                  />
                  <div>
                    <p>Datetime</p>
                  </div>
                  <v-text-field
                    placeholder=" "
                    disabled
                    dense
                    outlined
                    class="mt-4"
                    name="login"
                    type="text"
                    v-model="Datetime"
                  />
                  <div>
                    <p>Password</p>
                  </div>
                  <v-text-field
                    required
                    :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showpass ? 'text' : 'password'"
                    dense
                    outlined
                    @click:append="showpass = !showpass"
                    v-model="Password"
                  ></v-text-field>
                </v-form>

                <v-layout flex align-center justify-center>
                  <v-btn
                    class="login mt-4"
                    large
                    style="width: 25%"
                    color="white"
                    align="center"
                    justify="center"
                    @click="ClosePage"
                    :loading="flagLoadingSend"
                    >Close</v-btn
                  >
                  <v-btn
                    class="login mt-4"
                    large
                    color="primary"
                    style="width: 25%"
                    align="center"
                    justify="center"
                    @click="SendData"
                    :loading="flagLoadingSend"
                    >Send</v-btn
                  >
                </v-layout>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-layout>
    
    <Loading
      :flagLoading="flagLoadingSend"
    />
    
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/api/function.js";
// import func from 'vue-editor-bridge';
import Loading from "@/components/Loading";

export default {
  components: {
    Loading,
  },
  mixins: [LogTrace],
  name: "ConfirmPassword",
  data() {
    return {
      CertName: "",
      CertPassword: "",
      Datetime: "",
      Password: "",
      PageBeforeName: "",
      url: enurl.apiUrl,
      showpass: false,
      strData: "",
      flagLoadingSend: false,
    };
  },
  mounted: function () {
    var currentdate = new Date();
    this.Datetime = currentdate.getDate() + "/" + (currentdate.getMonth()+1)  + "/" + currentdate.getFullYear() + " "  
                    + currentdate.getHours() + ":"  + currentdate.getMinutes();
    this.GetDataURL();   
  },
  methods: {
    GetDataURL(){
      let self = this;
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      self.CertName = urlParams.get('certname')
      self.strData = urlParams.get('stk')
    },
    ClosePage: function(){
      let self = this;
      self.CertName = "";
      self.CertPassword = "";
      self.Datetime = "";
      self.Password = "";
      window.close;
    },
    SendData: function(){
      var self = this;
      self.flagLoadingSend = true;
      var tmpData = {
        strData: self.strData,
        pw: self.Password,
      }
      axios
        .post(`${self.url}PDFStamp/PdfStampConfirm`, tmpData)
        .then(function (response) {
          if (response.data.status == 0) {
            self.$router.push("/success");
          } else {
            self.$router.push("/failure");
          }
        })
        .catch(function (error) {
          alert(error);
          self.flagLoadingSend = false;
          });
    },
    base64ToBlob( base64, type = "application/octet-stream" ) {
      const binStr = atob( base64 );
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[ i ] = binStr.charCodeAt( i );
      }
      return new Blob( [ arr ], { type: type } );
    },
  },
};
</script>
<style scoped>
.forgotPassword {
  margin-top: 1.3rem;
}

.forgot {
  width: 10rem;
}

.login {
  width: 14rem;
}

.form {
  min-width: 50%;
}

.img-intersection {
  width: 100%;
  height: 100vh;
  max-width: 100%;
}

@media only screen and (max-width: 959px) {
  .logocss {
    display: none;
  }
  .rescss {
    margin: 1rem;
    width: 100% !important;
  }
}
</style>

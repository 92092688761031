<template >
  <h1 style="position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;">Access Denied</h1>
</template>

<script>
export default {
  data(){
      return{
          
      }
  },
  mounted() {
      
  },
  methods:{

  }
}
</script>
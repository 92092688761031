<template>
  <div>
    <v-container fluid>
      <v-card class="card">
        <v-toolbar color="primary" dark>
          <v-toolbar-title class="title">{{$t('userprofile')}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-title class="title">{{today}}</v-toolbar-title>
        </v-toolbar>

        <v-layout flex align-center justify-center class="mt-4">
          <v-col md="2">
            <div style="text-align: center;">
              <v-avatar size="180" color="#2B76D1" class="mt-5" @click="pickFile">
                <div class="dotted">
                  <img
                    :src="'data:image/png;base64,'+imageUrl"
                    class="circular--portrait"
                    v-if="imageUrl"
                  />
                </div>
                <!-- <v-text-field label="Select Image" @click="pickFile" v-model="imageName"></v-text-field> -->
                <input
                  type="file"
                  style="display: none"
                  ref="image"
                  accept="image/*"
                  @change="onFilePicked"
                />
              </v-avatar>
            </div>

            <div style="text-align: center;">
              <p class="mt-4">
                <b>Fusion Fusion</b>
              </p>
              <p>{{username}}</p>
            </div>
          </v-col>
        </v-layout>
        <v-flex class="flex">
          <v-row>
            <v-col cols="12" md="3"></v-col>
            <v-col cols="12" md="3">
              <v-text-field
                placeholder="firstname"
                :label="$t('firstname')"
                v-model="firstName"
                :rules="[() => !!firstName || 'Required']"
                required
              >{{firstName}}</v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                placeholder="lastname"
                :label="$t('lastname')"
                v-model="lastName"
                :rules="[() => !!lastName || 'Required']"
                required
              >{{lastName}}</v-text-field>
            </v-col>
            <v-col cols="12" md="3"></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3"></v-col>
            <v-col cols="12" md="3">
              <v-text-field
                placeholder="email"
                :label="$t('email')"
                v-model="email"
                :rules="[rules.required, validateEmail(email)]"
              >{{email}}</v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                placeholder="phonenumber"
                :label="$t('phonenumber')"
                v-mask="mask"
                v-model="mobile"
                :rules="[rules.required, PhoneNumberValidation(mobile)]"
              >{{mobile}}</v-text-field>
            </v-col>
            <v-col cols="12" md="3"></v-col>
          </v-row>
          <div style="text-align: center;">
            <v-btn color="#1976D2" class="mt-10" @click="UpdateUser" style="width:14rem" outlined>
              <b>{{$t('save')}}</b>
            </v-btn>
          </div>

          <br />
          <br />
          <br />
        </v-flex>
      </v-card>
    </v-container>

    <Loading
      :flagLoading="flagLoadingSend"
    />
    
    <Alert
      :flagAlert="flagAlertSend"
      :typeAlert="typeAlertSend"
      :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()"
    />

  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import checkPermission from "@/api/function.js";
import store from "../store";
import { mask } from "vue-the-mask";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  name: "UserProfile",
  mixins: [checkPermission],
  directives: {
    mask
  },
  data: () => ({
    mask: "##########",

    flagLoadingSend: false,
    userList: [],
    url: enurl.apiUrl,
    username: store.state.username,
    password: store.state.username,
    usertype: store.state.usertype,
    firstName: store.state.firstName,
    lastName: store.state.lastName,
    email: store.state.email,
    mobile: store.state.mobile,
    EmployeeID: store.state.employeeID,
    imageUrl: store.state.pathImage,
    imageName: "",
    imageFile: "",
    today: null,
    rules: {
      required: value => !!value || "Required"
    },
    flagAlertSend: false,
    mesAlertSend: "",
    typeAlertSend: "success",
  }),
  methods: {
    ClearDataAlert(){
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    PhoneNumberValidation(phno) {
      let phoneno = /^\d{10}$/;
      return phoneno.test(phno) || "Please enter correct Phone Number";
    },

    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email) || "Please enter correct Email";
    },
    GetDate() {
      let today1 = new Date();
      let dd = today1.getDate();
      let mm = today1.getMonth() + 1; //January is 0!

      let yyyy = today1.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      this.today = dd + "/" + mm + "/" + yyyy;
    },
    pickFile() {
      this.$refs.image.click();
    },

    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.imageUrl = fr.result.split(",")[1];
          this.imageFile = files[0]; // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    GetUserManagement() {
      let self = this;
      self.flagLoadingSend = true;
      axios
        .post(`${self.url}EmployeeCom/GetAllEmployeeCompanies`)
        .then(function(response) {
          self.userList = response.data.data;
          self.flagLoadingSend = false;
        })
        .catch(function(error) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },
    UpdateUser() {
      let self = this;

      let emailvali = self.validateEmail(self.email);
      let phonevali = self.PhoneNumberValidation(self.mobile);
      if (
        emailvali == true &&
        phonevali == true &&
        self.firstName != "" &&
        self.lastName != ""
      ) {
        self.flagLoadingSend = true;
        let tempUser = {
          employeeCOMId: self.EmployeeID,
          username: self.username,
          password: self.username,
          agentID: 0,
          roleId: 0,
          userType: "string",
          idcard: 0,
          prefixId: 0,
          firstName: self.firstName,
          lastName: self.lastName,
          mobile: self.mobile,
          email: self.email,
          pathImage: self.imageUrl,
          updatedByUserId: null,
          salaryid: 0,
          startWorkDate: null,
          supervisorID: 0,
          createBy: null,
          updateBy: null
        };
        axios
          .post(`${self.url}EmployeeCom/UpdateEmployeeCompany`, tempUser)
          .then(function(response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = response.data.message;
              self.flagAlertSend = true;
              sessionStorage.setItem("firstName", self.firstName);
              sessionStorage.setItem("lastName", self.lastName);
              sessionStorage.setItem("email", self.email);
              sessionStorage.setItem("mobile", self.mobile);
              sessionStorage.setItem("pathImage", self.imageUrl);
              // sessionStorage.setItem("employeeID", self.employeeID);
              self.flagLoadingSend = false;
              window.location.reload();
            }
          })
          .catch(function(error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
          });
      } else {
        if (emailvali != true || phonevali != true) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = "Please Enter Correct Format!";
          self.flagAlertSend = true;
        } else if (self.firstName == "" || self.lastName == "") {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = "Please Fill All The Fields!";
          self.flagAlertSend = true;
        }
        self.flagLoadingSend = false;
      }
    }
  },
  mounted() {
    this.GetDate();
  }
};
</script>
<style scoped>
.card {
  border-radius: 10px !important;
  margin-bottom: 2rem;
  height: 90vh;
  overflow: auto;
  margin: 0;
  padding: 0;
}
.flex {
  margin-left: 2rem;
  margin-right: 2rem;
}
.circular--portrait {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
}

.circular--portrait img {
  width: 100%;
  height: auto;
}
</style>
/* eslint-disable no-await-in-loop */
import * as PDFJS from 'pdfjs-dist';

class Pdf2Image {
  /**
   * @param {string} pdfUrl
   * @return {Pdf2Image} 
   */
  static async open(pdfUrl) {
    // PDFJS.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js';
    const pdfDoc = await PDFJS.getDocument({ url: pdfUrl }).promise;
    return new Pdf2Image(pdfDoc);
  }

  /**
   * @param {PDFJS.PDFDocumentProxy} pdfDoc
   */
  constructor(pdfDoc) {
    this.pdfDoc = pdfDoc;
  }

  /**
   * @return {Number}
   */
  numPages() {
    return this.pdfDoc.numPages;
  }

  /**
   * @param {Number} pageNo
   * @param {Object} option
   * @return {String}
   */
  async getImageDataUrl(pageNo, option) {
    const page = await this.pdfDoc.getPage(pageNo);
    const scale = Pdf2Image.calcScale(page, option);
    const viewport = page.getViewport({ scale });
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    canvasContext.height = viewport.height;
    canvasContext.width = viewport.width;

    const renderContext = {
      canvasContext,
      viewport,
    };
    await page.render(renderContext).promise;
    switch (option.image) {
      case 'jpeg':
        return canvas.toDataURL('image/jpeg');
      case 'webp':
        return canvas.toDataURL('image/webp');
      default:
        return canvas.toDataURL();
    }
  }

  /**
   *
   * @param {PDFJS.PDFPageProxy} page
   * @param {Object} option
   * @return {Number} 
   */
  static calcScale(page, option) {
    if (option.scale !== undefined) {
      return option.scale;
    }
    if (option.width === undefined || option.height === undefined) {
      return 1.0;
    }
    const viewport = page.getViewport({ scale: 1.0 });
    return Math.min(option.width / viewport.width, option.height / viewport.height);
  }

  /**
   * @param {Object} option
   * @return {String[]} 
   */
   async getAllImageDataUrl(option) {
    const pages = [];
    var test = false;
    const numPages = this.numPages();
    for (let i = 1; i <= numPages; i += 1) {
      const img = await this.getImageDataUrl(i, option);
      const imag3 = new Image();
      imag3.src = img;
      imag3.onload = function () {
        if((imag3.width == '2083' && imag3.height == '2946') || (imag3.width == '2142' && imag3.height == '2772'))
        {
          pages.push({
            page: i,
            data: imag3.src,
            width: imag3.width,
            height: imag3.height,
          });
        }
        else
        {
          test = true;
          return [];
        }
      };
      if(test)
      {
        break;
      }
      
      //console.log(pages)
      //pages.push(img);
    }
    return pages;
  }
}

export default Pdf2Image;

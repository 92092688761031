<template>
    <div>
        <v-overlay :value="flagLoading" style="z-index: 999 !important">
            <v-card color="#1976D2" dark width="300">
                <v-card-text class="white--text">
                {{$t("loadingPleaseWait")}}
                <v-progress-linear
                    indeterminate
                    color="#FAA91B"
                    class="mb-0"
                ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-overlay>
    </div>
</template>

<script>
export default {
    computed: {
        // dialog: {
        // get() {
        //     return this.flagLoading;
        // },
        // set() {
        //     // this.$emit("closeSign");
        //     this.flagLoading;
        // },
        // },
    },
    props: {
        flagLoading: {
            type: Boolean,
            default: false,
        },
    },
    data:() => ( {
    }),
    mounted: function () {
    },
    watch: {
    },
    methods:{
    },
}
</script>
<template>
  <div>
    <v-container fluid>
      <v-card class="card">
        <v-toolbar color="primary" dark>
          <v-toolbar-title class="title">{{$t('menumanagement')}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-title class="title">{{today}}</v-toolbar-title>
        </v-toolbar>
        <v-flex class="flex">
          <v-row style="margin-top:2rem;">
            <v-col cols="3" class="mt-3">
              <v-autocomplete
                v-model="value"
                :items="menusDDL"
                dense
                :label="$t('menuname')"
                item-text="MenuName"
                item-value="MenuName"
                clearable
              ></v-autocomplete>
            </v-col>

            <v-col cols="3">
              <v-btn class="primary white--text mt-3" @click="Search">{{$t('search')}}</v-btn>
            </v-col>
          </v-row>
          <v-data-table :items="menus">
            <template v-slot:header="{ props: { headers } }">
              <thead>
                <tr>
                  <th>{{$t('menuname')}}</th>
                  <th>{{$t('addgroupinmenu')}}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.MenuName}}</td>
                <td>
                  <v-btn icon @click="Edit(item)" :disabled="checkPermissionTwo(11,12)">
                    <img :src="images.edit" />
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
        <br />
        <br />
        <br />
      </v-card>
    </v-container>

    <!-- Add User Group In Menu -->
    <!-- Search which user groups have which menus by check menu item -->
    <v-dialog v-model="EditdialogUser" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('menumanagement')}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div v-if="tempID==0" v-show="true">
              <v-col cols="12" sm="6" md="2">
                <v-checkbox
                  v-model="checkbox1"
                  color="#1976D2"
                  label="All"
                  value="0"
                  @click="SearchMenuInDDL"
                ></v-checkbox>
              </v-col>
            </div>

            <div v-if="tempID==0" v-show="true">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    :items="menus[tempID].Submenus"
                    label="Menu Name*"
                    item-value="id"
                    item-text="SubmenuName"
                    :disabled="check()"
                    clearable
                    v-model="subMenuID"
                    @blur="SearchMenuInDDL"
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="groupList"
                    :items="GroupList"
                    label="User Group*"
                    item-text="groupName"
                    item-value="id"
                    clearable
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="tempID==1" v-show="true">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="MenuID1"
                    :items="menus[tempID]"
                    label="Menu Name*"
                    item-value="id"
                    item-text="MenuName"
                    clearable
                    multiple
                    @blur="SearchMenuInDDL"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="groupList2"
                    :items="GroupList"
                    label="User Group*"
                    item-text="groupName"
                    item-value="id"
                    clearable
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="tempID==2" v-show="true">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="MenuID2"
                    :items="menus[tempID]"
                    label="Menu Name*"
                    item-value="id"
                    item-text="MenuName"
                    clearable
                    @blur="SearchMenuInDDL"
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="groupList3"
                    :items="GroupList"
                    label="User Group*"
                    item-text="groupName"
                    item-value="id"
                    clearable
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
            <div v-else v-show="true">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="MenuID3"
                    :items="menus[tempID]"
                    label="Menu Name*"
                    item-value="id"
                    item-text="MenuName"
                    clearable
                    @blur="SearchMenuInDDL"
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="groupList4"
                    :items="GroupList"
                    label="User Group*"
                    item-text="groupName"
                    item-value="id"
                    clearable
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="Refresh">Close</v-btn>
          <div v-if="tempID==0" v-show="true">
            <v-btn color="blue darken-1" text @click="AddGroupInMenu">Add</v-btn>
          </div>
          <div v-else-if="tempID==1" v-show="true">
            <v-btn color="blue darken-1" text @click="AddGroupInMenuManagement">Add</v-btn>
          </div>
          <div v-else-if="tempID==2" v-show="true">
            <v-btn color="blue darken-1" text @click="AddGroupInCalendar">Add</v-btn>
          </div>
          <div v-else v-show="true">
            <v-btn color="blue darken-1" text @click="AddGroupInLog">Add</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import checkPermissionTwo from "@/api/function.js";
import LogTrace from "@/api/function.js";
export default {
  name: "MenuManagement",
  mixins: [checkPermissionTwo,LogTrace],
  data: () => ({
    url: enurl.apiUrl,
    checkbox1: false,
    EditdialogUser: false,
    tempID: 0,
    MenuID1: null,
    MenuID2: null,
    MenuID3: null,
    menuID: null,
    subMenuID: null,
    today: null,
    value: null,
    groupList: null,
    groupList2: null,
    groupList3: null,
    groupList4: null,
    GroupList: [],
    menusDDL: [
      { MenuName: "User Management" },
      { MenuName: "Menu Management" },
      {
        MenuName: "Calendar"
      }
    ],
    menus: [
      {
        id: 0,
        MenuName: "User Management",
        Submenus: [
          { id: 11, SubmenuName: "Users" },
          { id: 22, SubmenuName: "User Group" },
          { id: 33, SubmenuName: "Permission Management" }
        ]
      },
      {
        id: 1,
        MenuName: "Menu Management"
      },
      {
        id: 2,
        MenuName: "Calendar"
      },
      {
        id: 3,
        MenuName: "Log"
      }
    ],
    images: {
      edit: require("@/assets/edit1.png")
    }
  }),

  methods: {
    Refresh() {
      this.EditdialogUser = false;
      window.location.reload();
    },
    Search() {
      let self = this;
      self.dialog = true;
      if (self.value != null) {
        let List = self.menusDDL;
        self.menus = [];
        for (let i = 0; i < List.length; i++) {
          if (List[i].MenuName == self.value) {
            self.menus.push(List[i]);
          }
        }
        self.dialog = false;
      } else {
        window.location.reload();
        alert("Please enter Menu Name!!!");
        self.dialog = false;
      }
    },
    SearchMenuInDDL() {
      var self = this;
      let tempMenuID = "";
      var tempsubmenu = self.subMenuID + "";
      if (self.menuID == 0) {
        tempMenuID = self.checkbox1 ? self.checkbox1 : tempsubmenu;
        self.dialog = true;
      } else if (self.MenuID1 == 1) {
        tempMenuID = self.MenuID1.toString();
        self.dialog = true;
      } else if (self.MenuID2 == 2) {
        tempMenuID = self.MenuID2.toString();
        self.dialog = true;
      } else if (self.MenuID3 == 3) {
        tempMenuID = self.MenuID3.toString();
        self.dialog = true;
      }
      var tempRole = {
        menu_id: tempMenuID
      };
      self.dialog = true;
      axios
        .post(`${self.url}Menu/GetMenuByID`, tempRole)
        .then(function(response) {
          if (response.data.status == 0) {
            self.groupList = response.data.data;
            self.groupList2 = response.data.data;
            self.groupList3 = response.data.data;
            self.groupList4= response.data.data;

            self.groupList = self.groupList.map(x => parseInt(x.id));
            self.groupList2 = self.groupList2.map(x => parseInt(x.id));
            self.groupList3 = self.groupList3.map(x => parseInt(x.id));
            self.groupList4 = self.groupList4.map(x => parseInt(x.id));
            if (self.groupList.length == []) {
              alert("This menu had no chosen group");
              self.dialog = false;
            } else if (self.groupList2.length == []) {
              alert("This menu had no chosen group");
              self.dialog = false;
            } else if (self.groupList3.length == []) {
              alert("This menu had no chosen group");
              self.dialog = false;
            }
             else if (self.groupList4.length == []) {
              alert("This menu had no chosen group");
              self.dialog = false;
            }

            self.dialog = false;
          }
        })
        .catch(function(error) {
          alert(error);
          self.dialog = false;
        });
    },
    AddGroupInMenu() {
      try{
      let self = this;
      self.dialog = true;
      let tempsubmenu = self.subMenuID + "";
      let templist = self.groupList + "";
      if (tempsubmenu != "null" || templist != "null") {
        let tempRole = {
          menu_id: self.checkbox1 ? self.checkbox1 : tempsubmenu,
          group_id: templist == "" ? null : templist
        };
        axios
          .post(`${self.url}Menu/UpdateMenuManagement`, tempRole)
          .then(function(response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.Editdialog = false;
              self.groupList2 = null;
              self.dialog = false;
              self.EditdialogUser = false;
              window.location.reload();
            }
          })
          .catch(function(error) {
            alert(error);
            self.dialog = false;
            self.EditdialogUser = false;
          });
      } else {
        alert("Please select all fields!!");
        self.dialog = false;
      }
      }catch(error){
        this.LogTrace(error,"AddGroupInMenu")
      }
    },

    AddGroupInMenuManagement() {
      try{
      let self = this;
      self.dialog = true;
      let tempmenu = self.MenuID1 + "";
      let templist = self.groupList2 + "";
      if (tempmenu != "null" || templist != "null") {
        let tempRole = {
          menu_id: tempmenu,
          group_id: templist == "" ? null : templist
        };

        axios
          .post(`${self.url}Menu/UpdateMenuManagement`, tempRole)
          .then(function(response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.Editdialog = false;
              self.groupList2 = null;
              self.dialog = false;
              self.EditdialogUser = false;
              window.location.reload();
            }
          })
          .catch(function(error) {
            alert(error);
            self.dialog = false;
            self.EditdialogMenu = false;
          });
      } else {
        alert("Please fill all fields!!!");
        self.dialog = false;
      }
      }catch(error){
        this.LogTrace(error,"AddGroupInMenuManagement")
      }
    },
    AddGroupInCalendar() {
    try{
      let self = this;
      self.dialog = true;
      let tempmenu = self.MenuID2 + "";
      let templist = self.groupList3 + "";
      if (tempmenu != "null" || templist != "null") {
        let tempRole = {
          menu_id: tempmenu,
          group_id: templist == "" ? null : templist
        };
        axios
          .post(`${self.url}Menu/UpdateMenuManagement`, tempRole)
          .then(function(response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.EditdialogCalendar = false;
              self.groupList3 = null;
              self.dialog = false;
              window.location.reload();
            }
          })
          .catch(function(error) {
            alert(error);
            self.dialog = false;
            self.EditdialogUser = false;
          });
      } else {
        alert("Please fill all fields!!");
        self.dialog = false;
      }
    }catch(error){
      this.LogTrace(error,"AddGroupInMenuManagement")
    }
    },
    AddGroupInLog() {
      try{
      let self = this;
      self.dialog = true;
      let tempmenu = self.MenuID3 + "";
      let templist = self.groupList4 + "";
      if (tempmenu != "null" || templist != "null") {
        let tempRole = {
          menu_id: tempmenu,
          group_id: templist == "" ? null : templist
        };
        axios
          .post(`${self.url}Menu/UpdateMenuManagement`, tempRole)
          .then(function(response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.EditdialogCalendar = false;
              self.groupList4 = null;
              self.dialog = false;
              window.location.reload();
            }
          })
          .catch(function(error) {
            alert(error);
            self.dialog = false;
            self.EditdialogUser = false;
          });
      } else {
        alert("Please fill all fields!!");
        self.dialog = false;
      }
      }catch(error){
        this.LogTrace(error,"AddGroupInLog")
      }
    },
    GetGroupManagement() {
      let self = this;
      self.dialog = true;
      axios
        .post(`${self.url}Group/GetGroupAll`)
        .then(function(response) {
          self.GroupList = response.data.data;
          self.dialog = false;
        })
        .catch(function(error) {
          alert(error);
        });
    },
    check() {
      let self = this;
      if (self.checkbox1 == false || self.checkbox1 == null) {
        return false;
      } else if (self.checkbox1 == "0") {
        return true;
      }
    },
    Edit(item) {
      let self = this;
      self.tempID = item.id;
      self.menuID = item.id;
      if (self.tempID == 0) {
        self.EditdialogUser = true;
      } else if (self.tempID == 1) {
        self.EditdialogUser = true;
      } else {
        self.EditdialogUser = true;
      }
    },
    GetDate() {
      let today1 = new Date();
      let dd = today1.getDate();
      let mm = today1.getMonth() + 1; //January is 0!

      let yyyy = today1.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      this.today = dd + "/" + mm + "/" + yyyy;
    }
  },
  mounted() {
    this.GetDate();
    this.GetGroupManagement();
  }
};
</script>
<style scoped>
.card {
  border-radius: 10px !important;
  margin-bottom: 2rem;
  height: 90vh;
  overflow: auto;
  margin: 0;
  padding: 0;
}

.flex {
  margin-left: 2rem;
  margin-right: 2rem;
}
</style>
<template>
  <div>
    <v-card class="card-preview">
      <div class="pt-8" style="
          text-color: #444444;
          font-size: 18px;
          margin-left: 1rem;
          margin-right: 1rem;
          color: #444444;
        ">{{$t("certificateManagement")}}</div>
      <div class="mt-6 mb-5" style="margin-bottom: 5rem">
        <div class="pl-5">
          <v-tabs class="mt-2" grey--text slider-color="#1976d2" show-arrows>
            <v-tab class="text-capitalize tabone" v-show="flag.cert_view" @click="CertView(flagCertOrGroup)">{{$t("certificate")}}
            </v-tab>
            <v-tab class="text-capitalize tabone" v-show="flag.group_cert_view" @click="CertView(flagCertOrGroup)">
              {{$t("certificateGroup")}}</v-tab>
            <v-tabs-slider color="white"></v-tabs-slider>

            <v-tab-item>
              <div style="margin-right: 1rem">
                
                <!-- New Certificate Top Buttons -->
                <v-row>
                  <v-col xl="2" sm="3" lg="2" cols="6" class="add-certificate-max-width">
                    <v-btn 
                      style="background-color: #126496; color: #ffffff; text-transform: none !important" 
                      width="150"
                      class="add-certificate-btn-width"
                      @click="AddCert()" 
                      :disabled="!flag_add">{{$t("addCertificate")}}</v-btn>
                  </v-col>
                  <v-col xl="2" sm="3" cols="6" class="responsive">
                    <v-btn 
                      style="background-color: #126496; color: #ffffff; text-transform: none !important"
                      width="150" 
                      class="request-root-ca-btn-width"
                      @click="RootCA()">{{$t("requestRootCA")}}</v-btn>
                  </v-col>
                  
                  <v-col xl="4" sm="4" md="4" lg="4" cols="9">
                    <v-text-field 
                      dense 
                      v-model="searchCert" 
                      outlined 
                      :placeholder="$t('search')" 
                      placeholder-color="#ACACAC" 
                      color="#126496"></v-text-field>
                  </v-col>
                  <v-col xl="1" sm="2" md="1" lg="1" cols="3">
                    <v-btn 
                      class="search-btn-width" 
                      @click="SearchCertName()">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- Old Top Buttons -->
                <!-- <v-row>
                  <v-col cols="3" xs="6" md="2">
                    <v-btn style="
                        background-color: #126496;
                        color: #ffffff;
                        text-transform: none !important;
                      " @click="AddCert()" :disabled="!flag_add" absolute>Add Certificate</v-btn>
                  </v-col>
                  <v-col cols="5" xs="6" md="5">
                    <v-text-field dense v-model="searchCert" outlined placeholder="Search" placeholder-color="#ACACAC"
                      color="#126496" />
                  </v-col>
                  <v-col cols="1" xs="6" md="1">
                    <v-btn @click="SearchCertName()">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col> -->
                  <!-- Already Commented -->
                  <!-- <v-spacer></v-spacer> -->
                  <!-- Not Already Commented -->
                  <!-- <v-col cols="3" xs="6" md="3">
                    <v-btn style="
                        background-color: #126496;
                        color: #ffffff;
                        margin-right: 1rem;
                        text-transform: none !important;
                      " @click="RootCA()" absolute right>Request Root CA</v-btn>
                  </v-col>
                </v-row> -->
                <v-row>
                  <div style="margin-right: 1rem">
                    <v-col cols="12">
                      <v-radio-group v-model="radioGroup" row>
                        <v-radio :key="1" :label="$t('all')" :value="1"></v-radio>
                        <v-radio :key="2" :label="$t('groupCertificate')" :value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </div>
                </v-row>
                <v-row v-show="radioGroup == 1">
                  <v-col cols="12" sm="6" md="2" v-for="(item, i) in items" :key="i">
                    <v-card white outlined style="border: 1px solid #126496; ">
                      <div style="
                          margin-left: 0.7rem;
                          margin-right: 0.7rem;
                          margin-top: 1.2rem;
                          margin-bottom: 1.2rem;
                        ">
                        <p style="
                            color: #126496;
                            font-size: 16px;
                            font-weight: Regular;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          ">{{ item.certName }}</p>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="#126496" style="margin-left:-13px!important" text @click="editItem(item)" v-show="flag_edit">
                            <img width="16"  src="@/assets/edit.svg" />
                            <p class="mt-4 text-capitalize ml-2">{{$t("edit")}}</p>
                          </v-btn>
                          <v-btn color="#FF4839"  class="btnDelete" text @click="deleteItem(item)"  v-show="flag_delete">
                            <img width="16"  src="@/assets/delete.png" />
                            <p class="mt-4 text-capitalize">{{$t("delete")}}</p>
                          </v-btn>
                        </v-card-actions>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
                <div v-show="radioGroup == 2" v-for="(groupCert_item, index) in items_GroupCertNameAndCert"
                  :key="index">
                  <v-row>
                    <v-col style="margin-top:15px;" cols="12">
                      <span >{{ groupCert_item.group_CertName }}</span>
                      <v-row>
                        <v-col cols="12" sm="6" md="3" v-for="(item, i) in groupCert_item.certListRespond" :key="i">
                          <v-card white outlined style="border: 1px solid #126496">
                            <div style="
                                margin-left: 1rem;
                                margin-right: 1rem;
                                margin-top: 0.8rem;
                                margin-bottom: 0.5rem;
                              ">
                              <p style="
                                  color: #126496;
                                  font-size: 16px;
                                  font-weight: Regular;
                                ">{{ item.certName }}</p>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="#126496" text @click="editItem(item)" v-show="flag_edit">
                                  <img width="16" src="@/assets/edit.svg" />
                                  <p class="mt-4 text-capitalize ml-2">{{$t("edit")}}</p>
                                </v-btn>
                                <v-btn color="#FF4839" text @click="deleteItem(item)" v-show="flag_delete">
                                  <img width="16" src="@/assets/delete.png" />
                                  <p class="mt-4 text-capitalize">{{$t("delete")}}</p>
                                </v-btn>
                              </v-card-actions>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div style="height: 1vh"></div>
              <div v-show="radioGroup == 1">
                <v-row justify="end" class="ml-5 mt-5 mr-5">
                  <v-col cols="12" md="4">
                    <v-pagination v-model="PageCertManage" :length="PageCountCertManage" color="#126496"
                      @input="ChangePageCertManage(PageCertManage)" :total-visible="7"></v-pagination>
                  </v-col>
                  <v-col cols="12" md="2" class="mt-1">
                    <v-select dense style="width: 120px; float: right" solo label="10/page"
                      v-model="itemPerPageCertManage" @input="ChangePerPageCertManage(itemPerPageCertManage)"
                      :items="itemsPerpageCertManage"></v-select>
                  </v-col>
                </v-row>
              </div>
              <div v-show="radioGroup == 2">
                <v-row justify="end" class="ml-5 mt-5 mr-5">
                  <v-col cols="12" md="4">
                    <v-pagination v-model="PageCertManage" :length="PageCountCertManage" color="#126496"
                      @input="ChangePageCertManageforGroup(PageCertManage)" :total-visible="7"></v-pagination>
                  </v-col>
                  <v-col cols="12" md="2" class="mt-1">
                    <v-select dense style="width: 120px; float: right" solo label="10/page"
                      v-model="itemPerPageCertManage" @input="
                        ChangePerPageCertManageforGroup(itemPerPageCertManage)
                      " :items="itemsPerpageCertManage"></v-select>
                  </v-col>
                </v-row>
              </div>
            </v-tab-item>

            <v-tab-item>
              <div style="margin-right: 1rem">
                
                <!-- New Certificate Group Top Buttons -->
                <v-row>
                  <v-col class="d-flex">
                    <v-btn 
                    class="flex-sm-grow-0 flex-grow-1"
                      style="background-color: #126496; color: #ffffff; text-transform: none !important" 
                      @click="AddGroupCert()" 
                      :disabled="!flag_add">{{$t("addCertificateGroup")}} </v-btn>
                  </v-col>
                  <v-col xl="3" sm="4" cols="9">
                    <v-text-field 
                      dense 
                      v-model="searchGroupCert" 
                      outlined 
                      :placeholder="$t('search')" 
                      placeholder-color="#ACACAC" 
                      color="#126496"></v-text-field>
                  </v-col>
                  <v-col xl="1" sm="2" cols="3">
                    <v-btn 
                      class="search-btn-width" 
                      @click="SearchGroupCertName()">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <!-- Old Certificate Group Top Buttons -->
                <!-- <v-row>
                  <v-col cols="5" xs="6" md="5">
                    <v-text-field dense v-model="searchGroupCert" outlined placeholder="Search"
                      placeholder-color="#ACACAC" color="#126496" />
                  </v-col>
                  <v-col cols="1" xs="6" md="1">
                    <v-btn @click="SearchGroupCertName()">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="3" xs="6" md="3">
                    <v-spacer></v-spacer>
                  </v-col> -->
                  <!-- Already Commented -->
                  <!-- <v-spacer></v-spacer> -->
                  <!-- Not Already Commented -->
                  <!-- <v-col cols="3" xs="6" md="3">
                    <v-btn style="
                        background-color: #126496;
                        color: #ffffff;
                        margin-right: 1rem;
                        text-transform: none !important;
                      " @click="AddGroupCert()" :disabled="!flag_add" absolute right>Add Group Certificate</v-btn>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col cols="12" sm="6" md="3" v-for="(item, i) in items_GroupCert" :key="i">
                    <v-card white outlined style="border: 1px solid #126496">
                      <div style="
                          margin-left: 1rem;
                          margin-right: 1rem;
                          margin-top: 0.8rem;
                          margin-bottom: 0.5rem;
                        ">
                        <p style="
                            color: #126496;
                            font-size: 16px;
                            font-weight: Regular;
                          ">{{ item.group_CertName }}</p>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="#126496" text @click="editItem_GroupCert(item)" v-show="flag_edit">
                            <img width="16" src="@/assets/edit.svg" />
                            <p class="mt-4 text-capitalize ml-2">{{$t("edit")}}</p>
                          </v-btn>
                          <v-btn color="#FF4839" text @click="deleteItem_GroupCert(item)" v-show="flag_delete">
                            <img width="16" src="@/assets/delete.png" />
                            <p class="mt-4 text-capitalize">{{$t("delete")}}</p>
                          </v-btn>
                        </v-card-actions>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div style="height: 1vh"></div>
              <div>
                <v-row justify="end" class="ml-5 mt-5 mr-5">
                  <v-col cols="12" md="3">
                    <v-pagination v-model="PageGroupCert" :length="PageCountGroupCert"
                      @input="ChangePageGroupCert(PageGroupCert)" color="#126496" :total-visible="6"></v-pagination>
                  </v-col>
                  <v-col cols="12" md="2" class="mt-1">
                    <v-select dense style="width: 120px; float: right" solo label="10/page"
                      v-model="itemPerPageGroupCert" @input="ChangePerPageGroupCert(itemPerPageGroupCert)"
                      :items="itemsPerpage"></v-select>
                  </v-col>
                </v-row>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
      </div>
    </v-card>

    <v-row justify="center">
      <v-dialog persistent v-model="dialog" max-width="1006">
        <v-card>
          <div class="pt-5" style="
              text-color: #444444;
              font-size: 18px;
              font-weight: medium;
              margin-left: 24px;
              margin-right: 1rem;
              color: #444444;
            ">{{ formTitle }}</div>
          <v-card-text>
            <v-form ref="form" v-model="validateModel">
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <div style="color: #444444; font-weight: regular">
                    <p>{{$t("certificateImage")}}</p>
                  </div>
                  <div style="text-align: center; border: 2px dotted #d3d3d3">
                    <div class="dvborder" @dragover.prevent @drop.prevent @click="$refs.fileimage.click()">
                      <div class="container centertext" @dragleave="fileDragOut" @dragover="fileDragIn"
                        @drop="handleFileDrop" v-on:drop="fileDragOut">
                        <!-- <v-icon x-large>mdi-cloud-upload-outline</v-icon> -->
                        <img v-if="!imageUrl" style="padding-top: 1rem" src="@/assets/Mask Group 1@2x.png" />
                        <img v-else :src="imageUrl" height="191px" width="200px" style="
                            margin-left: -0.7rem;
                            margin-right: -0.7rem;
                            margin-top: -0.5rem;
                          " />
                        <div class="file-wrapper" style="padding-top: 2rem">
                          <label for="file-upload" class="custom-file-upload">
                            <!-- <v-icon>mdi-magnify</v-icon> -->
                            <span style="font-size: 14px; color: #444444">{{$t("clickOrDragFileToThisAreaToUpload")}}</span>
                          </label>
                          <input accept=".png, .jpg" type="file" ref="fileimage"
                            @change="handleFileInput" />
                          <!-- <input type="file" name="file-input"  /> -->
                        </div>
                      </div>
                    </div>
                    <!-- <div
                    @dragleave="fileDragOut"
                    @dragover="fileDragIn"
                    @drop="handleFileDrop"
                    v-on:drop="fileDragOut"
                    v-if="seen"
                    class="upload_img_file"
                    @click="onPickFile"
                    @change="seen = !seen"
                    style="
                      height: 191px;
                      max-width: 191px;
                      border: 3px solid #d3d3d3;
                      border-style: dotted;
                      background: #fafafa;
                    "
                  >
                    <p class="text-capitalize" style="font-size: 7px; color: #444444">
                      <img src="@/assets/Mask Group 1@2x.png" />
                      <br />Click or drag file to this area to upload
                      <input
                        type="file"
                        accept="image/*"
                        style="display: none"
                        ref="fileInput"
                        @change="onFilePicked"
                      />
                    </p>
                  </div>

                  <v-img
                    v-else
                    @click="seen = !seen"
                    :src="imageUrl"
                    class="preview_img_file"
                    style="
                      height: 191px;
                      max-width: 191px;
                      border: 3px solid #d3d3d3;
                      border-style: dotted;
                      background: #fafafa;
                    "
                    ></v-img>-->
                  </div>
                 
                  <p style="color: red; font-size: 12px" v-if="certProfileRules == true">{{$t("certProfileIsRequired!")}}</p>
                </v-col>
                <v-col cols="12" md="9" sm="6">
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="3">
                      <div class="mt-2 txtStyle" style="margin-right: 20px">
                        <p>{{$t("certificateFile")}}*</p>
                      </div>
                    </v-col>
                    <!-- New Certificate File Choose -->
                    <v-col cols="12" sm="6" md="9">
                      <v-row
                        style="
                          border: 1px solid;
                          margin-left: 0.05rem;
                          margin-right: 0.09rem;
                          border-radius: 4px;
                          border-color: #ebebf2;
                        "
                      >
                        <v-col cols="12" md="4">
                          <v-btn
                            @click.native="openFileDialog()"
                            class="text-capitalize"
                            style="
                              background-color: #126496;
                              color: #ffffff;
                              height: 32px;
                              margin-right: 1rem;
                              font-size: 14px;
                            "
                          >{{$t("chooseFile")}}</v-btn>
                        </v-col>
                        <v-col  cols="12" md="8" class="filecss pa-0">{{ fileName }}</v-col>
                        <input
                          id="testFile"
                          type="file"
                          style="display: none"
                          ref="file"
                          accept=".pfx"
                          @change="onFileChange"
                        />
                      </v-row>
                      <p
                      class="mt-3"
                        style="
                          color: red;
                          font-size: 12px;
                          margin-left: 10px;
                          margin-top: -2px;
                          margin-bottom: -2rem;
                        "
                        v-if="certFileRules == true"
                      >{{$t("certFileIsRequired!")}}</p>
                    </v-col>
                    <!-- Old Certificate File Choose -->
                    <!-- <v-col cols="12" sm="6" md="9">
                      <v-row style="
                          border: 1px solid;
                          height: 100%;
                          margin-left: 0.05rem;
                          margin-right: 0.09rem;
                          border-radius: 4px;
                          border-color: #ebebf2;
                        ">
                        <v-col cols="4" md="4" style="margin-top: 0.67rem">
                          <v-btn @click.native="openFileDialog()" class="text-capitalize" style="
                              background-color: #126496;
                              color: #ffffff;
                              height: 32px;
                              margin-top: -30px;
                              margin-right: 1rem;
                              font-size: 14px;
                            ">Choose file</v-btn>
                        </v-col>
                        <v-col class="filecss">{{ fileName }}</v-col>
                        <input id="testFile" type="file" style="display: none" ref="file" accept=".pfx"
                          @change="onFileChange" />
                      </v-row>
                      <p style="
                          color: red;
                          font-size: 12px;
                          margin-left: 10px;
                          margin-top: -2px;
                          margin-bottom: -2rem;
                        " v-if="certFileRules == true">Cert file is required!</p>
                    </v-col> -->
                  </v-row>
                  <br>
                  <v-row no-gutters class="pp" style="margin-top: 1.2rem">
                    <v-col cols="12" sm="6" md="3">
                      <div class="mt-2 txtStyle" style="margin-right: 20px">
                        <p>{{$t("certificateName")}}*</p>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="9">
                      <v-text-field :rules="certNameRules" v-model="certName" :placeholder="$t('certificateName')" outlined
                        dense class="txtStyle" required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-show="Certtype == '1' || Certtype == '2'">
                    <v-col cols="12" sm="6" md="3">
                      <div class="mt-2 txtStyle" style="margin-right: 20px">
                        <p>{{$t("certType")}}*</p>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="9" style="margin-top: -10px">
                      <v-radio-group v-model="Certtype" row>
                        <v-radio :label="$t('personal')" value="1"></v-radio>
                        <v-radio :label="$t('enterprise')" value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="3">
                      <div class="mt-2 txtStyle" style="margin-right: 20px">
                        <p>{{$t("certReason")}}*</p>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="9">
                      <v-text-field v-model="cerReason" :rules="certReasonRules" class="txtStyle"
                        :placeholder="$t('certReason')" outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="3">
                      <div class="mt-2 txtStyle" style="margin-right: 20px">
                        <p>{{$t("certLocation")}}*</p>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="9">
                      <v-text-field class="txtStyle" v-model="cerLocation" outlined :rules="certLocationRules"
                        style="border-color: #ebebf2" dense required :placeholder="$t('certLocation')"></v-text-field>
                    </v-col>
                  </v-row>
                  <br>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="3"></v-col>
                    <v-col cols="12" sm="6" md="9">
                      <v-row>
                        <div style="margin-top: -25px">
                          <label class="container mt-4">
                            <input type="checkbox" v-model="ConfirmPasswordPage" />
                            <span class="checkmark"></span>
                            <p style="margin-top: -0.6rem; margin-left: 20px">{{$t("confirmPasswordPage")}}</p>
                          </label>
                        </div>
                        <div style="margin-top: -25px">
                          <label class="container mt-4">
                            <input type="checkbox" v-model="pswConfirm" />
                            <span class="checkmark"></span>
                            <p style="margin-top: -0.6rem; margin-left: 20px">{{$t("requirePassword")}}</p>
                          </label>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                  <div v-if="isEdit" v-show="!checkCA">
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                          <p>{{$t("password")}}</p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="5">
                        <v-text-field required :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show3 ? 'text' : 'password'" dense outlined @click:append="show3 = !show3"
                          v-model="cerPassword"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                          <p>{{$t("confirmPassword")}}</p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="5">
                        <v-text-field :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show2 ? 'text' : 'password'" dense outlined @click:append="show2 = !show2"
                          v-model="confirmPassword"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters v-show="checkCA">
                      <v-col cols="12" sm="6" md="3"></v-col>
                      <v-col cols="12" sm="6" md="5">
                        <a @click="ForgotPasswordCert()">{{$t("changePassword")}}</a>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                          <p>{{$t("password")}}</p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="5">
                        <v-text-field required :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show3 ? 'text' : 'password'" dense outlined @click:append="show3 = !show3"
                          v-model="cerPassword" :rules="passwordRules"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" md="3">
                        <div class="mt-2 txtStyle" style="margin-right: 20px">
                          <p>{{$t("confirmPassword")}}</p>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6" md="5">
                        <v-text-field :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show2 ? 'text' : 'password'" dense outlined @click:append="show2 = !show2"
                          v-model="confirmPassword" :rules="
                            confirmPasswordRules.concat(
                              passwordConfirmationRule
                            )
                          "></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <!-- <hr style="color: #ebebf2; margin-top: -10px" /> -->
              <v-divider style="color: #ebebf2"></v-divider>
              <div v-show="!flagUserAD">
                <p class="pt-5" style="color: #444444; font-size: 18px; font-weight: regular">{{$t("certificatePermission")}}</p>
                <v-row no-gutters>
                  <!-- <v-autocomplete
                v-model="model"
                :items="userlist"
                :loading="isLoading"
                :search-input.sync="search"
                color="#126496"
                hide-no-data
                hide-selected
                item-text="username"
                item-value="employeeID"
                prepend-inner-icon="mdi-magnify"
                return-object
                outlined
                cols="12"
                sm="6"
                md="3"
                dense
                  ></v-autocomplete>-->
                  <!-- <v-menu offset-y full-width>
                <template v-slot:activator="{ on }" class="v-menu-class">
                  <v-autocomplete
                    v-on="on"
                    v-model="search"
                    :loading="isLoading"
                    color="#126496"
                    hide-no-data
                    hide-selected
                    item-text="username"
                    item-value="employeeID"
                    prepend-inner-icon="mdi-magnify"
                    return-object
                    outlined
                    :append-icon-cb="helo"
                    dense
                  ></v-autocomplete>
                </template>
                <v-list flat dense 
                    :search="search">
                  <v-list-item
                    v-for="(item, i) in userlist"
                    :key="i"
                  >
                    <v-list-item-title
                      style="
                        color: #444444;
                        border-bottom: 1px solid #f1f1f1;
                        margin-left: 10px;
                        margin-right: 10px;
                      "
                    >
                      <v-row>
                        <v-col>{{ item.userID }}</v-col>
                        <v-col>{{ item.username }}</v-col>
                        <v-col class="text-end">
                          <v-btn
                            text
                            style="text-transform: none !important"
                            @click="AddEmp(item)"
                            >Add</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
                  </v-menu>-->
                  <v-autocomplete v-model="search" ref="refSearch" :items="userlist" hide-no-data
                    :search-input.sync="InputSearchAdd" outlined return-object color="#126496" item-text="username"
                    item-value="employeeID" prepend-inner-icon="mdi-magnify" cols="12" sm="6" dense md="3" attach>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item class="d-flex flex-row align-center py-0" @click="AddEmp(data.item)">
                          <!-- <v-row style="height: 30px;"> -->
                            <v-col >
                              <v-list-item-title v-html="data.item.employeeID"></v-list-item-title>
                            </v-col>
                            <v-col >
                              <v-list-item-title v-html="data.item.username"></v-list-item-title>
                            </v-col>
                            <v-col class="text-end py-0" style=" align-self: center">
                              <v-btn text style="
                                  background-color: #126496;
                                  color: #ffffff;
                                  text-transform: none !important; 
                                " @click="AddEmp(data.item)">{{$t("add")}}</v-btn>
                            </v-col> 
                          <!-- </v-row>  -->
                        </v-list-item>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-row>
                
                <!-- New Certificate Permission Data Table -->
                <v-data-table 
                  v-bind:headers="headers" 
                  :items="tempUserlist" 
                  hide-default-footer>
                  <!-- Items -->
                  <template v-slot:item.actions="{ item }">
                    <span>
                      <v-btn text @click="Delete(item)">
                        <img :src="images.delete" />
                        <span style="color: #ff4839" class="ml-1 text-capitalize">{{$t("delete")}}</span>
                      </v-btn>
                    </span>
                  </template>
                </v-data-table>
                <!-- Old Certificate Permission Data Table -->
                <!-- <v-data-table v-bind:headers="headers" :items="tempUserlist" hide-default-footer>
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td>{{ props.index + 1 }}</td>
                      <td>{{ props.item.employeeID }}</td>
                      <td>{{ props.item.username }}</td>
                      <td>{{ props.item.formatDate }}</td>
                      <td>
                        <v-layout justify-center>
                          <v-btn text @click="Delete(props.item)">
                            <img :src="images.delete" />
                            <span style="color: #ff4839" class="ml-1 text-capitalize">Delete</span>
                          </v-btn>
                        </v-layout>
                      </td>
                    </tr>
                  </template> -->
                  <!-- Already Commented -->
                  <!-- <template v-slot:item.employeeID="{ item }" class="text-end">{{ item.employeeID }}</template>
                  <template v-slot:item.createDate="{ item }" class="text-end">{{ item.formatDate }}</template>-->
                  <!-- <template v-slot:item.actions="{ item }" class="text-end">
                  <v-btn icon @click="Delete(item)">
                    <img :src="images.delete" />
                  </v-btn>
                  <span style="color: #ff4839" class="ml-1 text-capitalize">Delete</span>
                  </template>-->
                  <!-- Not Already Commented -->
                <!-- </v-data-table> -->
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions style="margin-right: 20px" class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="#444444" width="100px;" @click="Cancel()" text>
              <p class="mt-4 text-capitalize ml-2">{{$t("cancel")}}</p>
            </v-btn>
            <v-btn @click="Submit()" style="background-color: #126496; color: #ffffff; width: 100px" text>
              <!-- @click="Submit()" -->
              <p class="mt-4 text-capitalize">{{$t("submit")}}</p>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialog_GroupCert" max-width="1006">
        <v-card>
          <div class="pt-5" style="
              text-color: #444444;
              font-size: 18px;
              font-weight: medium;
              margin-left: 24px;
              margin-right: 1rem;
              color: #444444;
            ">{{ formTitleGroupCert }}</div>
          <v-card-text>
            <v-form ref="form" v-model="validateModel">
              <v-row>
                <v-col cols="12" md="9" sm="6">
                  <v-row no-gutters class="pp" style="margin-top: 1.2rem">
                    <v-col cols="12" sm="6" md="3">
                      <div class="mt-2 txtStyle" style="margin-right: 20px">
                        <p>{{$t("certificateGroupName")}}*</p>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="9">
                      <v-text-field :rules="groupCertNameRules" v-model="groupCertName"
                        :placeholder="$t('certificateGroupName')" outlined dense class="txtStyle" required></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- <hr style="color: #ebebf2; margin-top: -10px" /> -->
              <v-divider style="color: #ebebf2"></v-divider>
              <p class="pt-5" style="color: #444444; font-size: 18px; font-weight: regular">{{$t("certificate")}}</p>
              <v-row no-gutters>
                <v-autocomplete v-model="searchCertDDL" :items="ListDataCertDDL" ref="refSearchCertDDL"
                :search-input.sync="searchInput" @input="InputListDataCertDDL" attach color="#126496" item-text="certName" item-value="certID"
                  prepend-inner-icon="mdi-magnify" cols="12" sm="6" md="3" hide-no-data return-object outlined dense>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item class="d-flex flex-row align-center py-0">
                        <!-- <v-row style="height: 30px; margin-top: -0.5rem"> -->
                          <v-col>
                            <v-list-item-title v-html="data.item.certName"></v-list-item-title>
                          </v-col>
                          <v-col class="text-end py-0" style=" align-self: center">
                            <v-btn text style="
                                background-color: #126496;
                                color: #ffffff;
                                text-transform: none !important; 
                              " @click="AddCertDialog(data.item)">{{$t("add")}}</v-btn>
                          </v-col>
                        <!-- </v-row> -->
                    </v-list-item>
                    </template>
                  </template>
                </v-autocomplete>
              </v-row>
              
              <!-- New Certificate Group / Certificate Data Table -->
              <v-data-table :headers="headersDialogGroupCert" :items="tempCertlist" hide-default-footer :items-per-page="5">
                <template v-slot:item.no="{ item }">
                  <span>{{ (item.no + 1) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-layout justify-center>
                    <v-btn text @click="DeleteCertDialog(item)">
                      <img :src="images.delete" />
                      <span style="color: #ff4839" class="ml-1 text-capitalize">{{$t("delete")}}</span>
                    </v-btn>
                  </v-layout>
                </template>
              </v-data-table>
              <!-- Old Certificate Group / Certificate Data Table -->
              <!-- <v-data-table v-bind:headers="headersDialogGroupCert" :items="tempCertlist" hide-default-footer
                :items-per-page="5">
                <template slot="item" slot-scope="props">
                  <tr>
                    <td>{{ props.index + 1 }}</td>
                    <td>{{ props.item.certName }}</td>
                    <td>{{ props.item.formatDate }}</td>
                    <td>
                      <v-layout justify-center>
                        <v-btn text @click="DeleteCertDialog(props.item)">
                          <img :src="images.delete" />
                          <span style="color: #ff4839" class="ml-1 text-capitalize">Delete</span>
                        </v-btn>
                      </v-layout>
                    </td>
                  </tr>
                </template>
              </v-data-table> -->
              <br />

              <div v-show="!flagUserAD">
                <p class="pt-5" style="color: #444444; font-size: 18px; font-weight: regular">{{$t("certificateGroupPermission")}}</p>
                <v-row no-gutters>
                  <v-autocomplete :items="ListDataDropdownEmp" hide-no-data v-model="searchddlemp" ref="refSearchddlemp"
                  :search-input.sync="searchInputEmp" @input="InputSearchEmpCert" attach  outlined return-object color="#126496" item-text="username"
                    item-value="employeeID" prepend-inner-icon="mdi-magnify" cols="12" sm="6" dense md="3" >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item class="d-flex flex-row align-center py-0" >
                          <!-- <v-row style="height: 30px; margin-top: -0.5rem"> -->
                            <v-col>
                              <v-list-item-title v-html="data.item.employeeID"></v-list-item-title>
                            </v-col>
                            <v-col>
                              <v-list-item-title v-html="data.item.username"></v-list-item-title>
                            </v-col>
                            <v-col class="text-end py-0" style=" align-self: center">
                              <v-btn text style="
                                  background-color: #126496;
                                  color: #ffffff;
                                  text-transform: none !important;
                                " @click="AddCertDialogEmp(data.item)" >{{$t("add")}}</v-btn>
                            </v-col>
                          <!-- </v-row> -->
                        </v-list-item>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-row>
                
                <!-- New Certificate Group / Group Certificate Permission Data Table -->
                <v-data-table
                  :headers="headers"
                  :items="tempListDataDropdownEmp"
                  hide-default-footer
                  :items-per-page="5">
                  <template v-slot:item.no="{ item }">
                    <span>{{ (item.no + 1) }}</span>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-layout justify-center>
                      <v-btn text @click="DeleteCertDialogEmp(item)">
                        <img :src="images.delete" />
                        <span style="color: #ff4839" class="ml-1 text-capitalize">{{$t("delete")}}</span>
                      </v-btn>
                    </v-layout>
                  </template>  
                </v-data-table>
                <!-- Old Certificate Group / Group Certificate Permission Data Table -->
                <!-- <v-data-table v-bind:headers="headers" :items="tempListDataDropdownEmp" hide-default-footer
                  :items-per-page="5">
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td>{{ props.index + 1 }}</td>
                      <td>{{ props.item.employeeID }}</td>
                      <td>{{ props.item.username }}</td>
                      <td>{{ props.item.formatDate }}</td>
                      <td>
                        <v-layout justify-center>
                          <v-btn text @click="DeleteCertDialogEmp(props.item)">
                            <img :src="images.delete" />
                            <span style="color: #ff4839" class="ml-1 text-capitalize">Delete</span>
                          </v-btn>
                        </v-layout>
                      </td>
                    </tr>
                  </template>
                </v-data-table> -->
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions style="margin-right: 20px" class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="#444444" width="100px;" @click="CancelGroupCert()" text>
              <p class="mt-4 text-capitalize ml-2">{{$t("cancel")}}</p>
            </v-btn>
            <v-btn @click="SubmitGroupCert()" style="background-color: #126496; color: #ffffff; width: 100px" text>
              <p class="mt-4 text-capitalize">{{$t("submit")}}</p>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deletedialog" persistent width="400">
        <v-card>
          <div class="pt-5" style="
              text-color: #444444;
              font-size: 18px;
              font-weight: medium;
              margin-left: 24px;
              margin-right: 1rem;
              color: #444444;
            ">{{$t("deleteCertificate")}}</div>
          <v-card-text>
            <div style="text-align: center; margin-top: 1.5rem; font-size: 16px">{{$t("areYouSureWantToDelete?")}}</div>
          </v-card-text>
          <v-card-actions style="margin-right: 20px" class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="#444444" width="100px;" @click="deletedialog = false" text>
              <p class="mt-4 text-capitalize ml-2">{{$t("cancel")}}</p>
            </v-btn>
            <v-btn @click="DeleteCert()" style="background-color: #126496; color: #ffffff; width: 100px" text>
              <p class="mt-4 text-capitalize">{{$t("delete")}}</p>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deletedialog_GroupCert" persistent width="400">
        <v-card>
          <div class="pt-5" style="
              text-color: #444444;
              font-size: 18px;
              font-weight: medium;
              margin-left: 24px;
              margin-right: 1rem;
              color: #444444;
            ">{{$t("deleteCertificateGroup")}}</div>
          <v-card-text>
            <div style="text-align: center; margin-top: 1.5rem; font-size: 16px">{{$t("areYouSureWantToDelete?")}}</div>
          </v-card-text>
          <v-card-actions style="margin-right: 20px" class="pb-5">
            <v-spacer></v-spacer>
            <v-btn color="#444444" width="100px;" @click="deletedialog_GroupCert = false" text>
              <p class="mt-4 text-capitalize ml-2">{{$t("cancel")}}</p>
            </v-btn>
            <v-btn @click="DeleteGroupCert()" style="background-color: #126496; color: #ffffff; width: 100px" text>
              <p class="mt-4 text-capitalize">{{$t("delete")}}</p>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog persistent v-model="dialogRootCA" max-width="600">
      <v-card>
        <div class="pt-5" style="
              text-color: #444444;
              font-size: 18px;
              font-weight: medium;
              margin-left: 24px;
              margin-right: 1rem;
              color: #444444;
            ">{{$t("requestRootCA")}}</div>
        <v-card-text>
          <!-- <v-form ref="form" v-model="validateModel"> -->
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="3">
                  <div class="mt-2 txtStyle" style="margin-right: 20px">
                    <p>{{$t("password")}}: </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="9">
                  <v-text-field v-model="password" type="password" class="txtStyle" :placeholder="$t('enterYourPassword')"
                    outlined dense required style="margin-bottom: -25px;"></v-text-field>
                  <small>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span :class="has_minimum_lenth ? 'has_required_succeed' : 'has_required_unsuccessful'">{{$t("atLeast")}}
                          <b>{{$t("8characters")}}</b></span>
                      </v-col>
                      <v-col cols="6">
                        <span :class="has_lowercase ? 'has_required_succeed' : 'has_required_unsuccessful'">{{($i18n.locale == "th" ? "" : "One")}} 
                          <b>{{$t("lowercase")}}</b>{{($i18n.locale == "th" ? "หนึ่งตัว" : "letter")}}</span>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span :class="has_uppercase ? 'has_required_succeed' : 'has_required_unsuccessful'">{{($i18n.locale == "th" ? "" : "One")}} 
                          <b>{{$t("uppercase")}}</b>{{($i18n.locale == "th" ? "หนึ่งตัว" : "letter")}}</span>
                      </v-col>
                      <v-col cols="6">
                        <span :class="has_number ? 'has_required_succeed' : 'has_required_unsuccessful'">{{($i18n.locale == "th" ? "" : "One")}} 
                          <b>{{$t("numb")}}</b>{{($i18n.locale == "th" ? "หนึ่งตัว" : "")}}</span>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span :class="has_special ? 'has_required_succeed' : 'has_required_unsuccessful'">{{($i18n.locale == "th" ? "" : "One")}} <b>{{$t("specialCharacter")}}</b>{{($i18n.locale == "th" ? "หนึ่งตัว" : "")}} .</span>
                      </v-col>
                      <v-col cols="6">
                      </v-col>
                    </v-row>
                  </small>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="3">
                  <div class="mt-2 txtStyle" style="margin-right: 20px">
                    <p>{{$t("re-Password")}}: </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="9">
                  <v-text-field class="txtStyle" v-model="re_password" type="password" outlined
                    style="border-color: #ebebf2; margin-bottom: -25px;" dense required
                    :placeholder="$t('enterYourRe-Password')"></v-text-field>
                  <small>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span :class="password_match ? 'has_required_succeed' : 'has_required_unsuccessful'">{{$t("passwordsDoNotMatch")}}</span>
                      </v-col>
                    </v-row>
                  </small>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="3">
                  <div class="mt-2 txtStyle" style="margin-right: 20px">
                    <p>{{$t("detail")}}: </p>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="9">
                  <v-textarea class="txtStyle" v-model="detail" outlined style="border-color: #ebebf2" dense required
                    :placeholder="$t('detailAboutRootCert')"></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider style="color: #ebebf2"></v-divider>
          <!-- </v-form> -->
        </v-card-text>
        <v-card-actions style="margin-right: 20px" class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="#444444" width="100px;" @click="dialogRootCA = false" text>
            <p class="mt-4 text-capitalize ml-2">{{$t("cancel")}}</p>
          </v-btn>
          <v-btn @click="RequestCert()" style="background-color: #126496; color: #ffffff; width: 100px" text>
            <!-- @click="Submit()" -->
            <p class="mt-4 text-capitalize">{{$t("submit")}}</p>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert :flagAlert="flagAlertSend" :typeAlert="typeAlertSend" :mesAlert="mesAlertSend"
      @flagClose="ClearDataAlert()" />

    <Loading :flagLoading="flagLoadingSend" />

  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import * as moment from "moment/moment";
import store from "../store";
import Alert from '@/components/Alert';
import Loading from "@/components/Loading";

export default {
  components: {
    Alert,
    Loading,
  },
  computed: {
    headers() { 
      return [
        { text: this.$t("no"), sortable: false, value: "no" },
        { text: this.$t("userID"), sortable: false, value: "employeeID" },
        { text: this.$t("name"), sortable: false, value: "username" },
        { text: this.$t("addedTime"), sortable: false, value: "createDate" },
        { text: this.$t("actions"), align: "center", sortable: false, value: "actions" }
      ]
    },
    headersDialogGroupCert(){ 
      return [
        { text: this.$t("no"), sortable: false, value: "no" },
        { text: this.$t("name"), sortable: false, value: "certName" },
        { text: this.$t("addedTime"), sortable: false, value: "createDate" },
        { text: this.$t("actions"), align: "center", sortable: false, value: "actions" }
      ]
    },
    passwordConfirmationRule() {
      return () =>
        this.cerPassword === this.confirmPassword || this.$t("passwordMustMatch");
    },
    formTitle() {
      return this.isEdit == false ? this.$t("addCertificate") : this.$t("editCertificate");
    },
    formTitleGroupCert() {
      return this.isEditGroupCert == false
        ? this.$t("addCertificateGroup")
        : this.$t("editCertificateGroup");
    },
    certNameRules(){ return [v => !!v || this.$t("certificateNameIsRequired!")] } ,
    certReasonRules(){ return [v => !!v || this.$t("certReasonIsRequired!")]},
    certLocationRules(){ return [v => !!v || this.$t("certLocationIsRequired!")]},
    passwordRules(){ return [v => !!v || this.$t("passwordIsRequired!")]},
    confirmPasswordRules(){ return [v => !!v || this.$t("passwordIsRequired!")]},
    groupCertNameRules(){ return [v => !!v || this.$t("groupCertificateNameIsRequired!")]},
  },
  data() {
    return {
      checkCA : false,
      username: store.state.username,
      permissionid: store.state.permissionid,
      flagUserAD: JSON.parse(store.state.flagUserAD),
      employeeID: store.state.employeeID,
      EmployeeEmail: store.state.email,
      radioGroup: 1,
      dialogRootCA: false,
      re_password: "",
      detail: "",
      flagCertOrGroup: true,
      flag: {
        cert_view: true,
        group_cert_view: true,
        cert_add: false,
        cert_edit: false,
        cert_delete: false,
        group_cert_add: false,
        group_cert_edit: false,
        group_cert_delete: false
      },
      flag_add: true,
      flag_edit: true,
      flag_delete: true,
      flag_adm: true,
      show3: false,
      show2: false,
      search: "",
      url: enurl.apiUrl,
      password: "Password",
      confirmPassword: "",
      dialog: false,
      dialog_GroupCert: false,
      seen: true,
      imageUrl: "",
      image: [],
      fileName: "",
      isEdit: false,
      searchCert: "",

      isEditGroupCert: false,
      searchGroupCert: "",

      pageCert: 1,
      pageCertGroup: 1,
      //pageCountCert: 0,
      pageCountCertGroup: 0,
      perpage: 1,

      //validate
      certProfileRules: false,
      certFileRules: false,
      validateModel: true,

      //add
      certName: "",
      certBackground: "",
      fileFile: "",
      certImageName: "",
      cerReason: "",
      cerLocation: "",
      cerPassword: "",
      pswConfirm: false,

      ConfirmPasswordPage: false,
      Certtype: "1",
      //add Group Cert
      groupCertName: "",

      searchddlemp: "",
      ListDataDropdownEmp: [],
      OldListDataDropdownEmp: [],
      tempListDataDropdownEmp: [],
      //InputSearchEmpCert: null,
      InputSearchAdd: null,
      //InputListDataCertDDL: null,
      searchCertDDL: "",

      items: [],
      ListDataCert: [],
      OldListDataCert: [],
      ListDataCertDDL: [],
      items_GroupCert: [],
      items_GroupCertNameAndCert: [],
      flagLoadingSend: false,
      checkloadflag: false,

      CheckLoadingGroupCertFlag: false,
      //userlist
      userlist: [],
      Olduserlist: [],
      tempUserlist: [],
      empCertList: [],
      model: "",

      //Cert List in GroupCert
      tempCertlist: [],
      certListinGroup: [],
      certEmpListinGroup: [],

      //delete cert
      deletedialog: false,
      tempCertID: 0,

      //delete GroupCert
      deletedialog_GroupCert: false,
      tempGroup_CertID: 0,

      images: {
        delete: require("@/assets/delete.png")
      },
      editedIndex: -1,
      editedItem: {
        id: "",
        certName: ""
      },
      defaultItem: {
        id: "",
        certName: ""
      },
      userItem: {
        no: "",
        userId: "",
        name: "",
        addTime: ""
      },

      PageGroupCert: 1,
      itemPerPageGroupCert: 10,
      PageCountGroupCert: 0,
      itemsPerpage: [10, 20, 30],

      PageCertManage: 1,
      itemPerPageCertManage: 10,
      PageCountCertManage: 0,
      itemsPerpageCertManage: [10, 20, 30],

      has_minimum_lenth: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      password_match: false,
      isPassword: false,

      flagAlertSend: false,
      mesAlertSend: "",
      typeAlertSend: "success",
      searchInput: '', 
      searchInputEmp:''
    };
  },
  watch: {
    password() {
      let self = this;
      self.has_minimum_lenth = (self.password.length >= 8);
      self.has_number = /\d/.test(self.password);
      self.has_lowercase = /[a-z]/.test(self.password);
      self.has_uppercase = /[A-Z]/.test(self.password);
      self.has_special = /[-!@#$%^&*)(+=._|~`{}[\]\\:”;'<>?,/]/.test(self.password);
      if (self.has_minimum_lenth == true && self.has_number == true &&
        self.has_lowercase == true && self.has_uppercase == true &&
        self.has_special == true) {
        self.isPassword = true;
      }
      else {
        self.isPassword = false;
      }
    },
    re_password() {
      let self = this;
      self.password_match = (self.password == self.re_password);
      if (self.password == self.re_password && self.has_minimum_lenth == true
        && self.has_number == true && self.has_lowercase == true
        && self.has_uppercase == true && self.has_special == true) {
        self.isPassword = true;
      }
      else {
        self.isPassword = false;
      }
    },
    imageUrl(val) {
      if (val != null) {
        this.certProfileRules = false;
      } else {
        this.certProfileRules = true;
      }
    },
    fileFile(val) {
      if (val != null) {
        this.certFileRules = false;
      } else {
        this.certFileRules = true;
      }
    },
    radioGroup(val) {
      this.PageCertManage = 1;
      if (this.flagUserAD) {
        if (val == 1) {
          this.getCertListbyUser();
        } else {
          this.getGroupCertAndCertListByUser();
        }
      } else {
        if (val == 1) {
          this.getCertList();
          // this.getGroupCertList();
        } else {
          this.getGroupCertAndCertList();
          // this.getGroupCertList();
        }
      }
    },
    searchInputEmp(val) {
      if (val == null || val.length < 2) {
        this.ListDataDropdownEmp = [];
        this.flagLoadingSend = false; 
      } else {
        this.InputSearchEmpCert(val);
      }
    },

    // InputSearchEmpCert: function (val) {
    //   if (val != null && val != "") {
    //     if (val.length >= 2) {
    //       let self = this;
    //       if (val == null || val.length < 2) {
    //           self.ListDataDropdownEmp = [];
    //           self.flagLoadingSend = false;
    //           return; 
    //         }
    //       self.flagLoadingSend = true;
    //       let tempdata = {
    //         // CompanyID: self.tempCompanyID,
    //         Search: val
    //       };
    //       axios
    //         .post(`${self.url}EmployeeExt/GetEmployeeBySearch`, tempdata)
    //         .then(function (response) {
    //           if (response.data.status == 0) {
    //             var tempItem = response.data.data;
    //             self.ListDataDropdownEmp = tempItem.map(v => ({
    //               employeeID: v.employeeID,
    //               username: v.employeeName,
    //               createDate: v.createDate,
    //               //userID: "User00" + v.userID,
    //               formatDate: v.createDate
    //             }));
    //             self.OldListDataDropdownEmp = tempItem.map(v => ({
    //               employeeID: v.employeeID,
    //               username: v.employeeName,
    //               createDate: v.createDate,
    //               //userID: "User00" + v.userID,
    //               formatDate: v.createDate
    //             }));
    //             self.$refs.refSearchddlemp.focus();
    //             self.flagLoadingSend = false;
    //           }
    //         })
    //         .catch(function (error) {
    //           self.typeAlertSend = "error"; //success warning error
    //           self.mesAlertSend = error;
    //           self.flagAlertSend = true;
    //         });
    //     }
    //   } else {
    //     if (val == null || val == "") {
    //       self.ListDataDropdownEmp = [];
    //       self.searchddlemp = "";
    //       self.searchddlemp = null;
    //     }
    //   }
    // },

    searchInput(val) {
      if (val == null || val.length < 2) {
        this.ListDataCertDDL = [];
        this.flagLoadingSend = false;
      } else {
         this.flagLoadingSend = true;
         this.InputListDataCertDDL(val);
      }
    },
    // async InputListDataCertDDL(val) {
    //   let self = this;
    //   if (val != null && val != "") {
    //     if (val.length >= 2) {
    //       self.flagLoadingSend = true;
    //       let tempdata = {
    //         // ID: parseInt(sessionStorage.employeeID),
    //         Search: val
    //       };
    //       await axios
    //         .post(
    //           `${self.url}Cert/SearchDataCertListinGroupCertByEmployeeID`,
    //           tempdata
    //         )
    //         .then(function (response) {
    //           if (response.data.status == 0) {
    //             self.ListDataCertDDL = response.data.data;
    //             self.flagLoadingSend = false;
    //           } else {
               
    //             self.ListDataCertDDL = [];
    //             self.flagLoadingSend = false;
    //           }
    //           self.$refs.refSearchCertDDL.focus();
    //         })
    //         .catch(function (error) {
    //           self.typeAlertSend = "error"; //success warning error
    //           self.mesAlertSend = error;
    //           self.flagAlertSend = true;
    //           self.flagLoadingSend = false;
    //           self.ListDataCertDDL = [];
    //           // alert(error);
    //         });
    //       // self.flagLoadingSend = true;
    //       // //self.ListDataCertDDL = this.ListDataCert.filter(x => x.certName.includes(val));
    //       // self.ListDataCertDDL = this.ListDataCert.filter(x =>
    //       //   x.certName.toLowerCase().includes(val.toLowerCase())
    //       // );
    //       // self.flagLoadingSend = false;
    //     }
    //   } else {
    //     if (val == null || val < 2 || val == "") {
    //       self.ListDataCertDDL = [];
    //       self.flagLoadingSend = false;
    //     }
    //     self.flagLoadingSend = false;
    //   }
    // },

    InputSearchAdd: function (val) {
      let self = this;
      if (val == null || val.trim == "" || val.length < 2) {
        self.userlist = [];
        self.Olduserlist = [];
        self.flagLoadingSend = false;
        return;
      }
      if (val != null && val != "") {
        if (val.length >= 2) {
          self.flagLoadingSend = true;
          let tempdata = {
            // CompanyID: self.tempCompanyID,
            Search: val
          };
          axios
            .post(`${self.url}EmployeeExt/GetEmployeeBySearch`, tempdata)
            .then(function (response) {
              self.flagLoadingSend = false;
              if(self.InputSearchAdd.trim() == '' || self.InputSearchAdd.lenght < 2){
                self.userlist = [];
                self.Olduserlist = [];
                return ;
              }
              if (response.data.status == 0) {
                var tempItem = response.data.data;
                self.userlist = tempItem.map(v => ({
                  employeeID: v.employeeID,
                  username: v.employeeName,
                  createDate: v.createDate,
                  //userID: "User00" + v.userID,
                  formatDate: v.createDate
                }));
                self.Olduserlist = tempItem.map(v => ({
                  employeeID: v.employeeID,
                  username: v.employeeName,
                  createDate: v.createDate,
                  //userID: "User00" + v.userID,
                  formatDate: v.createDate
                }));
                self.$refs.refSearch.focus();
                //self.flagLoadingSend = false;
              }
            })
            .catch(function (error) {
              self.typeAlertSend = "error"; //success warning error
              self.mesAlertSend = error;
              self.flagAlertSend = true;
              self.flagLoadingSend = false;
              // alert(error);
            });
        }
      }
    },
  },

  methods: {
    async InputListDataCertDDL(val) {
      let self = this;
      if (val == null || val.length < 2) {
        self.ListDataCertDDL = [];
        self.flagLoadingSend = false;
        return; 
      }

      self.flagLoadingSend = true;
      let tempdata = {
        Search: val
      };

      await axios
        .post(
          `${self.url}Cert/SearchDataCertListinGroupCertByEmployeeID`,
          tempdata
        )
        .then(function (response) {
          if (val === self.searchInput) {
            if (response.data.status == 0) {
              self.ListDataCertDDL = response.data.data;
              self.flagLoadingSend = false;
            } else {
              self.ListDataCertDDL = [];
            }
          } else {
            self.ListDataCertDDL = [];
          }
          self.$refs.refSearchCertDDL.focus();
        })
        .catch(function (error) {
          self.typeAlertSend = "error"; // success warning error
          self.mesAlertSend = error;
          self.ListDataCertDDL = [];
        })
    },

   async InputSearchEmpCert(val) {
      let self = this;
      if (val == null || val.length < 2) {
        self.ListDataDropdownEmp = [];
        self.flagLoadingSend = false;
        return;
      }

      self.flagLoadingSend = true;
      let tempdata = {
        Search: val
      };

      await axios
        .post(`${self.url}EmployeeExt/GetEmployeeBySearch`, tempdata)
        .then(function (response) {
          if (val === self.searchInputEmp) {
            if (response.data.status == 0) {
              var tempItem = response.data.data;
              self.flagLoadingSend = false;
              self.ListDataDropdownEmp = tempItem.map(v => ({
                employeeID: v.employeeID,
                username: v.employeeName,
                createDate: v.createDate,
                formatDate: v.createDate
              }));
              self.OldListDataDropdownEmp = tempItem.map(v => ({
                employeeID: v.employeeID,
                username: v.employeeName,
                createDate: v.createDate,
                formatDate: v.createDate
              }));
            } else {
              self.ListDataDropdownEmp = [];
            }
          } else {
            self.ListDataDropdownEmp = [];
          }
          self.$refs.refSearchddlemp.focus();
        })
        .catch(function (error) {
          self.typeAlertSend = "error"; // success, warning, error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
          self.flagLoadingSend = false;
        });
    },
    ClearDataAlert() {
      let self = this;
      self.flagAlertSend = false;
      self.typeAlertSend = "success"; //success warning error
      self.mesAlertSend = "";
    },
    RootCA() {
      var self = this;
      self.dialogRootCA = true;
      self.password = "";
      self.re_password = "";
      self.detail = "";
    },

    RequestCert() {
      let self = this;
      if (self.isPassword) {
        self.flagLoadingSend = true;
        let tempdata = {
          Password: self.password,
          RePassword: self.re_password,
          Detail: self.detail
        }
        axios
          .post(`${self.url}GenerateCert/AddRootCA`, tempdata)
          .then(function (response) {
            if (response.data.status == 0) {
              self.typeAlertSend = "success"; //success warning error
              self.mesAlertSend = "Submitted a request to create a certificate. Please wait a moment.";
              self.flagAlertSend = true;
              self.dialogRootCA = false;
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            alert(error);
          });
      }
      else {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = self.$t("pleaseCompleteTheInformation");
        self.flagAlertSend = true;
      }
    },
    ForgotPasswordCert() {
      let self = this;
      self.flagLoadingSend = true;
      var flagForgot = false;
      if (!self.flag_adm) {
        var flagfileName = self.fileName.includes(self.username);
        if (flagfileName) {
          flagForgot = true;
        }
      } else {
        flagForgot = true;
      }
      if (flagForgot) {
        if (confirm("Are you sure Forgot Password Certificate?"))
          axios
            .post(`${self.url}Cert/ForgotPasswordCert?id=${self.tempCertID}`)
            .then(response => {
              if (response.data.status == 0) {
                self.typeAlertSend = "success"; //success warning error
                self.mesAlertSend = response.data.message;
                self.flagAlertSend = true;
              }
              self.flagLoadingSend = false;
            })
            .catch(error => {
              self.typeAlertSend = "error"; //success warning error
              self.mesAlertSend = error;
              self.flagAlertSend = true;
              self.flagLoadingSend = false;
            });
        else {
          self.flagLoadingSend = false;
        }
      } else {
        alert(self.$t("noPermissionForgotPasswordCertificate!"));
        self.flagLoadingSend = false;
      }
    },
    CheckPermission() {
      let self = this;
      let PerArr = [];
      if (self.permissionid != "" && typeof self.permissionid != "object") {
        PerArr = self.permissionid.split(",");
      } else {
        PerArr = self.permissionid;
      }
      for (let i = 0; i < PerArr.length; i++) {
        var temp_adm = PerArr.filter(x => x == 1);
        if (temp_adm.length > 0) {
          self.flag_adm = true;
        }
        var temp_add = PerArr.filter(x => x == 6 || x == 1);
        if (temp_add.length > 0) {
          self.flag_add = true;
        }
        var temp_edit = PerArr.filter(x => x == 7 || x == 1);
        if (temp_edit.length > 0) {
          self.flag_edit = true;
        }
        var temp_delete = PerArr.filter(x => x == 8 || x == 1);
        if (temp_delete.length > 0) {
          self.flag_delete = true;
        }
      }
    },

    todayDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = mm + "/" + dd + "/" + yyyy;

      return today;
    },

    getCertList() {
      let self = this;
      if (self.CheckLoadingGroupCertFlag == false) {
        self.flagLoadingSend = true;
      }
      let tempdata = {
        Search: self.searchCert,
        Page: self.PageCertManage,
        PerPage: self.itemPerPageCertManage
      };
      axios
        .post(`${self.url}Cert/GetCertListinGroupCertByEmployeeID`, tempdata)
        .then(function (response) {
          if (response.data.status == 0) {
            self.items = response.data.data.listData;
            self.ListDataCert = response.data.data.listData;
            self.OldListDataCert = response.data.data.listData;
            self.PageCountCertManage = response.data.data.count;
            self.pageCountCert = Math.ceil(
              response.data.data.length / self.perpage
            );
            self.flagLoadingSend = false;       
          } else {
            self.flagLoadingSend = false;
            self.items = [];
            self.ListDataCert = [];
            self.OldListDataCert = [];
            self.PageCountCertManage = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = self.$t("dataNotFound");
            self.flagAlertSend = true;
          }
          self.CheckLoadingGroupCertFlag = false;
        })
        .catch(function (error) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
          self.flagLoadingSend = false;
          // alert(error);
        });
    },

  

    getCertListbyUser() {
      let self = this;
      self.flagLoadingSend = true;
      let tempdata = {
        // ID: parseInt(sessionStorage.employeeID),
        Search: self.searchCert,
        Page: self.PageCertManage,
        PerPage: self.itemPerPageCertManage
      };
      axios
        // .get(`${self.url}Cert/GetCertByEmployeeID?id=${self.employeeID}`)
        .post(`${self.url}Cert/GetAllCertByEmployeeId`, tempdata)
        .then(function (response) {
          if (response.data.status == 0) {
            self.items = response.data.data.listData;
            self.ListDataCert = response.data.data.listData;
            self.PageCountCertManage = response.data.data.count;
            // self.pageCountCert = Math.ceil(
            //   response.data.data.length / self.perpage
            // );
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.items = [];
            self.ListDataCert = [];
            self.PageCountCertManage = [];
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = self.$t("dataNotFound");
            self.flagAlertSend = true;
          }
        })
        .catch(function (error) {
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
          self.flagLoadingSend = false;
          // alert(error);
        });
    },

    ChangePageCertManage: function (value) {
      if (this.flagUserAD) {
        let self = this;
        self.flagLoadingSend = true;
        let tempdata = {
          // ID: parseInt(sessionStorage.employeeID),
          Search: self.searchCert,
          Page: value,
          PerPage: self.itemPerPageCertManage
        };
        axios
          // .get(`${self.url}Cert/GetCertByEmployeeID?id=${self.employeeID}`)
          .post(`${self.url}Cert/GetAllCertByEmployeeId`, tempdata)
          .then(function (response) {
            if (response.data.status == 0) {
              self.items = response.data.data.listData;
              self.ListDataCert = response.data.data.listData;
              self.PageCountCertManage = response.data.data.count;
              // self.pageCountCert = Math.ceil(
              //   response.data.data.length / self.perpage
              // );
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.items = [];
              self.ListDataCert = [];
              self.PageCountCertManage = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t("dataNotFound");
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
            // alert(error);
          });
      } else {
        let self = this;
        if (self.CheckLoadingGroupCertFlag == false) {
          self.flagLoadingSend = true;
        }
        let tempdata = {
          // ID: parseInt(sessionStorage.employeeID),
          Search: self.searchCert,
          Page: value,
          PerPage: self.itemPerPageCertManage
        };
        axios
          // .get(`${self.url}Cert/GetCertByCompanyID?id=${self.tempCompanyID}`)
          .post(`${self.url}Cert/GetCertListinGroupCertByEmployeeID`, tempdata)
          .then(function (response) {
            if (response.data.status == 0) {
              self.items = response.data.data.listData;
              self.ListDataCert = response.data.data.listData;
              self.OldListDataCert = response.data.data.listData;
              self.PageCountCertManage = response.data.data.count;
              // self.pageCountCert = Math.ceil(
              //   response.data.data.length / self.perpage
              // );
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.items = [];
              self.ListDataCert = [];
              self.OldListDataCert = [];
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t("dataNotFound");
              self.flagAlertSend = true;
            }
            self.CheckLoadingGroupCertFlag = false;
          })
          .catch(function (error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
            // alert(error);
          });
      }
    },

    ChangePageGroupCert: function (value) {
      if (this.flagUserAD) {
        let self = this;
        self.flagLoadingSend = true;
        let temp = {
          // ID: parseInt(self.employeeID),
          Search: self.searchGroupCert,
          Page: value,
          PerPage: self.itemPerPageGroupCert
        };
        axios
          .post(
            `${self.url}Group_Cert/GetAllDataGroupCertListByEmployeeID`,
            temp
          )
          .then(function (response) {
            if (response.data.status == 0) {
              self.items_GroupCert = response.data.data.listData;
              self.PageCountGroupCert = response.data.data.count;
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.items_GroupCert = [];
              self.PageCountGroupCert = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t("dataNotFound");
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
            // alert(error);
          });
      } else {
        let self = this;
        self.flagLoadingSend = true;
        let temp = {
          // ID: parseInt(self.tempCompanyID),
          // EmployeeID: parseInt(sessionStorage.employeeID),
          Search: self.searchGroupCert,
          Page: value,
          PerPage: self.itemPerPageGroupCert
        };
        axios
          .post(`${self.url}Group_Cert/GetGroupCertListCompanyID`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.items_GroupCert = response.data.data.listdata;
              self.PageCountGroupCert = response.data.data.count;
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.items_GroupCert = [];
              self.PageCountGroupCert = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t("dataNotFound");
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
            self.flagLoadingSend = false;
            // alert(error);
          });
      }
    },

    ChangePerPageCertManage: function (value) {
      if (this.flagUserAD) {
        let self = this;
        self.flagLoadingSend = true;
        let tempdata = {
          // ID: parseInt(sessionStorage.employeeID),
          Search: self.searchCert,
          Page: 1,
          PerPage: value
        };
        axios
          // .get(`${self.url}Cert/GetCertByEmployeeID?id=${self.employeeID}`)
          .post(`${self.url}Cert/GetAllCertByEmployeeId`, tempdata)
          .then(function (response) {
            if (response.data.status == 0) {
              self.items = response.data.data.listData;
              self.ListDataCert = response.data.data.listData;
              self.PageCountCertManage = response.data.data.count;
              self.PageCertManage = 1;
              // self.pageCountCert = Math.ceil(
              //   response.data.data.length / self.perpage
              // );
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.items = [];
              self.ListDataCert = [];
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t("dataNotFound");
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
          });
      } else {
        let self = this;
        if (self.CheckLoadingGroupCertFlag == false) {
          self.flagLoadingSend = true;
        }
        let tempdata = {
          // ID: parseInt(sessionStorage.employeeID),
          Search: self.searchCert,
          Page: 1,
          PerPage: value
        };
        axios
          // .get(`${self.url}Cert/GetCertByCompanyID?id=${self.tempCompanyID}`)
          .post(`${self.url}Cert/GetCertListinGroupCertByEmployeeID`, tempdata)
          .then(function (response) {
            if (response.data.status == 0) {
              self.items = response.data.data.listData;
              self.ListDataCert = response.data.data.listData;
              self.OldListDataCert = response.data.data.listData;
              self.PageCountCertManage = response.data.data.count;
              self.PageCertManage = 1;
              // self.PageCertManage = Math.ceil(
              //   response.data.data.length / self.perpage
              // );
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.items = [];
              self.ListDataCert = [];
              self.OldListDataCert = [];
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t("dataNotFound");
              self.flagAlertSend = true;
            }
            self.CheckLoadingGroupCertFlag = false;
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
          });
      }
    },

    ChangePerPageGroupCert: function (value) {
      if (this.flagUserAD) {
        let self = this;
        self.flagLoadingSend = true;
        let temp = {
          // ID: parseInt(self.employeeID),
          Search: self.searchGroupCert,
          Page: 1,
          PerPage: value
        };
        axios
          // .post(`${self.url}Group_Cert/GetGroupCertListEmployeeID`, temp)
          .post(
            `${self.url}Group_Cert/GetAllDataGroupCertListByEmployeeID`,
            temp
          )
          .then(function (response) {
            if (response.data.status == 0) {
              self.items_GroupCert = response.data.data.listData;
              self.PageCountGroupCert = response.data.data.count;
              self.PageGroupCert = 1;
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.items_GroupCert = [];
              self.PageCountGroupCert = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t("dataNotFound");
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
          });
      } else {
        let self = this;
        self.flagLoadingSend = true;
        let temp = {
          // ID: parseInt(self.tempCompanyID),
          // EmployeeID: parseInt(sessionStorage.employeeID),
          Search: self.searchGroupCert,
          Page: 1,
          PerPage: value
        };
        axios
          .post(`${self.url}Group_Cert/GetGroupCertListCompanyID`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.items_GroupCert = response.data.data.listdata;
              self.PageCountGroupCert = response.data.data.count;
              self.PageGroupCert = 1;
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.items_GroupCert = [];
              self.PageCountGroupCert = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t("dataNotFound");
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
          });
      }
    },

    getEmployeeAll: function () {
      let self = this;
      if (self.checkloadflag == false) {
        self.flagLoadingSend = true;
      }
      axios
        .post(
          `${self.url}EmployeeExt/GetEmployeeExtByCompanyId`
        )
        .then(function (response) {
          if (response.data.status == 0) {
            var tempItem = response.data.data;
            self.userlist = tempItem.map(v => ({
              employeeID: v.employeeID,
              username: v.employeeName,
              createDate: v.createDate,
              //userID: "User00" + v.userID,
              formatDate: v.createDate
            }));
            self.Olduserlist = tempItem.map(v => ({
              employeeID: v.employeeID,
              username: v.employeeName,
              createDate: v.createDate,
              //userID: "User00" + v.userID,
              formatDate: v.createDate
            }));
            // self.ListDataDropdownEmp = tempItem.map(v => ({
            //   employeeID: v.employeeID,
            //   username: v.employeeName,
            //   createDate: v.createDate,
            //   //userID: "User00" + v.userID,
            //   formatDate: v.createDate
            // }));
            // self.OldListDataDropdownEmp = tempItem.map(v => ({
            //   employeeID: v.employeeID,
            //   username: v.employeeName,
            //   createDate: v.createDate,
            //   //userID: "User00" + v.userID,
            //   formatDate: v.createDate
            // }));
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            //alert(response.data.message);
          }
          self.checkloadflag = false;
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    onPickFile() {
      this.$refs.fileInput.click();
    },

    onFilePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert(self.$t("pleaseAddAValidFile!"));
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
    },

    onFileChange(e) {
      let self = this;
      const files = e.target.files;
      self.file = files[0];

      const acceptedTypes = ['application/x-pkcs12'];
      if(acceptedTypes.includes(self.file['type']))
      {
        if (files[0] !== undefined) {
          self.fileName = files[0].name;

          if(self.fileName.includes('_CA')){
            console.log(self.fileName.includes('_CA'))
            self.fileName = "";
            return alert(self.$t("pleaseAddAValidFile!"));
            
          }

          if (self.fileName.lastIndexOf(".") <= 0) {
            return;
          }
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);

          //onload
          fr.onload = e => {
            var getdata = e.target.result.split(",");
            self.fileFile = getdata[getdata.length - 1];
          };
        } else {
          self.fileName = "";
          self.fileFile = "";
          self.fileUrl = "";
        }
        self.showfile = self.fileName;
      }
      else
      {
        self.typeAlertSend = "warning"; //success warning error
        self.mesAlertSend = self.$t("pleaseAttach.pfxFileOnly.");
        self.flagAlertSend = true;
      }
    },

    openFileDialog() {
      this.$refs.file.click();
    },

    Cancel() {
      let self = this;
      self.dialog = false;
      self.certName = "";
      self.Certtype = "1";
      self.certBackground = "";
      self.fileFile = "";
      self.imageUrl = "";
      self.fileName = "";
      self.cerReason = "";
      self.cerLocation = "";
      self.pswConfirm = false;
      self.cerPassword = "";
      self.tempUserlist = [];
      self.confirmPassword = "";
      self.model = "";
      self.empCertList = [];
      self.$refs.form.reset();
      self.certFileRules = false;
      self.certProfileRules = false;
      self.$refs.file.value = null;
      self.$refs.fileimage.value = null;
      self.ConfirmPasswordPage = false;
      self.checkloadflag = true;
      //self.getEmployeeAll();
    },

    //add
    Submit() {
      this.$refs.form.validate();
      let self = this;
      if (self.fileFile == "") {
        self.certFileRules = true;
      } else {
        self.certFileRules = false;
      }
      if (
        self.certProfileRules == false &&
        self.certFileRules == false &&
        self.certName != "" &&
        self.cerReason != "" &&
        self.cerLocation != "" &&
        ((self.isEdit == false &&
          self.cerPassword !== "" &&
          self.confirmPassword !== "" &&
          self.cerPassword == self.confirmPassword) ||
          (self.isEdit == true &&
            ((self.cerPassword !== "" &&
              self.confirmPassword !== "" &&
              self.cerPassword == self.confirmPassword) ||
              (self.cerPassword === "" && self.confirmPassword === ""))))
      ) {
        if (
          self.certProfileRules == false &&
          self.certFileRules == false &&
          self.certName != "" &&
          self.cerReason != "" &&
          self.cerLocation != ""
        ) {
          if (self.isEdit == false) {
            self.dialog = false;
            self.flagLoadingSend = true;
            if (self.flagUserAD) {
              self.empCertList = [];
              var temp = {
                employeeID: self.employeeID
              };
              self.empCertList.push(temp);
            }
            let AddCert = {
              certName: self.certName,
              certBackground: self.certBackground,
              certImage: self.fileFile,
              certImageName: self.showfile,
              certReason: self.cerReason,
              certLocation: self.cerLocation,
              certPasswordCheck: self.pswConfirm,
              certPassword: self.cerPassword,
              // companyID: self.tempCompanyID,
              employeeList: self.empCertList,
              createBy: self.username,
              createDate: "2020-10-04T06:59:07.114Z",
              checkConfirmPass: self.ConfirmPasswordPage,
              certtype: parseInt(self.Certtype),
              // Email: self.EmployeeEmail
            };
            axios
              .post(`${self.url}Cert/AddCert`, AddCert)
              .then(function (response) {
                if (response.data.status == 0) {
                  if (self.flagUserAD) {
                    self.flagLoadingSend = false;
                    self.getCertListbyUser();
                  } else {
                    self.flagLoadingSend = false;
                    self.getCertList();
                  }
                }
                self.Cancel();
              })
              .catch(function (error) {
                self.flagLoadingSend = false;
                self.typeAlertSend = "error"; //success warning error
                self.mesAlertSend = error;
                self.flagAlertSend = true;
              });
          } else {
            self.dialog = false;
            self.flagLoadingSend = true;
            let UpdateCert = {
              certID: self.tempCertID,
              certName: self.certName,
              certBackground: self.certBackground,
              certImage: self.fileFile,
              certImageName: self.fileName,
              certReason: self.cerReason,
              certLocation: self.cerLocation,
              certPasswordCheck: self.pswConfirm,
              certPassword: self.cerPassword,
              // companyID: self.tempCompanyID,
              employeeList: self.empCertList,
              checkConfirmPass: self.ConfirmPasswordPage == null ? false : self.ConfirmPasswordPage,
              certtype: parseInt(self.Certtype),
              // Email: self.EmployeeEmail
            };
            axios
              .post(`${self.url}Cert/UpdateCert`, UpdateCert)
              .then(function (response) {
                if (response.data.status == 0) {
                  self.tempUserlist = [];
                  if (self.flagUserAD) {
                    self.flagLoadingSend = false;
                    self.getCertListbyUser();
                  } else {
                    self.flagLoadingSend = false;
                    self.getCertList();
                  }
                }
                self.Cancel();
                self.flagLoadingSend = false;
              })
              .catch(function (error) {
                self.flagLoadingSend = false;
                self.typeAlertSend = "error"; //success warning error
                self.mesAlertSend = error;
                self.flagAlertSend = true;
              });
          }
        }
      }
    },
    deleteItem(item) {
      this.deletedialog = true;
      this.tempCertID = item.certID;
      // this.getGroupCertList();
    },

    CancelGroupCert() {
      let self = this;
      self.dialog_GroupCert = false;
      self.groupCertName = "";
      self.tempCertlist = [];
      self.tempListDataDropdownEmp = [];
      self.certListinGroup = [];
      self.certEmpListinGroup = [];
      self.$refs.form.reset();
      this.CheckLoadingGroupCertFlag = true;
      this.getCertList();
    },

    SubmitGroupCert() {
      this.$refs.form.validate();
      let self = this;
      if (self.groupCertName != "") {
        if (self.isEditGroupCert == false) {
          self.dialog_GroupCert = false;
          self.flagLoadingSend = true;

          // let tempdup = [];
          // for(let i = 0; i < self.tempListDataDropdownEmp.length - 1; i++){
          //   if(self.tempListDataDropdownEmp[i + 1] == self.tempListDataDropdownEmp[i]){
          //     tempdup.push(self.tempListDataDropdownEmp[i]);
          //   }
          // }

          // if(tempdup.length > 0){
          //   alert("ข้อมูล Group Certificate Permission ซ้ำ!");
          //   self.flagLoadingSend = false;
          // }
          if (self.flagUserAD) {
            self.tempListDataDropdownEmp = [];
            let tempdata = {
              employeeID: self.employeeID,
              no: self.tempListDataDropdownEmp.length
            };
            self.tempListDataDropdownEmp.push(tempdata);
          }
          let tempAddGroupCert = {
            group_CertName: self.groupCertName,
            // companyID: self.tempCompanyID,
            cert_List: self.certListinGroup,
            listemp: self.tempListDataDropdownEmp
          };
          axios
            .post(`${self.url}Group_Cert/AddGroupCertificate`, tempAddGroupCert)
            .then(function (response) {
              if (response.data.status != 0) {
                self.typeAlertSend = "success"; //success warning error
                self.mesAlertSend = response.data.message;
                self.flagAlertSend = true;
              }
              if (self.flagUserAD) {
                self.flagLoadingSend = false;
                self.CancelGroupCert();
                self.getGroupCertListByUser();
              } else {
                self.flagLoadingSend = false;
                self.CancelGroupCert();
                self.getGroupCertList();
              }
            })
            .catch(function (error) {
              self.typeAlertSend = "error"; //success warning error
              self.mesAlertSend = error;
              self.flagAlertSend = true;
              self.CancelGroupCert();
              self.flagLoadingSend = false;
            });
        } else {
          self.dialog_GroupCert = false;
          self.flagLoadingSend = true;
          // if (self.flagUserAD) {
          //   self.tempListDataDropdownEmp = [];
          //   let tempdata = {
          //     employeeID: self.employeeID
          //   };
          //   self.tempListDataDropdownEmp.push(tempdata);
          // }
          let tempUpdateGroupCert = {
            group_CertID: self.tempGroup_CertID,
            group_CertName: self.groupCertName,
            // companyID: self.tempCompanyID,
            cert_List: self.certListinGroup,
            listEmp: self.tempListDataDropdownEmp
          };
          axios
            .post(`${self.url}Group_Cert/UpdateGroupCert`, tempUpdateGroupCert)
            .then(function (response) {
              if (response.data.status == 0) {
                if (self.flagUserAD) {
                  self.flagLoadingSend = false;
                  self.CancelGroupCert();
                  self.getGroupCertListByUser();
                } else {
                  self.flagLoadingSend = false;
                  self.CancelGroupCert();
                  self.getGroupCertList();
                }
              } else {
                self.flagLoadingSend = false;
              }
              //this.CancelGroupCert();
              //this.tempListDataDropdownEmp = [];
              //self.flagLoadingSend = false;
            })
            .catch(function (error) {
              self.CancelGroupCert();
              self.flagLoadingSend = false;
              self.typeAlertSend = "error"; //success warning error
              self.mesAlertSend = error;
              self.flagAlertSend = true;
            });
        }
      }
    },

    deleteItem_GroupCert(item) {
      this.deletedialog_GroupCert = true;
      this.tempGroup_CertID = item.group_CertID;
    },
    show1() {
      this.flagLoadingSend = true;
    },

    show() {
      this.flagLoadingSend = false;
    },

    editItem(item) {
      this.isEdit = true;
      this.dialog = true;
      this.certProfileRules = false;
      this.certFileRules = false;
      this.tempCertID = item.certID;
      this.fileFile = item.certImage;
      this.fileName = item.certImageName;
      this.certBackground = item.certBackground;
      this.certName = item.certName;
      this.cerReason = item.certReason;
      this.cerLocation = item.certLocation;
      this.pswConfirm = item.certPasswordCheck;
      this.cerPassword = item.certPassword;
      this.confirmPassword = item.certPassword;
      this.ConfirmPasswordPage = item.checkConfirmPass != null ? item.checkConfirmPass : (item.checkConfirmPass == null ? false : true);
      this.Certtype = item.certType == null ? null : item.certType.toString();
      var pattern = /_CA/i;
      this.checkCA = pattern.test(item.certImageName);
        
      

      if (item.employeeList != null) {
        for (let v of item.employeeList) {
          let dt = null;
          if (this.isEdit == true) {
            dt = this.changeLocal(v.createDate);
          } else {
            dt = this.convertUTCDateToLocalDate(v.createDate);
          }
          var temp = {
            employeeID: v.employeeID,
            //userID: emp.userID,
            certID: 0,
            createBy: "1",
            createDate: dt,
            formatDate: dt,
            username: v.employeeName,
            no: this.tempUserlist.length + 1
          };
          var temp1 = {
            employeeID: v.employeeID,
            certID: 0,
            createBy: this.employeeName,
            createDate: v.createDate
          };
          this.tempUserlist.push(temp);
          this.empCertList.push(temp1);
        }
      }
      // if (item.employeeList != null) {
      //   for (let v of item.employeeList) {
      //     for (let emp of this.userlist) {
      //       if (v.employeeID == emp.employeeID) {
      //         let dt = null;
      //         if (this.isEdit == true) {
      //           dt = this.changeLocal(v.createDate);
      //         } else {
      //           dt = this.convertUTCDateToLocalDate(v.createDate);
      //         }
      //         var temp = {
      //           employeeID: emp.employeeID,
      //           //userID: emp.userID,
      //           certID: 0,
      //           createBy: "1",
      //           createDate: v.createDate,
      //           formatDate: dt,
      //           username: emp.username
      //         };
      //         var temp1 = {
      //           employeeID: emp.employeeID,
      //           certID: 0,
      //           createBy: this.username,
      //           createDate: v.createDate
      //         };
      //         this.tempUserlist.push(temp);
      //         this.empCertList.push(temp1);
      //       }
      //     }
      //   }
      // }
      for (let i = 0; i < this.tempUserlist.length; i++) {
        var findIndex = this.userlist.findIndex(
          x => x.employeeID == this.tempUserlist[i].employeeID
        );
        if (findIndex != null) {
          this.userlist.splice(findIndex, 1);
        }
      }
      if (item.certBackground != "") {
        this.imageUrl = "data:image/jpeg;base64," + item.certBackground;
      }
    },

    editItem_GroupCert(item) {
      this.tempCertlist = [];
      this.certListinGroup = [];
      this.tempListDataDropdownEmp = [];

      this.isEditGroupCert = true;
      this.dialog_GroupCert = true;
      this.tempGroup_CertID = item.group_CertID;
      this.groupCertName = item.group_CertName;

      if (item.cert_List != null) {
        // this.ListDataCertDDL = this.ListDataCert;

        for (let v of item.cert_List) {
          let dt = null;
          if (this.isEditGroupCert == true) {
            dt = this.changeLocal(v.createDate);
          } else {
            dt = this.convertUTCDateToLocalDate(v.createDate);
          }
          var temp = {
            certID: v.certID,
            certName: v.certName,
            createBy: "1",
            createDate: dt,
            formatDate: dt,
            no: this.tempCertlist.length
          };
          var tempCert = {
            certID: v.certID,
            certName: v.certName,
            createDate: v.createDate
          };
          this.tempCertlist.push(temp);
          this.certListinGroup.push(tempCert);
          // this.ListDataCertDDL = this.ListDataCertDDL.filter(function (value) {
          //   return value.certID != tempCert.certID;
          // });
        }
        if (item.listEmp != null) {
          for (let i = 0; i < item.listEmp.length; i++) {
            var tempEmp = {
              employeeID: item.listEmp[i].employeeID,
              certID: 0,
              createBy: "1",
              createDate: this.changeLocal(i.createDate),
              formatDate: this.DateNow(),
              username: item.listEmp[i].username,
              userID: item.listEmp[i].employeeID,
              no: this.tempListDataDropdownEmp.length
            };
            this.tempListDataDropdownEmp.push(tempEmp);
          }
        }
      }
    },

    DeleteCert() {
      var self = this;
      if (self.tempCertID != 0) {
        self.deletedialog = false;
        self.flagLoadingSend = true;
        var temp = {
          certID: self.tempCertID,
          // Email: self.EmployeeEmail
        };
        axios
          .post(`${self.url}Cert/DeleteCert`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.flagLoadingSend = false;
              if (self.flagUserAD) {
                self.getCertListbyUser();
              } else {
                self.getCertList();
              }
            }
            self.flagLoadingSend = false;
          })
          .catch(function (error) {
            // alert(error);
            self.flagLoadingSend = false;
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
          });
      }
    },

    DeleteGroupCert() {
      var self = this;
      if (self.tempGroup_CertID != 0) {
        self.deletedialog_GroupCert = false;
        self.flagLoadingSend = true;
        var temp = {
          group_CertID: self.tempGroup_CertID
        };
        axios
          .post(`${self.url}Group_Cert/DeleteGroupCertificate`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.flagLoadingSend = false;
              if (self.flagUserAD) {
                self.getGroupCertListByUser();
              } else {
                self.getGroupCertList();
              }
            }
            self.flagLoadingSend = false;
          })
          .catch(function (error) {
            // alert(error);
            self.flagLoadingSend = false;
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
          });
      }
    },

    Delete(item) {
      alert(this.$t("areYouSureYouWantToDelete!"));
      let self = this;
      self.editedIndex = self.tempUserlist.indexOf(item);
      self.userItem = Object.assign({}, item);
      self.tempUserlist.splice(self.editedIndex, 1);

      var temp = self.empCertList.indexOf(item);
      self.empCertList.splice(temp, 1);

      self.userlist = [];
      self.Olduserlist.forEach(element => {
        self.userlist.push(element);
      });
      for (let i = 0; i < self.tempUserlist.length; i++) {
        var FindIndex = self.userlist.findIndex(
          x => x.employeeID == self.tempUserlist[i].employeeID
        );
        if (FindIndex != null) {
          self.userlist.splice(FindIndex, 1);
        }
      }
    },

    DeleteCertDialog(item) {
      // alert("Are you sure you want to delete!");
      var del = confirm("Are you sure you want to delete!");
      if (del == true) {
        this.editedIndex = this.tempCertlist.indexOf(item);
        this.userItem = Object.assign({}, item);
        this.tempCertlist.splice(this.editedIndex, 1);

        var temp = this.certListinGroup.indexOf(item);
        this.certListinGroup.splice(temp, 1);

        this.ListDataCertDDL = [];
        // this.OldListDataCert.forEach((element) => {
        //   this.ListDataCertDDL.push(element);
        // });
        // for (let i = 0; i < this.tempCertlist.length; i++) {
        //   var FindIndex = this.ListDataCertDDL.findIndex(
        //     (x) => x.certID == this.tempCertlist[i].certID
        //   );
        //   if (FindIndex != null) {
        //     this.ListDataCertDDL.splice(FindIndex, 1);
        //   }
        // }
      }
    },

    DeleteCertDialogEmp(item) {
      alert(this.$t("areYouSureYouWantToDelete!"));
      this.editedIndex = this.tempListDataDropdownEmp.indexOf(item);
      this.userItem = Object.assign({}, item);
      this.tempListDataDropdownEmp.splice(this.editedIndex, 1);

      var temp = this.certEmpListinGroup.indexOf(item);
      this.certEmpListinGroup.splice(temp, 1);

      this.ListDataDropdownEmp = [];
      this.OldListDataDropdownEmp.forEach(element => {
        this.ListDataDropdownEmp.push(element);
      });
      for (let i = 0; i < this.tempListDataDropdownEmp.length; i++) {
        var FindIndex = this.ListDataDropdownEmp.findIndex(
          x => x.certID == this.tempListDataDropdownEmp[i].certID
        );
        if (FindIndex != null) {
          this.ListDataDropdownEmp.splice(FindIndex, 1);
        }
      }
    },

    handleFileInput(e) {
      //var self = this;
      //this.image = [];
      let files = e ? e.target.files : this.$refs.fileimage.files[0];
      files = e.target.files;
      if(files[0].type == 'image/png' || files[0].type == "image/jpg" || files[0].type == "image/jpeg")
      {
        //files = e.target.files;
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = e => {
          var getdata = e.target.result.split(",");
          this.certBackground = getdata[getdata.length - 1];
          this.imageUrl = e.target.result;
        };
      }
      else
      {
        this.flagAlertSend = true;
        this.typeAlertSend = "warning"; //success warning error
        this.mesAlertSend = this.$t("pleaseAttach.PNGOr.JPGImageOnly.");
      }
      
    },

    handleFileDrop(e) {
      this.image = [];
      let droppedFiles = e.dataTransfer.files;
      if(droppedFiles[0].type == 'image/png' || droppedFiles[0].type == "image/jpg" || droppedFiles[0].type == "image/jpeg")
      {
        var reader = new FileReader();
        reader.readAsDataURL(droppedFiles[0]);
        reader.onload = e => {
          var getdata = e.target.result.split(",");
          this.certBackground = getdata[getdata.length - 1];
          this.imageUrl = e.target.result;
        };
      }
      else
      {
        this.flagAlertSend = true;
        this.typeAlertSend = "warning"; //success warning error
        this.mesAlertSend = this.$t("pleaseAttach.PNGOr.JPGImageOnly.");
      }
      
      // if (!droppedFiles) return;
      // //if (droppedFiles.type == "application/pdf") {
      // [...droppedFiles].forEach((f) => {
      //   this.image.push(f);
      // });
    },

    fileDragIn() { },

    fileDragOut() { },

    //AddCertificate
    AddCert() {
      this.dialog = true;
      this.isEdit = false;
      this.certName = "";
      this.Certtype = "1";
      this.certBackground = "";
      this.imageUrl = "";
      this.fileFile = "";
      this.fileName = "";
      this.cerReason = "";
      this.cerLocation = "";
      this.pswConfirm = false;
      this.cerPassword = "";
      this.tempUserlist = [];
      this.confirmPassword = "";
      this.model = "";
      this.empCertList = [];
    },

    AddGroupCert() {
      this.dialog_GroupCert = true;
      this.isEditGroupCert = false;
      //this.ListDataCertDDL = this.ListDataCert;
    },

    convertUTCDateToLocalDate(createDate) {
      let newDate = new Date();
      if (createDate != null) {
        newDate = createDate;
      }

      //let timezone = date.getTimezoneOffset() * 60000;
      //let newDate = new Date(date.getTime() - timezone);
      let output = null;
      let localMonth = newDate.getMonth() + 1;
      if (localMonth < 9) {
        output = "0" + localMonth;
      } else {
        output = localMonth;
      }
      if (newDate.getDate() < 10) {
        output = output + "/" + "0" + newDate.getDate();
      } else {
        output = output + newDate.getDate();
      }

      output = output + "/" + newDate.getFullYear();
      return output;
    },

    DateNow() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hh = String(today.getHours()).padStart(2,"0");
      var min = String(today.getMinutes()).padStart(2,"0");
      var ss = String(today.getSeconds()).padStart(2,"0");

      if(parseInt(hh) > 12)
      {
        hh = String(hh%12).padStart(2,"0");
      }

      return dd + "/" + mm + "/" + yyyy +" " + hh + ":" + min + ":" + ss;
      // return dd + "/" + mm + "/" + yyyy;
    },

    AddEmp(val) {
      if (val != null) {
        var checkdup = false;
        for (let i = 0; i < this.tempUserlist.length; i++) {
          if (this.tempUserlist[i].employeeID == val.employeeID) {
            checkdup = true;
          }
        }
        if (checkdup == true) {
          this.typeAlertSend = "warning"; //success warning error
          this.mesAlertSend = self.$t("userIsDuplicated!");
          this.flagAlertSend = true;
        } else {
          //let dt = this.convertUTCDateToLocalDate(null);
          this.search = null;
          var temp = {
            employeeID: val.employeeID,
            certID: 0,
            createBy: "1",
            createDate: this.DateNow(),
            formatDate: this.DateNow(),
            username: val.username,
            userID: val.userID
          };
          var empList = {
            employeeID: val.employeeID,
            certID: 0,
            createBy: "1",
            createDate: new Date().toISOString()
          };
          this.tempUserlist.push(temp);

          var indexNum = this.userlist.findIndex(
            x => x.employeeID == val.employeeID
          );
          if (indexNum != null) {
            this.userlist.splice(indexNum, 1);
          }

          this.empCertList.push(empList);
          this.userlist = [];
          this.search = '';
          this.InputSearchAdd = null;
        }
      }
    },

    AddCertDialog(val) {
      if (val != null) {
        var checkdup = false;
        for (let i = 0; i < this.tempCertlist.length; i++) {
          if (this.tempCertlist[i].certID == val.certID) {
            checkdup = true;
          }
        }
        if (checkdup == true) {
          this.typeAlertSend = "warning"; //success warning error
          this.mesAlertSend = this.$t("certificateIsDuplicated!");
          this.flagAlertSend = true;
        } else {
          var temp = {
            certID: val.certID,
            certName: val.certName,
            createDate: this.DateNow(),
            formatDate: this.DateNow()
          };
          var tempCert = {
            certID: val.certID,
            certName: val.certName,
            createDate: new Date().toISOString()
          };
          this.tempCertlist.push(temp);
          this.certListinGroup.push(tempCert);

          // this.ListDataCertDDL = [];
          //this.OldListDataCert = [];
          // this.OldListDataCert.forEach((element) => {
          //   this.ListDataCertDDL.push(element);
          // });

          // for (let i = 0; i < this.certListinGroup.length; i++) {
          //   var FindIndex = this.ListDataCertDDL.findIndex(
          //     (x) => x.certID == this.certListinGroup[i].certID
          //   );
          //   if (FindIndex != null) {
          //     this.ListDataCertDDL.splice(FindIndex, 1);
          //   }
          // }
          this.searchCertDDL = null;
        }
      }
    },

    AddCertDialogEmp(val) {
      if (val != null) {
        //let dt = this.convertUTCDateToLocalDate(null);
        var checkdup = false;
        for (let i = 0; i < this.tempListDataDropdownEmp.length; i++) {
          if (this.tempListDataDropdownEmp[i].employeeID == val.employeeID) {
            // if (
            //   this.tempListDataDropdownEmp[i].username.trim() ==
            //     val.username.trim() ||
            //   this.tempListDataDropdownEmp[i].username.includes(val.username)
            // ) {
            //   checkdup = true;
            // }
            checkdup = true;
          }
        }
        if (checkdup == true) {
          this.typeAlertSend = "warning"; //success warning error
          this.mesAlertSend = self.$t("userIsDuplicated!");
          this.flagAlertSend = true;
          this.ListDataDropdownEmp = [];
        } else {
          this.searchddlemp = null;
          var temp = {
            employeeID: val.employeeID,
            certID: 0,
            createBy: "1",
            createDate: this.DateNow(),
            formatDate: this.DateNow(),
            username: val.username,
            userID: val.userID
          };
          var empList = {
            employeeID: val.employeeID,
            certID: 0,
            createBy: "1",
            createDate: new Date().toISOString()
          };
          this.tempListDataDropdownEmp.push(temp);

          var indexNum = this.ListDataDropdownEmp.findIndex(
            x => x.employeeID == val.employeeID
          );
          if (indexNum != null) {
            this.ListDataDropdownEmp.splice(indexNum, 1);
          }

          this.certEmpListinGroup.push(empList);
          this.searchddlemp = null;
          this.ListDataDropdownEmp = [];
        }
      }
    },

    changeLocal(value) {
      return moment
        .utc(value)
        .local()
        .format("DD/MM/YYYY hh:mm:ss");
    },

    SearchCertName() {
      let self = this;
      if (self.radioGroup == 2) {
        self.SearchGroupCertInCertificate();
      } else {
        if (this.flagUserAD) {
          self.flagLoadingSend = true;
          let tempdata = {
            // ID: parseInt(sessionStorage.employeeID),
            Search: self.searchCert,
            // Page: self.PageCertManage,
            Page: 1,
            PerPage: self.itemPerPageCertManage
          };
          axios
            // .get(`${self.url}Cert/GetCertByEmployeeID?id=${self.employeeID}`)
            .post(`${self.url}Cert/GetAllCertByEmployeeId`, tempdata)
            .then(function (response) {
              if (response.data.status == 0) {
                self.items = response.data.data.listData;
                self.ListDataCert = response.data.data.listData;
                self.PageCountCertManage = response.data.data.count;
                self.PageCertManage = 1;
                // self.pageCountCert = Math.ceil(
                //   response.data.data.length / self.perpage
                // );
                self.flagLoadingSend = false;
              } else {
                self.flagLoadingSend = false;
                self.items = [];
                self.ListDataCert = [];
                self.PageCountCertManage = 0;
                self.typeAlertSend = "warning"; //success warning error
                self.mesAlertSend = self.$t("dataNotFound");
                self.flagAlertSend = true;
              }
            })
            .catch(function (error) {
              self.flagLoadingSend = false;
              self.typeAlertSend = "error"; //success warning error
              self.mesAlertSend = error;
              self.flagAlertSend = true;
            });
        } else {
          if (self.CheckLoadingGroupCertFlag == false) {
            self.flagLoadingSend = true;
          }
          let tempdatasearch = {
            // ID: parseInt(sessionStorage.employeeID),
            Search: self.searchCert,
            // Page: self.PageCertManage,
            Page: 1,
            PerPage: self.itemPerPageCertManage
          };
          axios
            // .get(`${self.url}Cert/GetCertByCompanyID?id=${self.tempCompanyID}`)
            .post(
              `${self.url}Cert/GetCertListinGroupCertByEmployeeID`,
              tempdatasearch
            )
            .then(function (response) {
              if (response.data.status == 0) {
                self.items = response.data.data.listData;
                self.ListDataCert = response.data.data.listData;
                self.OldListDataCert = response.data.data.listData;
                self.PageCountCertManage = response.data.data.count;
                self.PageCertManage = 1;
                // self.pageCountCert = Math.ceil(
                //   response.data.data.length / self.perpage
                // );
                self.flagLoadingSend = false;
              } else {
                self.flagLoadingSend = false;
                self.items = [];
                self.ListDataCert = [];
                self.PageCountCertManage = 0;
                self.typeAlertSend = "warning"; //success warning error
                self.mesAlertSend = self.$t("dataNotFound");
                self.flagAlertSend = true;
              }
              self.CheckLoadingGroupCertFlag = false;
            })
            .catch(function (error) {
              self.flagLoadingSend = false;
              self.typeAlertSend = "error"; //success warning error
              self.mesAlertSend = error;
              self.flagAlertSend = true;
            });
        }
      }
    },

    SearchGroupCertInCertificate() {
      if (this.flagUserAD) {
        let self = this;
        self.flagLoadingSend = true;
        let tempdata = {
          // ID: parseInt(self.tempCompanyID),
          // EmployeeID: parseInt(self.employeeID),
          Search: self.searchCert,
          Page: 1,
          PerPage: self.itemPerPageCertManage
        };
        axios
          .post(
            `${self.url}Cert/GetGroupCertAndCertListCompanyId`,
            tempdata
          )
          .then(function (response) {
            if (response.data.status == 0) {
              self.items_GroupCertNameAndCert = response.data.data.listData;
              self.PageCountCertManage = response.data.data.count;
              self.PageCertManage = 1;
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.items_GroupCertNameAndCert = [];
              self.PageCountCertManage = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t("dataNotFound");
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
          });
      } else {
        let self = this;
        self.flagLoadingSend = true;
        let temp = {
          // ID: parseInt(self.tempCompanyID),
          // EmployeeID: parseInt(self.employeeID),
          Search: self.searchCert,
          Page: 1,
          PerPage: self.itemPerPageCertManage
        };
        axios
          .post(`${self.url}Cert/GetGroupCertAndCertListCompanyId`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.items_GroupCertNameAndCert = response.data.data.listData;
              self.PageCountCertManage = response.data.data.count;
              self.PageCertManage = 1;
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.items_GroupCertNameAndCert = [];
              self.PageCountCertManage = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t("dataNotFound");
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
          });
      }
    },

    async SearchGroupCertName() {
      if (this.flagUserAD) {
        let self = this;
        self.flagLoadingSend = true;
        var temp = {
          // ID: parseInt(self.employeeID),
          Search: self.searchGroupCert,
          // Page: self.pageCertGroup,
          Page: 1,
          PerPage: self.itemPerPageGroupCert
        };
        await axios
          // .post(`${self.url}Group_Cert/GetGroupCertListEmployeeID`, temp)
          .post(
            `${self.url}Group_Cert/GetAllDataGroupCertListByEmployeeID`,
            temp
          )
          .then(function (response) {
            if (response.data.status == 0) {
              self.items_GroupCert = response.data.data.listdata;
              self.PageCountGroupCert = response.data.data.count;
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.items_GroupCert = [];
              self.PageCountGroupCert = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t("dataNotFound");
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
          });
      } else {
        let self = this;
        self.flagLoadingSend = true;
        let temp = {
          // ID: parseInt(self.tempCompanyID),
          // EmployeeID: parseInt(sessionStorage.employeeID),
          Search: self.searchGroupCert,
          // Page: self.pageCertGroup,
          Page: 1,
          PerPage: self.itemPerPageGroupCert
        };
        axios
          .post(`${self.url}Group_Cert/GetGroupCertListCompanyID`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              self.items_GroupCert = response.data.data.listdata;
              self.PageCountGroupCert = response.data.data.count;
              self.flagLoadingSend = false;
            } else {
              self.flagLoadingSend = false;
              self.items_GroupCert = [];
              self.PageCountGroupCert = 0;
              self.typeAlertSend = "warning"; //success warning error
              self.mesAlertSend = self.$t("dataNotFound");
              self.flagAlertSend = true;
            }
          })
          .catch(function (error) {
            self.flagLoadingSend = false;
            self.typeAlertSend = "error"; //success warning error
            self.mesAlertSend = error;
            self.flagAlertSend = true;
          });
      }
    },

    CertView() {
      let self = this;
      if (self.flagUserAD) {
        if (self.flagCertOrGroup == true) {
          self.flagCertOrGroup = false;
          self.searchGroupCert = '';
          self.getGroupCertListByUser();
        }
        else {
          self.flagCertOrGroup = true;
          self.searchCert = '';
          self.getCertListbyUser();
        }
      } else {
        if (self.flagCertOrGroup == true) {
          self.flagCertOrGroup = false;
          self.searchGroupCert = '';
          self.getGroupCertList();
        }
        else {
          self.flagCertOrGroup = true;
          self.searchCert = '';
          self.getCertList();
        }
      }
    },

    // SearchCertName() {
    //   var self = this;
    //   var tempSearch = {
    //     name: self.searchCert,
    //     id: parseInt(self.tempCompanyID)
    //   };
    //   self.flagLoadingSend = true;

    //   axios
    //     .post(`${self.url}Cert/GetCertByName`, tempSearch)
    //     .then(function(response) {
    //       if (response.data.status == 0) {
    //         if (response.data.data != null) {
    //           self.items = response.data.data;
    //           // self.getCertList();
    //           // self.Cancel();
    //         } else {
    //           alert("ไม่พบข้อมูล");
    //         }
    //       }
    //       self.flagLoadingSend = false;
    //     })
    //     .catch(function(error) {
    //       self.flagLoadingSend = false;
    //       alert(error);
    //     });
    // },

    // SearchGroupCertName() {
    //   var self = this;
    //   var tempSearch = {
    //     name: self.searchGroupCert,
    //     id: parseInt(self.tempCompanyID)
    //   };
    //   self.flagLoadingSend = true;

    //   axios
    //     .post(`${self.url}Group_Cert/GetGroupCertbyName`, tempSearch)
    //     .then(function(response) {
    //       if (response.data.status == 0) {
    //         self.items_GroupCert = response.data.data;
    //         // self.getGroupCertList();
    //         // self.Cancel();
    //       } else {
    //         alert(response.data.message);
    //       }
    //       self.flagLoadingSend = false;
    //     })
    //     .catch(function(error) {
    //       self.flagLoadingSend = false;
    //       alert(error);
    //     });
    // },

    getGroupCertList () {
      let self = this;
      self.flagLoadingSend = true;
      var temp = {
        // ID: parseInt(self.tempCompanyID),
        // EmployeeID: parseInt(sessionStorage.employeeID),
        Search: self.searchGroupCert,
        Page: self.pageCertGroup,
        PerPage: self.itemPerPageGroupCert
      };
      axios
        .post(`${self.url}Group_Cert/GetGroupCertListCompanyID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.items_GroupCert = response.data.data.listdata;
            self.PageCountGroupCert = response.data.data.count;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.items_GroupCert = [];
            self.PageCountGroupCert = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = self.$t("dataNotFound");
            self.flagAlertSend = true;
          }
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    getGroupCertListByUser: function () {
      let self = this;
      self.flagLoadingSend = true;
      var temp = {
        // ID: parseInt(self.employeeID),
        Search: self.searchGroupCert,
        Page: self.pageCertGroup,
        PerPage: self.itemPerPageGroupCert
      };
      axios
        // .post(`${self.url}Group_Cert/GetGroupCertListEmployeeID`, temp)
        .post(`${self.url}Group_Cert/GetAllDataGroupCertListByEmployeeID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.items_GroupCert = response.data.data.listData;
            self.PageCountGroupCert = response.data.data.count;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.items_GroupCert = [];
            self.PageCountGroupCert = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = self.$t("dataNotFound");
            self.flagAlertSend = true;
          }
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    ChangePageCertManageforGroup: function (val) {
      let self = this;
      self.flagLoadingSend = true;
      var temp = {
        // ID: parseInt(self.tempCompanyID),
        // EmployeeID: parseInt(self.employeeID),
        Search: self.searchCert,
        Page: val,
        PerPage: self.itemPerPageCertManage
      };
      axios
        .post(`${self.url}Cert/GetGroupCertAndCertListCompanyId`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.items_GroupCertNameAndCert =
              response.data.data.listData;
            self.PageCountCertManage = response.data.data.count;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.items_GroupCertNameAndCert = [];
            self.PageCountCertManage = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = self.$t("dataNotFound");
            self.flagAlertSend = true;
          }
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    ChangePerPageCertManageforGroup: function (val) {
      let self = this;
      self.flagLoadingSend = true;
      var temp = {
        // ID: parseInt(self.tempCompanyID),
        // EmployeeID: parseInt(self.employeeID),
        Search: self.searchCert,
        Page: 1,
        PerPage: val
      };
      axios
        .post(`${self.url}Cert/GetGroupCertAndCertListCompanyId`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.items_GroupCertNameAndCert =
              response.data.data.listData;
            self.PageCountCertManage = response.data.data.count;
            self.PageCertManage = 1;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.items_GroupCertNameAndCert = [];
            self.PageCountCertManage = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = self.$t("dataNotFound");
            self.flagAlertSend = true;
          }
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },

    getGroupCertAndCertList: function () {
      let self = this;
      self.flagLoadingSend = true;
      var temp = {
        // ID: parseInt(self.tempCompanyID),
        // EmployeeID: parseInt(self.employeeID),
        Search: self.searchCert,
        Page: self.PageCertManage,
        PerPage: self.itemPerPageCertManage
      };
      axios
        .post(`${self.url}Cert/GetGroupCertAndCertListCompanyId`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.items_GroupCertNameAndCert =
              response.data.data.listData;
            self.PageCountCertManage = response.data.data.count;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.items_GroupCertNameAndCert = [];
            self.PageCountCertManage = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = self.$t("dataNotFound");
            self.flagAlertSend = true;
          }
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    },
    getGroupCertAndCertListByUser: function () {
      let self = this;
      self.flagLoadingSend = true;
      var temp = {
        // ID: parseInt(self.tempCompanyID),
        // EmployeeID: parseInt(self.employeeID),
        Search: self.searchCert,
        Page: self.PageCertManage,
        PerPage: self.itemPerPageCertManage
      };
      axios
        .post(`${self.url}Cert/GetGroupCertAndCertListEmployeeID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.items_GroupCertNameAndCert =
              response.data.data.listData;
            self.PageCountCertManage = response.data.data.count;
            self.flagLoadingSend = false;
          } else {
            self.flagLoadingSend = false;
            self.items_GroupCertNameAndCert = [];
            self.PageCountCertManage = 0;
            self.typeAlertSend = "warning"; //success warning error
            self.mesAlertSend = self.$t("dataNotFound");
            self.flagAlertSend = true;
          }
        })
        .catch(function (error) {
          self.flagLoadingSend = false;
          self.typeAlertSend = "error"; //success warning error
          self.mesAlertSend = error;
          self.flagAlertSend = true;
        });
    }
  },
  mounted() {
    // this.tempCompanyID = localStorage.getItem("companyID");
    if (this.flagUserAD) {
      this.getCertListbyUser();
      // this.getGroupCertListByUser();
    } else {
      this.getCertList();
      //this.getEmployeeAll();
      // this.getGroupCertList();
    }
    // this.CheckPermission();
  }
};
</script>
<style scoped>
/* >>>.v-input__control{
  height: 43px !important;
} */
.v-list {
  height: 200px;
  overflow-y: auto;
}

.card-preview {
  border-radius: 5px !important;
  height: 100%;
  overflow: auto;
  padding: 0;
}

*>>>.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: #1264960d !important;
}

*>>>.v-data-table-header {
  background-color: #126496 !important;
  color: #ffffff !important;
}

*>>>.v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}

/* #CertManagment_container {
  font-family: "Roboto", sans-serif;
} */
.txtStyle {
  color: #444444;
  font-weight: regular;
  text-align: right;
}

*>>>.theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot:hover {
  border-width: 1px;
  border-style: solid;
  border-color: #6fbd44;
}

*>>>.v-dialog {
  overflow-y: unset !important;
  overflow-x: auto !important;
}

.container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #acacac;
}

.filecss {
  margin-left: 0px;
  margin-top: 15px;
  /* min-width:200px; */
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  top: 2px;
  left: 2px;
  height: 21px;
  width: 21px;
  border-radius: 5px;
  background-color: #126496;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: -3px;
  top: -3px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid #126496;
}

*>>>.v-text-field--outlined fieldset {
  color: #ebebf2 !important;
}

*>>>tbody tr:nth-of-type(even) {
  background-color: #1264960d;
}

.tabone {
  width: 150px;
  border: solid thin #1976d2;
  border-radius: 3px;
  height: 40px;
}

*>>>.mdi-paperclip::before {
  content: none;
}

*>>>.foricon .mdi:before {
  color: #1976d2;
}

.v-tabs .v-tab--active {
  color: #3c4043;
  background-color: #1976d2 !important;
}

/* New CSS */
@media only screen and (min-width: 1920px) {
  .add-certificate-max-width {
    max-width: 10% !important;
  }
}

@media only screen and (max-width: 1194px) {
  .add-certificate-max-width {
    max-width: 15% !important;
  }
}

@media only screen and (max-width: 1024px) {
  .add-certificate-max-width {
    max-width: 16% !important;
  }
}

@media only screen and (max-width: 834px) {
  .add-certificate-max-width {
    max-width: 20% !important;
  }
}

@media only screen and (max-width: 768px) {
  .add-certificate-max-width {
    max-width: 22% !important;
  }
}

@media only screen and (max-width: 568px) {
  .add-certificate-max-width {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 428px) {
  .hide-header {
    display: none !important;
  }

  .add-certificate-max-width {
    max-width: 100% !important;
  }

  .request-root-ca-btn-width, .add-certificate-btn-width, .search-btn-width {
    width: 100% !important;
  }
}

@media only screen and (max-width: 320px) {
  .request-root-ca-btn-width, .add-certificate-btn-width {
    width: 130px !important;
  }

  .search-btn-width {
    min-width: 53px !important;
  }
}

@media only screen and (max-width: 599px) {
  .txtStyle {
    color: #444444;
    font-weight: regular;
    text-align: left;
  }

  .pp {
    padding-top: 2.5rem;
  }

  /* .filecss {
    width: 200px;
    left: 0;
    margin-top: 2rem;
  } */
}

/* @media only screen and (max-width: 959px) {
  .filecss {
    width: 200px;
    left: 0;
    margin-top: 2rem;
  }
} */
/* @media (min-device-width: 960px) and (max-device-width: 1440px)  {
  .btnRespon {
   margin-left:-30px;
  }
  
  } */

.v-btn:not(.v-btn--round).v-size--default {
    height: 36px;
    min-width: 64px;
    padding: 0 4px;
}

  @media (min-device-width: 960px) and (max-device-width: 1024px){
    .btnDelete{
      margin-left:-1px!important;
    }
  }
 @media (min-device-width: 430px) and (max-device-width: 520px){
    .responsive{
      margin-left: 40px!important;
    }
  }
  @media (min-device-width: 830px) and (max-device-width: 940px){
    .responsive{
      margin-left: 21px!important;
    }
  }
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
}

@media (min-width: 960px){
.col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    /* margin-left:3%; */
}
}
.has_required_succeed {
  /* text-decoration: line-through; */
  color: #689868;
}

.has_required_unsuccessful {
  /* text-decoration: line-through; */
  color: #C62828;
}
</style>
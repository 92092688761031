<template>
  <v-app>
    <NavBar v-if="flagOpenNav" />
    <div :style="[$vuetify.theme.dark ? { 'background-color': '#424242' } : { 'background-color': '#f5f8fe' }]">
      <router-view v-if="$route.path == '/login' || $route.path == '/resetpassword'"></router-view>
      <v-content v-else>
        <div>
          <router-view></router-view>
        </div>
      </v-content>
    </div>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
export default {
  name: "App",

  components: {
    NavBar,
  },
  data() {
    return {
      flagOpenNav: false,
    }
  },
  watch: {
    '$route.path': function () {
      this.SkipNavBar();
    }
  },
  created() {
    this.SkipNavBar();
  },
  methods: {
    SkipNavBar() {
      var strPath = this.$route.path.toLowerCase();
      if (strPath.search("login") != 1 && strPath.search("resetpassword") != 1 && strPath.search("confirmpassword") != 1 && strPath.search("register") != 1
        && strPath.search("success") != 1 && strPath.search("failure") != 1) {
        this.flagOpenNav = true;
      }
      else {
        this.flagOpenNav = false;
      }
    }
  }
};
</script>

<style>
@font-face {
  font-family: "DB Helvethaica X Reg";
  src: url("//db.onlinewebfonts.com/t/9e29a765855a9000ab8e4aa2883d5384.eot");
  src: url("//db.onlinewebfonts.com/t/9e29a765855a9000ab8e4aa2883d5384.eot?#iefix") format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/9e29a765855a9000ab8e4aa2883d5384.woff2") format("woff2"),
    url("//db.onlinewebfonts.com/t/9e29a765855a9000ab8e4aa2883d5384.woff") format("woff"),
    url("//db.onlinewebfonts.com/t/9e29a765855a9000ab8e4aa2883d5384.ttf") format("truetype"),
    url("//db.onlinewebfonts.com/t/9e29a765855a9000ab8e4aa2883d5384.svg#DB Helvethaica X Reg") format("svg");
}

/* @font-face {
  font-family: 'English';
  src: local("English"),
  url(./assets/Font/Kanit-Regular.ttf)
} */

/* html {
  overflow: hidden !important;
} */

@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url(./assets/Font/Kanit-Regular.ttf) format("truetype");
}

* {
  font-family: 'Kanit';
}

.v-content__wrap {
  background-color: white;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 959px) {
  .v-content__wrap {
    background-color: white;
    width: 80%;
  }
}

#app {
  /* font-family: "DB Helvethaica X Reg"; */
  color: #444444;
}
</style>
